import { $themeConfig } from '@themeConfig'
import MenuService from '@/service/MenuService'
import MenuAdapter from '@/adapters/MenuAdapter'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    menuItems: null,
  },
  getters: {
    menuItems(state) {
      return state.menuItems
    },
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    UPDATE_MENU_ITEMS(state, menuItems) {
      state.menuItems = menuItems
    },
  },
  actions: {
    async getMenuItems({ commit }) {
      const menuItems = await MenuService.findAll()
      commit('UPDATE_MENU_ITEMS', MenuAdapter.toStore(menuItems))
    },
  },
}
