import PesquisaService from '@/service/PesquisaService'

export default {
  namespaced: true,
  state: {
    dados: [{
      selecionado: true,
    }],
    dadosMeses: [],
    servicos: [],
    selectServicos: [],
  },
  getters: {
    dados(state) {
      return state.dados
    },
    dadosMeses(state) {
      return state.dadosMeses
    },
    servicos(state) {
      return state.servicos
    },
    selectServicos(state) {
      return state.selectServicos
    },
  },
  mutations: {
    SET_DADOS(state, dados) {
      state.dados = dados
    },
    SET_DADOS_MESES(state, dadosMeses) {
      state.dadosMeses = dadosMeses
    },
    SET_DADOS_SERVICOS(state, servicos) {
      state.servicos = servicos
    },
    SET_SELECT_SERVICOS(state, selectServicos) {
      state.selectServicos = selectServicos
    },
  },
  actions: {
    async pesquisar({ commit }, obj) {
      const dados = await PesquisaService.pesquisaRelatorio(obj)
      commit('SET_DADOS', dados)
      return dados
    },
    async findAll({ commit }, obj) {
      const dados = await PesquisaService.pesquisaRelatorio(obj)
      dados.forEach(d => {
        d.selecionado = true
      })
      commit('SET_DADOS', dados)
      const dadosMeses = await PesquisaService.raioXServicos(obj)
      commit('SET_DADOS_MESES', dadosMeses)
    },
    async findAllServicos({ commit }, obj) {
      const dados = await PesquisaService.raioXServicosFindServicos(obj)
      const servicos = dados.map(d => d.servicoId)
      commit('SET_DADOS_SERVICOS', servicos)
      const selectServicos = dados.map(s => ({
        label: s.agrupamento,
        value: s.servicoId,
      }))
      commit('SET_SELECT_SERVICOS', selectServicos)
    },
  },
}
