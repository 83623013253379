<template>
  <div style="padding-bottom: 80px">
    <b-card
      no-body
      class="mb-2"
    >
      <hr class="my-0">
      <div class="p-2">
        <b-row>
          <b-col>
            <h5>Dados do Arquivo Importado:</h5>
            <b-table 
              ref="table"
              class="pb-2"
              :items="items"
              :fields="fields"
              caption-top
              responsive
              sticky-header="400px"
            >
              <template #cell(numero)="item">
                {{ item.index + 1 }}
              </template>
              <template #cell(editar)="data">
                <div 
                  class="d-flex justify-content-center" 
                  style="min-height: 45px;"
                >
                  <b-button 
                    v-if="!data.item[0].loading && !data.item[0].gerado && data.item[0].campos.length > 0"
                    class="text-secondary"
                    variant="link"
                    @click="openModal(data.item)"
                  >
                    <feather-icon
                      icon="Edit3Icon"
                      size="18"
                    />
                  </b-button>
                </div>
              </template>
              <template #head(selecionar)="{}">
                <div class="d-flex justify-content-center">
                  <b-checkbox
                    v-model="allSelected"
                    @change="value => selectAll(value, items)"
                  />
                </div>
              </template>
              <template #cell(selecionar)="data">
                <div class="d-flex justify-content-center">
                  <b-checkbox
                    v-model="data.item[0].selecionado"
                    :disabled="!data.item[0].valido || data.item[0].loading || data.item[0].gerado"
                    @change="selecionaEtiqueta($event, data.item, data.index)"
                  />
                </div>
              </template>
              <template #cell(valido)="data">
                <div class="d-flex justify-content-center">
                  <span 
                    :id="`tooltip-target-${data.index}`"
                    :class="data.item[0].valido ? 'text-success' : 'text-danger'"
                  >
                    <feather-icon
                      :icon="data.item[0].valido ? 'CheckCircleIcon' : 'XIcon'"
                      size="18"
                    />
                  </span>
                  <b-tooltip 
                    :target="`tooltip-target-${data.index}`"
                    triggers="hover"
                  >
                    <span>
                      {{ data.item[0].valido ? 'Dados válidos' : '' }}
                    </span>
                    <ul
                      v-if="!data.item[0].valido"
                      class="pl-1 pt-1 m-0 text-left"
                    >
                      <li
                        v-for="(erro, index) in data.item[0].erros"
                        :key="index"
                        class="pb-1"
                      >
                        {{ erro }}
                      </li>
                    </ul>
                  </b-tooltip>
                </div>
              </template>
              <template #cell(gerado)="data">
                <div class="d-flex justify-content-center">
                  <span 
                    :id="`tooltip-target-gerado-${data.index}`"
                    :class="data.item[0].gerado && !data.item[0].erroApi ? 'text-success' : (data.item[0].erroApi ? 'text-danger' : '')"
                  >
                    <b-spinner 
                      v-if="data.item[0].loading" 
                      style="width: 1.5rem; height: 1.5rem;"
                    />
                    <feather-icon
                      v-if="!data.item[0].loading"
                      :class="data.item[0].gerado && !data.item[0].erroApi ? 'text-success' : (data.item[0].erroApi ? 'text-danger' : '')"
                      :icon="data.item[0].gerado && !data.item[0].erroApi ? 'CheckCircleIcon' : (data.item[0].erroApi ? 'XIcon' : 'MoreHorizontalIcon')"
                      size="18"
                    />
                  </span>
                  <b-tooltip 
                    :target="`tooltip-target-gerado-${data.index}`"
                    triggers="hover"
                  >
                    <span>
                      {{ data.item[0].gerado ? 'Etiqueta gerada' : '' }}
                    </span>
                    <ul
                      v-if="data.item[0].erroApi"
                      class="pl-1 pt-1 m-0 text-left"
                    >
                      <li
                        v-for="(erro, index) in data.item[0].erros"
                        :key="index"
                        class="pb-1"
                      >
                        {{ erro }}
                      </li>
                    </ul>
                  </b-tooltip>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
      <hr class="my-0">
      <div class="p-2">
        <b-row class="flex-row-reverse">
          <div
            style="display:flex; align-items: center; justify-content: center"
          >
            <span 
              v-if="linhasComErro > 0"
              class="text-danger mr-2"
            >
              <feather-icon
                class="text-danger"
                icon="AlertTriangleIcon"
                size="18"
              />
              Existe {{ linhasComErro }} objetos que precisam ser editados. Caso você clique em Gerar reversas, serão geradas somente as etiquetas reversas que não precisam ser ajustadas
              <feather-icon
                class="text-danger"
                icon="AlertTriangleIcon"
                size="18"
              />
            </span>
            <b-button
              variant="primary"
              class="mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
              :disabled="importacao.loading"
            >
              Gerar reversas
              <feather-icon
                v-if="!importacao.loading"
                class="ml-1"
                icon="CheckIcon"
              />
              <b-spinner 
                v-if="importacao.loading"
                class="ml-1"
                style="width: 1rem; height: 1rem;"
              />
            </b-button>
          </div>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-campos"
      ref="modal"
      title="Editar Campos"
      cancel-title="Cancelar"
      ok-title="OK"
      size="xl"
      style="max-height: 10000px"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk($event, salvaDadosCampos)"
    >
      <validation-observer
        ref="validatorCamposModal"
      >
        <b-form
          ref="formModalCampos"
          @reset.prevent="resetForm"
        >
          <editar-dados-importacao 
            :get-validation-state="getValidationState"
            :item.sync="modalData"
          />
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { watch, reactive, ref, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import EditarDadosImportacao from '../../../components/editarDadosImportacao'

export default {
  name: 'DadosImportacao',
  components: { EditarDadosImportacao },
  props: {
    getValidationState: {
      type: Function,
      required: true,
    },
    importacao: {
      type: undefined,
      required: true,
    },
  },

  setup(props) {
    const dadosArquivoStore = createNamespacedHelpers('arquivoImportacao')
    const layoutStore = createNamespacedHelpers('layout-reverso')
    const clienteLayoutParametroStore = createNamespacedHelpers('clienteLayoutParametro')

    const modal = ref(null)

    const state = reactive({
      dadosArquivo: dadosArquivoStore.useGetters(['dadosArquivo']).dadosArquivo,
      layout: layoutStore.useGetters(['layout']).layout,
      layoutParametros: clienteLayoutParametroStore.useGetters(['layoutParametros']).layoutParametros,
      items: [],
      fields: [],
      allSelected: true,
      modalData: {},
      camposBackup: [],
      etiquetas: [],
      linhasComErro: 0,
    })

    watch(() => state.dadosArquivo, value => {
      state.fields = []
      state.items = []
      state.modalData = {}
      state.camposBackup = []
      state.etiquetas = []
      state.allSelected = true
      state.linhasComErro = 0
      Object.values(value.linhasObjeto).forEach((linha, index) => {
        state.etiquetas.push(linha.etiqueta)
        const campos = []
        campos.push({
          numero: index,
          selecionado: false,
          valido: linha.valido,
          erros: linha.erros,
          gerado: false,
          erroApi: false,
          loading: false,
          campos: linha.campos,
          etiqueta: linha.etiqueta,
        })
        linha.campos.forEach(campo => {
          campos.push(campo.conteudo)
        })
        state.items.push(campos)
        props.importacao.items.push(null)
        if (!linha.valido) {
          state.linhasComErro += 1
        }
      })
      state.fields.push({ label: 'Número', key: 'numero' })
      state.fields.push({ label: 'Editar', key: 'editar' })
      state.fields.push({ label: 'Selecionar', key: 'selecionar' })
      // eslint-disable-next-line no-unused-vars
      state.fields.push({ label: 'Válido', key: 'valido', formatter: (val, key, item) => (item[0].valido ? 2 : 1), sortable: true, sortByFormatted: true })
      state.fields.push({ label: 'Gerado', key: 'gerado', formatter: (val, key, item) => (item[0].gerado ? 2 : 1), sortable: true, sortByFormatted: true })
      Object.values(value.header).forEach((header, index) => { 
        state.fields.push({ label: header, key: `${index + 1}`, sortable: true, tdClass: 'text-nowrap', thStyle: { minWidth: '250px', whiteSpace: 'nowrap' } })
      })
    })

    const openModal = item => {
      state.modalData = item
      state.camposBackup = []
      item[0].campos.forEach(campo => {
        state.camposBackup.push(campo.conteudo)
      })
      modal.value.show()
    }

    const salvaDadosCampos = () => {
      const { campos, numero } = state.modalData[0]
      const etiqueta = state.etiquetas[numero]
      campos.forEach((campo, index) => {
        state.modalData[0].valido = true
        state.modalData[0].campos[index].conteudo = campo.conteudo
        state.modalData[index + 1] = campo.conteudo
        state.items[numero][0].campos[index].conteudo = campo.conteudo
        state.camposBackup[index] = campo.conteudo
        const prop = campo.parametro.nomeCampoEtiqueta.split('.')
        if (prop.length > 1) {
          etiqueta[prop[0]][prop[1]] = campo.conteudo
        } else {
          etiqueta[prop[0]] = campo.conteudo
        }
      })
      state.linhasComErro -= 1
    }

    const resetModal = () => {
      state.modalData[0].campos.forEach((campo, index) => {
        campo.conteudo = state.camposBackup[index]
      })
    }
    
    return {
      ...toRefs(state),
      modal,
      openModal,
      salvaDadosCampos,
      resetModal,
    }
  },
  watch: {
    items() {
      this.selectAll(true, this.items)
    },
  },
  methods: {
    selectAll(value, items) {
      if (!value) {
        for (let x = 0; x < this.importacao.items.length; x += 1) {
          this.importacao.items.splice(x, 1, null)
        }
      }
      items.forEach((item, index) => {
        if (item[0].valido && !item[0].loading && !item[0].gerado) {
          item[0].selecionado = value
          if (value) {
            this.importacao.items.splice(index, 1, item)
          }
        }
      })
    },
    selecionaEtiqueta(event, item, index) {
      if (event) {
        const indexARemover = this.importacao.items[index] ? 0 : 1
        this.importacao.items.splice(index, indexARemover, item)
      } else {
        this.importacao.items.splice(index, 1, null)
      }
    },
    async validaCamposModal() {
      const validation = this.$refs.validatorCamposModal
      const valid = await validation.validate()
      return valid
    },
    handleOk(bvModalEvent, salvaDadosCampos) {
      bvModalEvent.preventDefault()
      this.validaCamposModal().then(valid => {
        if (valid) {
          salvaDadosCampos()
          this.$nextTick(() => {
            this.$bvModal.hide('modal-campos')
            this.$refs.table.refresh()
          })
        }
      })
    },
  },
}
</script>