<template>
  <div style="padding-bottom: 80px">
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header
        class="p-2"
        style="display:flex; align-items: center; justify-content: flex-start"
      >
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="FileIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Criar Layout de Importação
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <div class="p-2">
        <b-row class="pl-2">
          <b-col md="8">
            <h5>
              ORIENTAÇÕES DA IMPORTAÇÃO DE ARQUIVOS DE ENVIOS:
            </h5>
            <ul class="list-group">
              <li>O modelo do arquivo deve ser na extensão CSV (Separado por ponto e vírgula) ou TXT.</li>
              <li>O tamanho máximo do arquivo não pode passar de 2mb.</li>
              <li>A codificação do arquivo CSV deve estar em UTF-8.</li>
            </ul>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col
            cols="1"
            md="5"
          >
            <validation-provider
              #default="validationContext"
              name="Arquivo de Importação"
              rules="required|size:2000"
            >
              <b-form-group>
                <b-input-group 
                  class="mb-2"
                >
                  <b-form-file
                    ref="fileInput"
                    v-model="data.arquivo"
                    drop-placeholder="Solte o arquivo aqui"
                    :state="getValidationState(validationContext)"
                    accept=".csv, .txt"
                  >
                    <template #placeholder>
                      <feather-icon
                        icon="UploadCloudIcon"
                        size="18"
                        class="mr-1"
                      />
                      Escolha um arquivo CSV - TXT
                    </template>
                  </b-form-file>
                </b-input-group>
                <span class="undermessage-error">
                  {{ validationContext.errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </div>
      <hr class="my-0">
      <div class="p-2">
        <b-row class="flex-row-reverse">
          <div
            style="display:flex; align-items : center; justify-content: center"
          >
            <b-button
              variant="primary"
              class="mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Próximo
              <feather-icon
                icon="ArrowRightIcon"
              />
            </b-button>
          </div>
          <div
            style="display:flex; align-items: center; justify-content: center"
          />
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'Arquivo',
  props: {
    getValidationState: {
      type: Function,
      required: true,
    },
    data: {
      type: undefined,
      required: true,
    },
  },
}
</script>
