import httpClient from './http-clients/portaljad'

const baseUrl = 'romaneios'

export default class ImprimirRomaneioService {
  static async reimprimirRomaneios(ids) {
    try {
      const { data } = await httpClient.post([baseUrl, 'reimpressao'].join('/'), ids, { responseType: 'arraybuffer' })
      if (data.byteLength > 0) {
        const blobPdf = new Blob([data], { type: 'application/pdf' })
        const url = URL.createObjectURL(blobPdf)
        return url
      }
      return null
    } catch (error) {
      throw error.response.data
    }
  }

  static async imprimirRomaneio(ids) {
    try {
      const { data } = await httpClient.post([baseUrl, 'romaneio'].join('/'), ids, { responseType: 'arraybuffer' })
      if (data.byteLength > 0) {
        const blobPdf = new Blob([data], { type: 'application/pdf' })
        const url = URL.createObjectURL(blobPdf)
        return url
      }
      return null
    } catch (error) {
      throw error.response.data
    }
  }

  static async findByDateAndSituation(obj) {
    const params = new URLSearchParams()
    params.append('dataInicial', obj.dataInicial)
    params.append('dataFinal', obj.dataFinal)
    params.append('situacao', 'IMPRESSO')
    params.append('clienteId', obj.clienteId)
    
    try {
      const { data } = await httpClient.get([baseUrl, 'movimentacoes'].join('/'), { params })
      const arrayRomaneios = await httpClient.post(baseUrl, data.body.map(mov => mov.movimentacaoId))
      const lista = data.body.map(m => ({ ...m, romaneio: this.verificaSeTemRomaneio({ movimentacaoId: m.movimentacaoId, romaneios: arrayRomaneios.data.body }) }))
      // Mostrar apenas quem não tem romaneio (Aguardando Impressão)
      if (obj.situacaoRomaneio[0] === 'AGUARDANDO_IMPRESSAO' && obj.situacaoRomaneio.length === 1) {
        return lista.filter(item => (item.romaneio.length === 0)) 
      }
      
      // Mostrar apenas quem tem romaneio (Impresso)
      if (obj.situacaoRomaneio[0] === 'IMPRESSO' && obj.situacaoRomaneio.length === 1) { return lista.filter(etiqueta => etiqueta.romaneio.length > 0) }

      return lista
    } catch (error) {
      throw error.response.data
    }
  }

  static verificaSeTemRomaneio({ movimentacaoId, romaneios }) {
    const romaneiosList = romaneios.reduce((acc, rom) => {
      if (rom.movimentacoes && rom.movimentacoes.includes(movimentacaoId)) {
        return [...acc, rom]
      }
      return acc
    }, [])
    
    if (romaneiosList.length > 0) {
      return romaneiosList[0].romaneioId.toString()
    }
    return ''
  }

  static async removeRomaneio(romaneioId) {
    try {
      await httpClient.delete([baseUrl, romaneioId].join('/'))
    } catch (error) {
      throw error.response.data
    }
  }
}
