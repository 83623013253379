<template>
  <div style="padding: 2rem 15rem;">
    <b-row 
      align-h="center" 
      align-v="center"
    >
      <b-col
        sm="6"
        md="6"
      >
        <b-card
          no-body
          class="gerar-etiqueta-card"
        >
          <b-link :to="{name:'geracao-reversa-manual'}">
            <div class="card individual">
              <div class="text">
                +1
              </div>
              <div>Individual</div>
            </div>
            <div class="text-and-icon">
              <feather-icon
                icon="TagIcon"
                size="22"
              />
              <b-card-sub-title class="subtitle-icon-text">
                Gerar etiqueta individualmente, depois de escrever as informações do envio.
              </b-card-sub-title>
            </div>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="6"
      >
        <b-card
          no-body
          class="gerar-etiqueta-card"
        >
          <b-link :to="{name: 'geracao-reversa-importacao'}">
            <div class="card lote">
              <feather-icon
                icon="UploadIcon"
                width="73.73"
                height="116"
                stroke-width="1.5"
              />
              <div>Em Lote</div>
            </div>
            <div class="text-and-icon">
              <feather-icon
                icon="UploadIcon"
                size="22"
              />
              <b-card-sub-title class="subtitle-icon-text">
                Gerar etiqueta utilizando arquivo XML, TXT ou CSV.
              </b-card-sub-title>
            </div>
          </b-link>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'IndexVue',
  mounted() {
    if (this.$route.params.page) {
      const pag = this.$route.params.page
      if (pag === 'etq-manual') {
        this.$router.push('/envios/gerar-reversa-manual')
      }
    }
  },
}
</script>

<style lang="scss">

</style>