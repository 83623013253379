import LojaService from '@/service/LojaService'

export default {
  namespaced: true,
  state: {
    loja: {},
    lojas: [],
  },
  getters: {
    loja(state) {
      return state.loja
    },
    lojas(state) {
      return state.lojas
    },
  },
  mutations: {
    UPDATE_LOJA(state, loja) {
      state.loja = loja
    },
    UPDATE_LOJAS(state, lojas) {
      state.lojas = lojas
    },
  },
  actions: {
    async findAll({ commit }, clienteId) {
      const lojas = await LojaService.findAll(clienteId)
      commit('UPDATE_LOJAS', lojas)
      return lojas
    },
    async saveAuth(_, integrationAuth) {
      const integracao = await LojaService.saveAuth(integrationAuth)
      return integracao
    },
    async findById({ commit }, lojaId) {
      const loja = await LojaService.findById(lojaId)
      commit('UPDATE_LOJA', loja)
      return loja
    },
    async save(_, loja) {
      const data = await LojaService.save(loja)
      return data
    },
    async edit(_, { lojaId, loja }) {
      const data = await LojaService.edit(lojaId, loja)
      return data
    },
    async delete(_, lojaId) {
      const data = await LojaService.delete(lojaId)
      return data
    },
  },
}