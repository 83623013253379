<template>
  <div>
    <b-row class="justify-content-center">
      <b-col
        cols="3"

        class="d-flex justify-content-center"
      >
        <svg
          v-if="Object.keys(situacao).length === 0"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.5"
            y="1.5"
            width="29"
            height="29"
            rx="14.5"
            :stroke="alterColor('icon', Object.keys(situacao).length === 0)"
            stroke-width="3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.5 9H16.875V17.3125L24 21.54L22.8125 23.4875L14.5 18.5V9Z"
            :fill="alterColor('icon', Object.keys(situacao).length === 0)"
          />
        </svg>
        <svg
          v-else
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          :stroke="alterColor('icon', Object.keys(situacao)[0] === 'ENVIADO')"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.6667 0.666626C7.83466 0.666626 0.666664 7.83463 0.666664 16.6666C0.666664 25.4986 7.83466 32.6666 16.6667 32.6666C25.4987 32.6666 32.6667 25.4986 32.6667 16.6666C32.6667 7.83463 25.4987 0.666626 16.6667 0.666626ZM13.4666 23.8661L23.0666 16.6661L13.4666 9.46611V23.8661ZM3.8667 16.6671C3.8667 23.7231 9.6107 29.4671 16.6667 29.4671C23.7227 29.4671 29.4667 23.7231 29.4667 16.6671C29.4667 9.61115 23.7227 3.86715 16.6667 3.86715C9.6107 3.86715 3.8667 9.61115 3.8667 16.6671Z"
            :fill="alterColor('icon', Object.keys(situacao)[0] === 'ENVIADO')"
          />
        </svg>
      </b-col>
      <b-col
        cols="1"
        class="line-box"
      >
        <div 
          class="line"
          :class="alterArrowClass(Object.keys(situacao)[0] === 'ENVIADO' || Object.keys(situacao).length === 0)"
          :style="alterColor('line', Object.keys(situacao)[0] === 'ENVIADO' || Object.keys(situacao).length === 0)"
        />
      </b-col>
      <b-col
        cols="3"
        class="d-flex justify-content-center"
      >
        <svg
          v-if="Object.keys(situacao)[0] === 'TRANSITO' && situacao.TRANSITO[0].situacaoTracking === 'PROBLEMA_NA_ENTREGA'"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.5"
            y="1.5"
            width="29"
            height="29"
            rx="14.5"
            :stroke="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'PROBLEMA_NA_ENTREGA')"
            stroke-width="3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.6667 15.6667C21.6667 12.7267 19.2733 10.3333 16.3333 10.3333C15.3933 10.3333 14.5133 10.58 13.7533 11.0067L12.78 10.0333C13.8067 9.38 15.0267 9 16.3333 9C20.0133 9 23 11.9867 23 15.6667C23 16.9733 22.62 18.1933 21.9667 19.2267L20.9933 18.2533C21.42 17.4867 21.6667 16.6067 21.6667 15.6667ZM17.7467 15H19.6667V16.3333H19.08L17.7467 15ZM9.94 9.08667L9 10.0267L10.8533 11.88C10.1067 12.9533 9.66667 14.26 9.66667 15.6667C9.66667 19.3467 12.6533 22.3333 16.3333 22.3333C17.74 22.3333 19.0467 21.8933 20.12 21.1467L21.9733 23L22.9133 22.06L9.94 9.08667ZM11 15.6667C11 18.6067 13.3933 21 16.3333 21C17.3733 21 18.3333 20.7 19.1533 20.18L15.3067 16.3333H13V15H13.9733L11.82 12.8467C11.3 13.6667 11 14.6267 11 15.6667Z"
            :fill="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'PROBLEMA_NA_ENTREGA')"
          />
        </svg>
        <svg
          v-else-if="Object.keys(situacao)[0] === 'TRANSITO' && situacao.TRANSITO[0].situacaoTracking === 'AGUARDANDO_RETIRADA'"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.5"
            y="1.5"
            width="29"
            height="29"
            rx="14.5"
            :stroke="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'AGUARDANDO_RETIRADA')"
            stroke-width="3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.7667 10.3667L21.6933 11.4867C21.8867 11.7133 22 12.0133 22 12.3333V20.6667C22 21.4 21.4 22 20.6667 22H11.3333C10.6 22 10 21.4 10 20.6667V12.3333C10 12.0133 10.1133 11.7133 10.3067 11.4867L11.2267 10.3667C11.4133 10.14 11.6867 10 12 10H20C20.3133 10 20.5867 10.14 20.7667 10.3667ZM19.84 11.3333H12.16L11.6267 11.98H20.38L19.84 11.3333ZM11.3333 20.6667V13.3333H20.6667V20.6667H11.3333ZM15.0333 14.6667H16.9667V16.6667H18.6667L16 19.3333L13.3333 16.6667H15.0333V14.6667Z"
            :fill="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'AGUARDANDO_RETIRADA')"
          />
        </svg>
        <svg
          v-else-if="Object.keys(situacao)[0] === 'TRANSITO' && situacao.TRANSITO[0].situacaoTracking === 'DEVOLVIDO'"
          width="32"
          height="28"
          viewBox="0 0 32 28"
          fill="none"
          :stroke="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'DEVOLVIDO')"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.57143 13.7143C4.57143 6.14095 10.7124 0 18.2857 0C25.859 0 32 6.14095 32 13.7143C32 21.2876 25.859 27.4286 18.2857 27.4286C14.4914 27.4286 11.0781 25.8895 8.59429 23.4057L10.7581 21.2419C12.6781 23.1771 15.3448 24.381 18.2857 24.381C24.1829 24.381 28.9524 19.6114 28.9524 13.7143C28.9524 7.81714 24.1829 3.04762 18.2857 3.04762C12.3886 3.04762 7.61905 7.81714 7.61905 13.7143H12.1905L6.03429 19.8552L5.92762 19.6419L0 13.7143H4.57143ZM16.7619 15.238V7.619H19.0476V13.9428L24.4114 17.1276L23.2381 19.078L16.7619 15.238Z"
            :fill="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'DEVOLVIDO')"
          />
        </svg>
        <svg
          v-else-if="Object.keys(situacao)[0] === 'TRANSITO' && situacao.TRANSITO[0].situacaoTracking === 'BLOQUEADO'"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          :stroke="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'BLOQUEADO')"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM3.20003 16.0005C3.20003 8.92852 8.92803 3.20052 16 3.20052C18.96 3.20052 21.68 4.20852 23.84 5.90452L5.90403 23.8405C4.20803 21.6805 3.20003 18.9605 3.20003 16.0005ZM8.15999 26.0962C10.32 27.7922 13.04 28.8002 16 28.8002C23.072 28.8002 28.8 23.0722 28.8 16.0002C28.8 13.0402 27.792 10.3202 26.096 8.16016L8.15999 26.0962Z"
            :fill="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'BLOQUEADO')"
          />
        </svg>

        <svg
          v-else
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.5"
            y="1.5"
            width="29"
            height="29"
            rx="14.5"
            :stroke="alterColor('icon', Object.keys(situacao)[0] === 'TRANSITO')"
            stroke-width="3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.3333 13.3333H19.3333V10.6666H9.99999C9.26666 10.6666 8.66666 11.2666 8.66666 12V19.3333H9.99999C9.99999 20.44 10.8933 21.3333 12 21.3333C13.1067 21.3333 14 20.44 14 19.3333H18C18 20.44 18.8933 21.3333 20 21.3333C21.1067 21.3333 22 20.44 22 19.3333H23.3333V16L21.3333 13.3333ZM21 14.3333L22.3067 16H19.3333V14.3333H21ZM11.3333 19.3333C11.3333 19.7 11.6333 20 12 20C12.3667 20 12.6667 19.7 12.6667 19.3333C12.6667 18.9666 12.3667 18.6666 12 18.6666C11.6333 18.6666 11.3333 18.9666 11.3333 19.3333ZM13.48 18C13.1133 17.5933 12.5933 17.3333 12 17.3333C11.4067 17.3333 10.8867 17.5933 10.52 18H9.99999V12H18V18H13.48ZM19.3333 19.3333C19.3333 19.7 19.6333 20 20 20C20.3667 20 20.6667 19.7 20.6667 19.3333C20.6667 18.9666 20.3667 18.6666 20 18.6666C19.6333 18.6666 19.3333 18.9666 19.3333 19.3333Z"
            :fill="alterColor('icon', Object.keys(situacao)[0] === 'TRANSITO')"
          />
        </svg>
      </b-col>
      <b-col
        cols="1"
        class="line-box"
      >
        <div 
          class="line"
          :class="alterArrowClass(Object.keys(situacao)[0] === 'TRANSITO')"
          :style="alterColor('line', Object.keys(situacao)[0] === 'TRANSITO')"
        />
      </b-col>
      <b-col
        cols="3"
        class="d-flex justify-content-center"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.5"
            y="1.5"
            width="29"
            height="29"
            rx="14.5"
            :stroke="alterColor('icon', Object.keys(situacao)[0] === 'ENTREGUE')"
            stroke-width="3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14 22.6667H20C20.5534 22.6667 21.0267 22.3334 21.2267 21.8534L23.24 17.1534C23.3 17 23.3334 16.84 23.3334 16.6667V15.3334C23.3334 14.6 22.7334 14 22 14H17.7934L18.4267 10.9534L18.4467 10.74C18.4467 10.4667 18.3334 10.2134 18.1534 10.0334L17.4467 9.33337L13.0534 13.7267C12.8134 13.9667 12.6667 14.3 12.6667 14.6667V21.3334C12.6667 22.0667 13.2667 22.6667 14 22.6667ZM14 14.6667L16.8934 11.7734L16 15.3334H22V16.6667L20 21.3334H14V14.6667ZM11.3334 14.6667H8.66669V22.6667H11.3334V14.6667Z"
            :fill="alterColor('icon', Object.keys(situacao)[0] === 'ENTREGUE')"
          />
        </svg>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col
        cols="3"
        class="d-flex flex-grow-2 justify-content-center"
      >
        <span v-if="Object.keys(situacao).length === 0" :style="alterColor('text', Object.keys(situacao).length === 0)">Coleta Solicitada</span>
        <span v-else :style="alterColor('text', (Object.keys(situacao)[0] === 'ENVIADO' || Object.keys(situacao).length === 0))">Enviado</span>
      </b-col>
      <b-col
        cols="1"
      />
      <b-col
        cols="3"
        class="d-flex flex-grow-2 justify-content-center"
      >
        <span
          v-if="Object.keys(situacao)[0] === 'TRANSITO' && situacao.TRANSITO[0].situacaoTracking === 'PROBLEMA_NA_ENTREGA'"
          :style="alterColor('text', situacao.TRANSITO[0].situacaoTracking === 'PROBLEMA_NA_ENTREGA')"
        >Problema na Entrega</span>
        <span
          v-else-if="Object.keys(situacao)[0] === 'TRANSITO' && situacao.TRANSITO[0].situacaoTracking === 'BLOQUEADO'"
          :style="alterColor('text', situacao.TRANSITO[0].situacaoTracking === 'BLOQUEADO')"
        >Bloqueado</span>
        <span
          v-else-if="Object.keys(situacao)[0] === 'TRANSITO' && situacao.TRANSITO[0].situacaoTracking === 'EXTRAVIADO'"
          :style="alterColor('text', situacao.TRANSITO[0].situacaoTracking === 'EXTRAVIADO')"
        >Extraviado</span>
        <span
          v-else-if="Object.keys(situacao)[0] === 'TRANSITO' && situacao.TRANSITO[0].situacaoTracking === 'AGUARDANDO_RETIRADA'"
          :style="alterColor('text', situacao.TRANSITO[0].situacaoTracking === 'AGUARDANDO_RETIRADA')"
        >Aguardando Retirada</span>
        <span
          v-else-if="Object.keys(situacao)[0] === 'TRANSITO' && situacao.TRANSITO[0].situacaoTracking === 'DEVOLVIDO'"
          :style="alterColor('text', situacao.TRANSITO[0].situacaoTracking === 'DEVOLVIDO')"
        >Devolvido</span>
        <span
          v-else
          :style="alterColor('text', Object.keys(situacao)[0] === 'TRANSITO')"
        >Transito</span>
      </b-col>
      <b-col
        cols="1"
      />
      <b-col
        cols="3"
        class="d-flex flex-grow-2 justify-content-center pl-sm-1"
      >
        <span :style="alterColor('text', Object.keys(situacao)[0] === 'ENTREGUE')">Entregue</span>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: 'TrackExternoHeader',
  props: {
    situacao: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const alterArrowClass = local => {
      const key = Object.keys(props.situacao)[0]
      if (local) {
        if (key === 'ENVIADO' || key == null) {
          return 'enviado-arrow'
        }
        if (key === 'ENTREGUE') {
          return 'entregue-arrow'
        }
        if (key === 'TRANSITO') {
          if (props.situacao.TRANSITO.length > 0) {
            const { situacaoTracking } = props.situacao.TRANSITO[0]
            if (situacaoTracking === 'PROBLEMA_NA_ENTREGA' || situacaoTracking === 'EXTRAVIADO' || situacaoTracking === 'BLOQUEADO') {
              return 'problema-arrow'
            }
            if (situacaoTracking === 'AGUARDANDO_RETIRADA') {
              return 'retirada-arrow'
            }
            return 'transito-arrow'
          }
          return 'transito-arrow'
        }
      }
      return 'default-arrow'
    }

    const alterColor = (local, primary) => {
      const key = Object.keys(props.situacao)[0]
      let as = ''
      if (local === 'text') {
        as = 'color: '
      }
      if (local === 'line') {
        as = 'border-top: 3px dotted '
      }
      if (primary) {
        if (key === 'ENVIADO' || key == null) {
          return `${as}#000000`
        }
        if (key === 'ENTREGUE') {
          return `${as}#61AA82`
        }
        if (key === 'TRANSITO') {
          if (props.situacao.TRANSITO.length > 0) {
            const { situacaoTracking } = props.situacao.TRANSITO[0]
            if (situacaoTracking === 'PROBLEMA_NA_ENTREGA' || situacaoTracking === 'EXTRAVIADO' || situacaoTracking === 'BLOQUEADO') {
              return `${as}#D33540`
            }
            if (situacaoTracking === 'AGUARDANDO_RETIRADA') {
              return `${as}#4E7EFF`
            }
            return `${as}#9747FF`
          }
          return `${as}#9747FF`
        }
      }
      return `${as}#A5A5A5 `
    }

    return {
      alterArrowClass,
      alterColor,
    }
  },
}
</script>

<style scoped>
.line-box {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
.line {
  flex-grow: 1;
  min-width: 170%;
  height: 0.3rem;
  position: relative;
}

.line::after {
  content: '';
  position: absolute;
  right: -0.5rem;
  top: -0.1rem;
  transform: translateY(-50%) rotate(315deg);
  border: solid;
  border-width: 0 0.2rem 0.2rem 0;
  display: inline-block;
  padding: 0.1rem;
}

.entregue-arrow::after {
  border-color: #61AA82;
}
.transito-arrow::after {
  border-color: #9747FF;
}
.problema-arrow::after {
  border-color: #D33540;
}
.retirada-arrow::after {
  border-color: #4E7EFF;
}
.enviado-arrow::after {
  border-color: #000000;
}
.default-arrow::after {
  border-color: #A5A5A5;
}
</style>
