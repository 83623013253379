import TrackExternoService from '@/service/TrackExternoService'

export default {
  namespaced: true,
  state: {
    track: {},
    situacao: {},
    movimentacaoInfo: {},
    jadlogFranquia: {},
    clienteMidiaSocial: null,
  },
  
  getters: {
    track(state) {
      return state.track
    },
    getSituacao(state) {
      return state.situacao
    },
    getMovimentacaoInfo(state) {
      return state.movimentacaoInfo
    },
    getJadlogFranquia(state) {
      return state.jadlogFranquia
    },
    getClienteMidiaSocial(state) {
      return state.clienteMidiaSocial
    },
  },
  mutations: {
    SET_TRACK(state, track) {
      state.track = track
    },
    SET_SITUACAO(state, situacao) {
      state.situacao = situacao
    },
    SET_MOVIMENTACAO_INFO(state, movimentacaoInfo) {
      state.movimentacaoInfo = movimentacaoInfo
    },
    SET_JADLOG_FRANQUIA(state, jadlogFranquia) {
      state.jadlogFranquia = jadlogFranquia
    },
    SET_CLIENTE_MIDIA_SOCIAL(state, clienteMidiaSocial) {
      state.clienteMidiaSocial = clienteMidiaSocial
    },
  },
  actions: {
    async findValues({ commit }, track) {
      const trackValues = await TrackExternoService.findTrackValues(track)
      if (trackValues != null) {
        if (trackValues.situacao != null) {
          const situacao = { ...trackValues.situacao }
          Object.keys(situacao).forEach(key => {
            situacao[key].sort((a, b) => new Date(b.dataHora) - new Date(a.dataHora))
          })
          commit('SET_SITUACAO', situacao)
        }
        if (trackValues.movimentacaoInfo != null) {
          commit('SET_MOVIMENTACAO_INFO', trackValues.movimentacaoInfo)
        }
        if (trackValues.jadlogFranquia != null) {
          commit('SET_JADLOG_FRANQUIA', trackValues.jadlogFranquia)
        }
        if (trackValues.clienteMidiaSocial != null) {
          commit('SET_CLIENTE_MIDIA_SOCIAL', trackValues.clienteMidiaSocial)
        }
      }
      commit('SET_TRACK', trackValues)
    },
  },
}
