<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="{ required: required }"
  >
    <b-form-group
      :label="label"
      :label-for="id"
    >
      <b-form-datepicker
        :id="id"
        v-model="date"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        locale="pt-BR"
        placeholder="00/00/0000"
        start-weekday="0"
        class="mb-1, form-control"
        :state="getValidationState ? getValidationState(validationContext) : null"
        @input="$emit('update', date)"
      />
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  name: 'FormDatepicker',
  props: {
    id: {
      type: String,
      default: 'FormDatepicker',
    },
    label: {
      type: String,
      default: '',
    },
    defaultDate: {
      type: String,
      default: null,
    },
    getValidationState: {
      type: Function,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
    }
  },
  created() {
    this.date = this.$props.defaultDate
  },
  methods: {
    clear() {
      this.date = null
    },
  },
}
</script>

<style scoped>
</style>