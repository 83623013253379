import GerarEtiquetasService from '@/service/GerarEtiquetaService'

export default {
  namespaced: true,
  state: {
    etiquetas: '',
    declaracaoConteudo: '',
    danfe: '',
  },

  getters: {
    gerarEtiquetas(state) {
      return state.etiquetas
    },
    gerarDeclaracaoConteudo(state) {
      return state.declaracaoConteudo
    },
    gerarDanfe(state) {
      return state.danfe
    },
  },
  mutations: {
    POST_ETIQUETA(state, etiquetas) {
      state.etiquetas = etiquetas
    },
    POST_DECLARACAO_CONTEUDO(state, declaracaoConteudo) {
      state.declaracaoConteudo = declaracaoConteudo
    },
    POST_DANFE(state, danfe) {
      state.danfe = danfe
    },
  },
  actions: {
    async find({ commit }, { etiqueta, tipoEtiqueta }) {
      return GerarEtiquetasService.etiquetasPost(etiqueta, tipoEtiqueta)
        .then(url => {
          commit('POST_ETIQUETA', url)
          return url
        })
    },
    async findReversa({ commit }, { etiqueta, tipoEtiqueta }) {
      return GerarEtiquetasService.etiquetasPostReversa(etiqueta, tipoEtiqueta)
        .then(url => {
          commit('POST_ETIQUETA', url)
          return url
        })
    },
    async gerarEtiquetasReversaQrCode({ commit }, { etiquetas, clienteId }) {
      return GerarEtiquetasService.etiquetasPostReversaQrCode(etiquetas, clienteId).then(url => {
        commit('POST_DECLARACAO_CONTEUDO', null)
        commit('POST_DANFE', null)
        commit('POST_ETIQUETA', url)
        return url
      })
    },
    async declaracaoConteudo({ commit }, { etiqueta, tipoEtiqueta, reversa }) {
      return GerarEtiquetasService.declaracaoConteudoPost(etiqueta, tipoEtiqueta, reversa)
        .then(url => {
          commit('POST_DECLARACAO_CONTEUDO', url)
          return url
        })
    },
    async danfe({ commit }, { etiqueta, tipoEtiqueta, reversa }) {
      return GerarEtiquetasService.danfePost(etiqueta, tipoEtiqueta, reversa)
        .then(url => {
          commit('POST_DANFE', url)
          return url
        })
    },
  },
}