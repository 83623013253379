export default class ClienteAdapter {
  static toApi(cliente) {
    return {
      ...cliente,
      numeroDocumento: cliente.destinatario.numeroDocumento.replaceAll(/[.,()/\s-]+/g, ''),
      telefone: cliente.destinatario.celular.replaceAll(/[.,()/\s-]+/g, ''),
      cep: cliente.destinatario.cep.replaceAll(/[.,()/\s-]+/g, ''),
      ativo: true,
    }
  }

  static toStore(cliente) {
    return {
      ...cliente,
    }
  }
}