<template>
  <b-card
    no-body
    class="mb-2 py-1 pl-2"
  >
    <vue-apex-charts
      ref="grafico"
      type="line"
      height="350" 
      width="98%"
      :options="options"
      :series="series"
      class="chart"
    />
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import useAppConfig from '@core/app-config/useAppConfig'
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'GraficoBarra',
  components: { VueApexCharts },
  props: {
    quantidadeDeObjEntregue: {
      type: Array,
      required: true,
    },
    uf: {
      type: Array,
      required: true,
    },
    mediaEmDiasDaEntrega: {
      type: Array,
      required: true,
    },
    mediaGeralEmDias: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { skin } = useAppConfig()
    const grafico = ref(null)

    const quantidadeDeObjEntregue = ref(props.quantidadeDeObjEntregue)
    const mediaEmDiasDaEntrega = ref(props.mediaEmDiasDaEntrega)
    const mediaGeralEmDias = ref(props.mediaGeralEmDias)

    const series = [{
      name: 'Média em dias da Entrega',
      type: 'column',
      color: '#058dc7',
      data: mediaEmDiasDaEntrega.value,
    }, {
      name: 'Quantidade de Obj. Entregue',
      type: 'column',
      color: '#f55559',
      data: quantidadeDeObjEntregue.value,
    }, {
      name: 'Média Geral em dias',
      type: 'line',
      color: '#50B432',
      data: mediaGeralEmDias.value,
    }]

    const options = {
      responsive: [{
        breakpoint: 500,
        options: {
          title: {
            margin: 2,
            floating: true,
            offsetY: 0,
            offsetX: 25,
            style: {
              fontSize: '12px',
            },
          },  
          chart: {
            offsetY: 20,
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: false,
              },
              labels: {
                style: {
                  colors: '#058dc7',
                },
              },
              title: {
                text: 'Dias',
                rotate: 0,
                offsetX: 25,
                offsetY: 140,
                style: {
                  color: '#058dc7',
                  fontSize: '10px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  cssClass: 'apexcharts-yaxis-title',
                },
              },
              tooltip: {
                enabled: true,
              },
            },
            {
              seriesName: 'Quantidade de Obj. Entregue',
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: false,
              },
              labels: {
                style: {
                  colors: '#f55559',
                },
              },
              title: {
                text: 'Quantidade',
                rotate: 0,
                offsetX: -20,
                offsetY: 140,
                style: {
                  color: '#f55559',
                  fontSize: '10px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  cssClass: 'apexcharts-yaxis-title',
                },
              },
            },
            {
              seriesName: 'Média em dias da Entrega',
              opposite: false,
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
              labels: {
                style: {
                  colors: '#fff',
                },
              },
              title: {
                text: 'Dias',
                style: {
                  color: '#fff',
                },
              },
            },
          ],
        },
      }],
      noData: {
        text: 'Nenhum resultado',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined,
        },
      },
      chart: {
        type: 'line',
        foreColor: `${skin.value === 'dark' ? '#ffffff' : '#373d3f'}`,
        toolbar: {
          show: true,
          offsetX: -35,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
          autoSelected: 'pan', 
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1, 4],
      },
      title: {
        text: 'Média em Dias de Entrega dos Objetos por Estado',
        align: 'center',
        margin: 25,
        style: {
          fontSize: '16px',
          fontWeight: 600,
          color: `${skin.value === 'dark' ? '#ffffff' : '#5e5873'}`,
        },
      },
      xaxis: {
        categories: props.uf,
        labels: {
          style: {
            colors: `${skin.value === 'dark' ? '#ffffff' : '#373d3f'}`,
          },
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: '#058dc7',
            },
          },
          min: 0,
          max: Math.ceil(Math.max(...mediaEmDiasDaEntrega.value) / 5) * 5,
          tickAmount: 5,
          title: {
            text: 'Dias',
            rotate: 0,
            offsetX: 25,
            offsetY: -140,
            style: {
              color: '#058dc7',
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 300,
              cssClass: 'apexcharts-yaxis-title',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: 'Quantidade de Obj. Entregue',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: '#f55559',
            },
          },
          min: 0,
          max: Math.ceil(Math.max(...quantidadeDeObjEntregue.value) / 5) * 5,
          tickAmount: 5,
          title: {
            text: 'Quantidade',
            rotate: 0,
            offsetX: -25,
            offsetY: -140,
            style: {
              color: '#f55559',
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 300,
              cssClass: 'apexcharts-yaxis-title',
            },
          },
        },
        {
          seriesName: 'Média em dias da Entrega',
          opposite: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      ],
      legend: {
        horizontalAlign: 'center',
        offsetX: 40,
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }

    watch(() => skin.value, value => {
      options.title.style = {
        color: `${value === 'dark' ? '#ffffff' : '#5e5873'}`,
      }
      options.chart.foreColor = `${value === 'dark' ? '#ffffff' : '#373d3f'}`
      options.xaxis.labels.style = {
        colors: `${value === 'dark' ? '#ffffff' : '#373d3f'}`,
      }
      if (grafico && grafico.value) {
        grafico.value.updateOptions(options)
      }
    })

    return {
      grafico,
      options,
      series,
    }
  },
}
</script>

<style scoped>
</style>