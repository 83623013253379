<template>
  <b-card
    no-body
    class="mb-2 py-1 pl-2"
  >
    <vue-apex-charts
      ref="grafico"
      type="bar"
      height="350" 
      width="98%"
      :options="options"
      :series="series"
    />
  </b-card>
</template>

<script>
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import useAppConfig from '@core/app-config/useAppConfig'
import utils from '@/helpers/utils'

export default {
  name: 'GraficoBarra',
  components: { VueApexCharts },
  props: {
    dados: {
      type: Array,
      required: true,
    },
    dadosMeses: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { skin } = useAppConfig()
    
    const dadoMeses = servicoId => props.dadosMeses.filter(d => Number(d.servicoId) === Number(servicoId))

    const dadoServico = servicoId => props.dados.filter(d => Number(d.servicoId) === Number(servicoId))

    const isSelecionado = servicoId => {
      const ds = dadoServico(servicoId)
      if (ds) {
        const cardServico = ds[0]
        if (cardServico) {
          if (cardServico.selecionado) {
            return cardServico.selecionado === true
          }
        }
      }
      return false
    }

    const meses = () => {
      const mesesLista = []
      const today = new Date()
      let d
      for (let i = 5; i >= 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1)
        mesesLista.push(utils.mesNomes[d.getMonth()])
      }
      return mesesLista
    }

    const quantidades = () => meses().map(mes => {
      const selecionados = props.dadosMeses.filter(d => isSelecionado(d.servicoId) && d.agrupamento === mes)
      const qtd = selecionados.map(d => d.quantidade).reduce((a, b) => a + b, 0)
      return qtd
    })

    const valores = () => meses().map(mes => {
      const selecionados = props.dadosMeses.filter(d => isSelecionado(d.servicoId) && d.agrupamento === mes)
      const qtd = selecionados.map(d => d.valor).reduce((a, b) => a + b, 0)
      return qtd
    })

    const state = reactive({
      series: [{
        name: 'Quantidade',
        data: quantidades(),
      }, {
        name: 'Valor (R$)',
        data: valores(),
      }],
    })

    const options = {
      title: {
        text: 'Gráfico de Vendas',
        align: 'center',
        margin: 10,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          color: `${skin.value === 'dark' ? '#ffffff' : '#6e6b7b'}`,
        },
      },
      subtitle: {
        text: 'Vendas do período',
        align: 'center',
        margin: 10,
        style: {
          fontSize: '12px',
          fontWeight: 'normal',
          color: `${skin.value === 'dark' ? '#ffffff' : '#6e6b7b'}`,
        },
      },
      chart: {
        type: 'bar',
        foreColor: `${skin.value === 'dark' ? '#ffffff' : '#373d3f'}`,
        height: 400,
        toolbar: {
          show: true,
          offsetX: -35,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
          autoSelected: 'pan', 
        },
      },
      colors: ['#ED4D53', '#61AA82'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: meses(),
      },
      yaxis: {
        labels: {
          formatter: value => utils.maskCurrency(Number(value)),
        },
        title: {
          text: 'Quantidade X Valor',
          offsetX: -8,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            color: `${skin.value === 'dark' ? '#ffffff' : '#6e6b7b'}`,
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter(value, { seriesIndex }) {
            if (seriesIndex === 1) {
              return utils.maskCurrency(Number(value))
            }
            return value
          },
        },
      },
    }

    const grafico = ref(null)
    
    watch(() => quantidades(), value => {
      grafico.value.updateSeries([{
        name: 'Quantidade',
        data: value,
      }, {
        name: 'Valor (R$)',
        data: valores(),
      }])
    })

    watch(() => valores(), value => {
      grafico.value.updateSeries([{
        name: 'Quantidade',
        data: quantidades(),
      }, {
        name: 'Valor (R$)',
        data: value,
      }])
    })

    watch(() => skin.value, value => {
      options.title.style.color = `${value === 'dark' ? '#ffffff' : '#6e6b7b'}`
      options.subtitle.style.color = `${value === 'dark' ? '#ffffff' : '#6e6b7b'}`
      options.chart.foreColor = `${value === 'dark' ? '#ffffff' : '#373d3f'}`
      if (options.yaxis && options.yaxis.title) {
        options.yaxis.title.style.color = `${skin.value === 'dark' ? '#ffffff' : '#6e6b7b'}`
      }
      if (options.yaxis && options.yaxis[0] && options.yaxis[0].title) {
        options.yaxis[0].title.style.color = `${skin.value === 'dark' ? '#ffffff' : '#6e6b7b'}`
      }
      if (grafico && grafico.value) {
        grafico.value.updateOptions(options)
      }
    })

    return {
      ...toRefs(state),
      options,
      dadoMeses,
      quantidades,
      grafico,
    }
  },
}
</script>

<style scoped>

</style>