<template>
  <b-card
    no-body
    class="mb-2"
  >
    <b-card-header class="p-2">
      <b-card-title class="font-medium-2 card-title">
        <feather-icon
          icon="HomeIcon"
          size="18"
        />
        <span class="align-middle ml-50">
          Remetente
        </span>
      </b-card-title>
    </b-card-header>
    <hr class="my-0">
    <div class="p-2">

      <b-row>
        <b-col 
          v-if="mostrarDepartamento"
          cols="12"
        >
          <validation-provider
            #default="validationContext"
            name="Departamento"
            :rules="{ required: temDepartamento }"
          >
            <b-form-group
              label="Departamento"
              label-for="departamento"
            >
              <v-select
                id="departamento"
                v-model="remetente.clienteDepartamentoId"
                :options="selectDepartamentos"
                :reduce="val=>val.value"
                :clearable="false"
                @input="(value) => atualizaEndereco(value)"
              />
              <span class="undermessage-error">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required"
          >
            <b-form-group
              label="Nome *"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="remetente.nomeFantasia"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Remetente"
                disabled
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="remetente.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="remetente@exemplo.com"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Celular"
            rules="phone"
          >
            <b-form-group
              label="Celular"
              label-for="celular"
            >
              <b-form-input
                id="celular"
                v-model="remetente.telefone"
                v-cleave="cleaveOptions.phone"
                trim
                placeholder="(11) 00000-0000"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="CEP"
            rules="required"
          >
            <b-form-group
              label="CEP *"
              label-for="cep"
            >
              <b-form-input
                id="cep"
                v-model="remetente.cep"
                v-cleave="cleaveOptions.cep"
                trim
                placeholder="00000-000"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="UF"
            rules="required"
          >
            <b-form-group
              label="UF *"
              label-for="uf"
            >
              <b-form-input
                id="uf"
                v-model="remetente.uf"
                :state="getValidationState(validationContext)"
                trim
                placeholder="SP"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Cidade"
            rules="required"
          >
            <b-form-group
              label="Cidade *"
              label-for="cidade"
            >
              <b-form-input
                id="cidade"
                v-model="remetente.cidade"
                :state="getValidationState(validationContext)"
                trim
                placeholder="São Paulo"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Bairro"
            rules="required"
          >
            <b-form-group
              label="Bairro *"
              label-for="bairro"
            >
              <b-form-input
                id="bairro"
                v-model="remetente.bairro"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Centro"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Logradouro"
            rules="required"
          >
            <b-form-group
              label="Logradouro *"
              label-for="logradouro"
            >
              <b-form-input
                id="logradouro"
                v-model="remetente.logradouro"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Av. Brasil"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Numero"
            rules="required"
          >
            <b-form-group
              label="Número *"
              label-for="numero"
            >
              <b-form-input
                id="numero"
                v-model="remetente.numero"
                :state="getValidationState(validationContext)"
                trim
                placeholder="110"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Complemento"
          >
            <b-form-group
              label="Complemento"
              label-for="complemento"
            >
              <b-form-input
                id="complemento"
                v-model="remetente.complemento"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Sala 220"
                disabled
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </div>
    <hr class="my-0">
    <div class="p-2">
      <b-row class="flex-row-reverse">
        <div
          style="display:flex; align-items: center; justify-content: center"
        >
          <b-button
            variant="primary"
            class="mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
          >
            Próximo
            <feather-icon
              icon="ArrowRightIcon"
            />
          </b-button>
        </div>
        <div
          style="display:flex; align-items: center; justify-content: center"
        />
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { watch, reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { mapGetters } from 'vuex'

import cleaveOptions from '@/libs/cleave/options'

export default {
  name: 'Remetente',
  props: {
    getValidationState: {
      type: Function,
      required: true,
    },
    remetente: {
      type: undefined,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      firebaseUser: 'firebase/firebaseUser',
    }),
  },

  beforeMount() {
    const user = JSON.parse(localStorage.getItem('userName'))
    this.$store.dispatch('departamento/findAllByUsuarioId', user.usuarioId)
    this.$store.dispatch('cliente/findById', user.clienteId)
  },

  setup(props, { emit }) {
    const clienteStore = createNamespacedHelpers('cliente')
    const findClienteById = clienteStore.useActions(['findById']).findById
    const departamentoStore = createNamespacedHelpers('departamento')
    const findDepartamentoById = departamentoStore.useActions(['findById']).findById

    const state = reactive({
      clienteOptions: clienteStore.useGetters(['cliente']).cliente,
      departamentoOptions: departamentoStore.useGetters(['departamentos']).departamentos,
      departamento: departamentoStore.useGetters(['departamento']).departamento,
      cliente: clienteStore.useGetters(['cliente']).cliente,
      selectDepartamentos: [],
      temDepartamento: false,
      mostrarDepartamento: false,
    })

    // se for definido que nao sera necessario depto, remover este bloco
    // watch(() => state.departamentoOptions, value => {
    //   state.temDepartamento = !!value[0].clienteDepartamentoId
    //   value.forEach(elemento => {
    //     state.selectDepartamentos.push({ label: elemento.nomeDepartamento,
    //       value: elemento.clienteDepartamentoId })
    //   })
    // })

    watch(() => state.clienteOptions, value => {
      emit('update:remetente', value)
    })

    const atualizaEndereco = value => {
      if (value) {
        findDepartamentoById(value)
          .then(departamento => {
            props.remetente.nomeDepartamento = departamento.nomeDepartamento
            if (departamento.temEndereco) {
              props.remetente.cep = departamento.cep
              props.remetente.uf = departamento.uf
              props.remetente.cidade = departamento.cidade
              props.remetente.bairro = departamento.bairro
              props.remetente.logradouro = departamento.logradouro
              props.remetente.numero = departamento.numero
              props.remetente.complemento = departamento.complemento
            } else {
              const user = JSON.parse(localStorage.getItem('userName'))
              findClienteById(user.clienteId)
                .then(cliente => {
                  props.remetente.cep = cliente.cep
                  props.remetente.uf = cliente.uf
                  props.remetente.cidade = cliente.cidade
                  props.remetente.bairro = cliente.bairro
                  props.remetente.logradouro = cliente.logradouro
                  props.remetente.numero = cliente.numero
                  props.remetente.complemento = cliente.complemento
                })
                .finally(() => {
                  props.remetente.clienteDepartamentoId = value
                })
            }
          })
      }
    }

    return {
      ...toRefs(state),
      cleaveOptions,
      atualizaEndereco,
    }
  },
}
</script>

<style scoped>

</style>