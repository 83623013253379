import httpClient from './http-clients/portaljad'

const baseUrl = 'movimentacoes'

export default class MovimentacaoService {
  static async findAll() {
    try {
      const { data } = await httpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(id) {
    try {
      const { data } = await httpClient.get([baseUrl, id]).join('/')
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
  
  static async save(cliente) {
    try {
      const { data } = await httpClient.post(baseUrl, cliente)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async cacelarMovimentacao(movimentacao) {
    try {
      const { data } = await httpClient.post([baseUrl, 'cancelar'].join('/'), movimentacao)
      return data
    } catch (error) {
      throw error.response.data
    }
  }
}