import MovimentacaoService from '@/service/MovimentacaoService'
import adapter from '@/adapters/ClienteAdapter'

export default {
  namespaced: true,
  state: {
    movimentacao: [],
  },
  getters: { 
    movimentacao(state) { 
      return state.movimentacao 
    },
  },
  mutations: {
    SAVE_MOVIMENTACAO(state, movimentacao) {
      state.movimentacao.push(movimentacao)
    },
    UPDATE_SELECTED(state, movimentacao) {
      state.selected = movimentacao
    },
  },
  actions: {
    async save({ commit }, movimentacao) {
      const movimentacaoId = await MovimentacaoService.save(adapter.toApi(movimentacao))
      const movimentacaoToSave = { ...movimentacao, movimentacaoId }
      commit('SAVE_MOVIMENTACAO', movimentacaoToSave)
    },

    async findById({ commit }, movimentacaoId) {
      const movimentacao = await MovimentacaoService.findById(movimentacaoId)
      commit('UPDATE_SELECTED', adapter.toStore(movimentacao))
    },

    async cancelarMovimento(_, movimentacao) {
      const mov = await MovimentacaoService.cacelarMovimentacao(movimentacao)
      return mov
    },
  },
}
