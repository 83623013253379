<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar Tratativa
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          ref="formTratativas"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Cte"
            rules="required"
          >
            <b-form-group
              label="Cte"
              label-for="cte"
            >
              <b-form-input
                id="cte"
                v-model="selected.cte"
                readonly
                :state="getValidationState(validationContext)"
                trim
                placeholder="Cte"
              />
              <span 
                v-if="validationContext.errors[0]"
                class="undermessage-error text-danger"
              >
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Status da Ocorrência"
            rules="required"
          >
            <b-form-group
              label="Status da Ocorrência"
              label-for="statusTratativa"
            >
              <v-select
                v-model="tratativa.statusTratativa"
                :options="tratativasOptions"
                :reduce="val=>val.value"
              />
              <span
                v-if="validationContext.errors[0]"
                class="undermessage-error text-danger"
              >
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Descrição"
            rules="required"
          >
            <b-form-group
              label="Descrição"
              label-for="descricao"
            >
              <b-textarea
                id="descricao"
                v-model="tratativa.descricao"
                placeholder="Descrição da tratativa"
              />
              <span
                v-if="validationContext.errors[0]"
                class="undermessage-error text-danger"
              >
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Acareação"
            rules="required"
          >
            <b-form-group
              label="Acareação"
              label-for="acareacao"
            >
              <v-select
                v-model="tratativa.acareacao"
                :disabled="statusAcareacao()"
                :options="acareacao"
                :reduce="val=>val.value"
              />
              <span
                v-if="validationContext.errors[0]"
                class="undermessage-error text-danger"
              >
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
import swal from 'sweetalert2/dist/sweetalert2.min'
import { reactive, toRefs, watch } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'
import formValidation from '@core/comp-functions/forms/form-validation'
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default {
  name: 'TratativaSideBar',
  emits: ['aoSalvar'],
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const tratativaStore = createNamespacedHelpers('tratativa')
    const { save } = tratativaStore.useActions(['save'])

    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const appLoading = $themeConfig.app.appLoadingImage

    const blankTratativas = {
      cte: null,
      statusTratativa: 'PENDENTE',
      descricao: null,
      acareacao: false,
      clienteId: null,
    }

    const tratativasOptions = [
      { value: 'PENDENTE', label: 'Pendente' },
      { value: 'FINALIZADO', label: 'Finalizado' },
    ]

    const acareacao = [
      { value: true, label: 'Sim' },
      { value: false, label: 'Não' },
    ]

    const state = reactive({
      selected: tratativaStore.useGetters(['selected']).selected,
      tratativa: _.cloneDeep(blankTratativas),
    })

    const onSubmit = () => {
      const tratativaSave = state.tratativa
      tratativaSave.cte = state.selected.cte
      tratativaSave.clienteId = clienteId
      appLoading.show()
      try {
        save(tratativaSave).then(response => {
          appLoading.hide()
          emit('aoSalvar')
          if (!response.success) {
            swal.fire({
              icon: 'info',
              html: `${response.body}`,
              showConfirmButton: true,
            })
          } else {
            swal.fire({
              icon: 'success',
              text: 'Tratativa salva com sucesso!',
              showConfirmButton: true,
            })
          }
        })
      } catch (error) {
        appLoading.hide()
        swal.fire({
          icon: 'error',
          title: 'Erro!',
          html: `Erro ao salvar tratativa: ${error}`,
          showConfirmButton: true,
        })
      }
    }

    const statusAcareacao = () => {
      if (state.selected.situacaoTracking === 'ENTREGUE' || state.selected.situacaoTracking === 'DEVOLVIDO') {
        return false
      } return true
    }

    watch(() => props.active, value => {
      if (value) {
        state.tratativa = _.cloneDeep(blankTratativas)
      }
    })

    const { refFormObserver, getValidationState, resetForm } = formValidation()

    return {
      ...toRefs(state),
      statusAcareacao,
      acareacao,
      tratativasOptions,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>