<template>
  <div>
    <b-card
      no-body
      class="mb-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="9"
          class="d-flex justify-content-start"
        >
          <b-card-header class="p-2">
            <b-card-title class="font-medium-2 card-title">
              <span class="align-middle">
                Raio-X por Serviço
              </span>
            </b-card-title>
          </b-card-header>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="d-flex justify-content-end"
        >
          <div class="p-1">
            <b-button
              variant="light"
              class="px-2"
              title="Mostrar / ocultar filtros"
              @click="toggleFiltros"
            >
              <feather-icon
                icon="MenuIcon"
                size="18"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row 
        v-if="visualizaFiltros"
        class="py-2"
      >
        <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start px-3 mb-1 mb-md-0"
        >
          <label class="mr-1">Buscar serviço</label>
          <v-select
            id="servico"
            v-model="filtros.servicos"
            multiple
            :options="selectServicos"
            :reduce="val=>val.value"
            :clearable="true"
            style="min-width: 300px;"
            @input="atualiza"
          >
            <span 
              slot="no-options"
              @click="$refs.select.open = false"
            >
              Não há dados
            </span>
          </v-select>
          <b-form-checkbox
            id="todos-servicos"
            v-model="todosServicos"
            class="align-middle ml-2"
          >
            Selecionar todos os serviços
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start px-3 mb-1 mb-md-0"
        >
          <div>
            <span class="mt-2">
              <b>Valor Total: </b>{{ maskCurrency(Number(valorTotal())) }}
            </span>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="my-2">
      <b-col 
        v-for="(dado, index) in dadosServicos()"
        :key="index"
        cols="3"
        style="min-width: 340px;"
      >
        <servicoCard
          :dado="dado"
          :dado-meses="dadoMeses(dado.servicoId)"
        />
      </b-col>
    </b-row>
    <b-card
      no-body
      class="mb-2"
    >
      <hr class="my-0">
      <servicoGrafico
        :dados.sync="dados"
        :dados-meses.sync="dadosMeses"
      />
    </b-card>
    
  </div>
</template>

<script>
import { reactive, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'
import moment from 'moment'
import store from '@/store'
import utils from '@/helpers/utils'
import servicoGrafico from './components/servicoGrafico.vue'
import servicoCard from './components/servicoCard.vue'

export default {
  name: 'RaioXServicos',
  components: { servicoGrafico, servicoCard },
  setup() {
    const { appCarregando } = $themeConfig.app
    
    const relatorioStore = createNamespacedHelpers('raioXServicos')

    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const state = reactive({
      dateRange: {
        startDate: moment()
          .subtract(180, 'days')
          .format('YYYY-MM-DD'),
        endDate: new Date().toISOString().split('T')[0],
      },
      selectServicos: relatorioStore.useGetters(['selectServicos']).selectServicos,
      todosServicos: false,
      visualizaFiltros: false,
      filtros: {
        servicos: [],
        agrupamento: 'SERVICO',
        clienteId,
      },
      dados: relatorioStore.useGetters(['dados']).dados,
      dadosMeses: relatorioStore.useGetters(['dadosMeses']).dadosMeses,
    })

    const toggleFiltros = () => {
      state.visualizaFiltros = !state.visualizaFiltros
    }

    const selecionaTodosServicos = selectServicos => {
      state.filtros.servicos = []
      selectServicos.forEach(servico => {
        state.filtros.servicos.push(servico.value)
      })
    }
    
    watch(() => state.selectServicos, value => {
      selecionaTodosServicos(value)
    })

    const sendRequest = filtros => {
      appCarregando.show()
      store.dispatch('raioXServicos/pesquisar', filtros)
        .then(dados => {
          appCarregando.hide()
          const ids = dados.map(d => d.servicoId)
          const obj = {
            ...filtros,
            servicoId: ids,
          }
          store.dispatch('raioXServicos/findAll', obj)
        })
    }

    const dadoMeses = servicoId => state.dadosMeses.filter(d => Number(d.servicoId) === Number(servicoId))

    const onSubmit = () => {
      const filtros = {
        ...state.filtros,
        dataInicial: state.dateRange.startDate, 
        dataFinal: state.dateRange.endDate,
      }
      sendRequest(filtros)
    }

    const objFiltros = {
      clienteId,
      dataInicial: state.dateRange.startDate, 
      dataFinal: state.dateRange.endDate,
    }
    store.dispatch('raioXServicos/findAllServicos', objFiltros)

    onSubmit()

    const atualiza = value => {
      const filtros = {
        ...state.filtros,
        servicoId: value,
        dataInicial: state.dateRange.startDate, 
        dataFinal: state.dateRange.endDate,
      }
      store.dispatch('raioXServicos/pesquisar', filtros)
        .then(() => {
          const obj = {
            ...filtros,
          }
          store.dispatch('raioXServicos/findAll', obj)
        })
    }

    watch(() => state.todosServicos, value => {
      if (value === true) {
        selecionaTodosServicos(state.selectServicos)
        atualiza(state.selectServicos.map(s => s.value))
      } else {
        state.filtros.servicos = []
        atualiza([])
      }
    })

    const dadosServicos = () => state.dados.filter(s => state.filtros.servicos.includes(s.servicoId))

    const valorTotal = () => state.dados.filter(s => state.filtros.servicos.includes(s.servicoId)).map(d => d.valor).reduce((a, b) => a + b, 0)

    return {
      ...toRefs(state),
      onSubmit,
      ...utils,
      dadoMeses,
      selecionaTodosServicos,
      toggleFiltros,
      atualiza,
      dadosServicos,
      valorTotal,
    }
  },
}
</script>
<style scoped>

</style>