<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
      >
        <b-img
          fluid
          :src="imgLogo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Redefinir sua senha
          </b-card-title>
          <b-card-text class="mb-2">
            Digite o endereço de email correspondente à conta que você quer redefinir a senha.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validaCampos"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="email"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                @click="enviar()"
              >
                Enviar e-mail de redefinição
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link @click="backToLogin()">
              <feather-icon icon="ChevronLeftIcon"/>
              Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { ref } from '@vue/composition-api'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data() {
    return {
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      appLogo: $themeConfig.app.appLogoImageRed,
      appLoading: $themeConfig.app.appLoadingImage,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.appLogo = $themeConfig.app.appLogoImageWhite
        return this.appLogo
      }
      return this.appLogo
    },
  },
  created() {
    this.appLoading.hide()
  },
  methods: {
    async validaCampos() {
      await this.$refs.simpleRules.validate()
    },
    enviar() {
      if (this.email) {
        this.appLoading.show()
        firebase.auth().sendPasswordResetEmail(this.email)
          .then(() => {
            this.success()
          })
          .catch(() => {
            this.error()
          })
      }
    },
    success() {
      this.appLoading.hide()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Não esqueça de verificar sua caixa de spam!',
          icon: 'MailIcon',
          variant: 'warning',
        },
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Email Enviado!',
          icon: 'MailIcon',
          variant: 'success',
        },
      })
    },
    error() {
      this.appLoading.hide()
      this.$swal({
        icon: 'error',
        title: 'Algo deu errado',
        text: 'Verifique se o email está correto',
        showConfirmButton: false,
        timer: 3000,
      })
    },
    backToLogin() {
      firebase.auth().signOut()
      this.$router.push('/auth/login')
    },
  },
  setup() {
    const email = ref('')
    return { email }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>