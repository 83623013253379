export default {
  namespaced: true,
  state: {
    user: null,
  },

  getters: {
    user(state) {
      return state.user
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
  },
  actions: {
    async setCurrentUser({ commit }, user) {
      commit('SET_USER', user)
    },
  },
}