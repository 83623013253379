import TratativaService from '../../service/TratativaService'

export default {
  namespaced: true,
  state: {
    tratativas: [],
    selected: {},
  },
  getters: {
    tratativa(state) {
      return state.tratativas
    },
    selected(state) {
      return state.selected
    },
  },
  mutations: {
    UPDATE_TRATATIVA(state, tratativa) {
      state.tratativas = tratativa
    },
    UPDATE_SELECTED(state, selected) {
      state.selected = selected
    },
    SAVE_TRATATIVA(state, tratativa) {
      state.tratativas.push(tratativa)
    },
    EDIT_TRATATIVA(state, tratativa) {
      const index = state.tratativas.findIndex(tra => tra.tratativaId === tratativa.tratativaId)
      Object.assign(state.tratativas[index], tratativa)
    },
    DELETE_TRATATIVA(state, tratativaId) {
      const index = state.tratativas.findIndex(tra => tra.tratativaId === tratativaId)
      state.tratativas.splice(index, 1)
    },
  },
  actions: {
    async findAll({ commit }, filtros) {
      const tratativa = await TratativaService.findAll(filtros)
      commit('UPDATE_TRATATIVA', tratativa)
      return tratativa
    },
    async findAllEntregas({ commit }, filtros) {
      const tratativa = await TratativaService.findAllEntregas(filtros)
      commit('UPDATE_TRATATIVA', tratativa)
      return tratativa
    },
    async findById({ commit }, tratativaId) {
      const tratativa = await TratativaService.findById(tratativaId)
      commit('UPDATE_SELECTED', tratativa)
    },
    async save({ commit }, tratativa) {
      const resp = await TratativaService.save(tratativa)
      if (resp.data) {
        commit('SAVE_TRATATIVA', resp.data)
        return resp.data
      } return null
    },
    async edit({ commit }, { tratativaId, tratativa }) {
      const edited = await TratativaService.edit(tratativaId, tratativa)
      commit('EDIT_TRATATIVA', edited)
    },
    async deleteById({ commit }, tratativaId) {
      await TratativaService.deleteById(tratativaId)
      commit('DELETE_TRATATIVA', tratativaId)
    },
    async setSelected({ commit }, tratativa) {
      commit('UPDATE_SELECTED', tratativa)
    },
  },
}