<template lang="">
  <div
    v-if="infoValue.length > 0"
    class="info"
    @mouseover="hoover = true"
  >
    <label class="infoLabel">Informações</label>
    <feather-icon
      icon="InfoIcon"
      size="21"
      class="text-danger stroke-current"
    />
    <div
      v-if="hoover"
      class="popUpInfo tail"
    >
      <div class="popUpHeader">
        <label for=""> Legenda </label>
        <feather-icon
          icon="XIcon"
          size="21"
          cursor="pointer"
          class="text-danger stroke-current iconPopUp"
          @click="hoover = false"
        />
      </div>
      <div
        v-for="(item, index) in infoValue"
        :key="index"
        class="d-flex flex-column align-items-center"
      >
        <div class="col-12">
          <label class="text">
            <label>{{ item.text }}</label>
          </label>
        </div>
        <div
          class="d-flex col-10 flex-column align-content-start boxInfo"
        >
          <div
            v-for="(box, ind) in item.info"
            :key="ind"
          >
            <span>{{ box.value }} - {{ box.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, computed, toRefs, watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import _ from 'lodash'

export default {
  name: 'InfoSideBar',
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    const blankInformation = [
      // { label: 'Texto', 
      //   info: [
      //     { text: 'texto', value: 'K' },
      //     { text: 'texto', value: 'K' },
      //     { text: 'texto', value: 'K' },
      //   ],
      //   text: 'Tipo da Coleta',
      // },
      // { label: 'Texto', 
      //   info: [
      //     { text: 'texto', value: 'K' },
      //     { text: 'texto', value: 'K' },
      //     { text: 'texto', value: 'K' },
      //   ],
      //   text: 'Tipo da Coleta',
      // },
    ]

    const state = reactive({
      infoValue: blankInformation,
      hoover: true,
    })
    
    watch(() => state.infoValue, value => {
      if (value !== null && value.lenght > 0) {
        state.infoValue = _.cloneDeep(value)
      } else {
        state.infoValue = _.cloneDeep(blankInformation)
      }
    })

    return {
      ...toRefs(state),
      skin,
      isDark,
    }
  },
}
</script>
<style lang="scss">
.info {
  position: fixed;
  left: 90vw;
  top: 96vh;
}

.infoLabel {
  padding-right: 5px;
}

.header {
  align-items: start;
  .text {
    margin: 15px 0 10px 30px;
  }

  .iconPopUp {
    margin-right: 20px;
  }
}

.boxInfo {
  width: 80%;
}

.popUpInfo {
  position: fixed;
  background-color: #f0f0f0;
  left: 78%;
  top: 53%;
  border-radius: 4px;
  width: 20%;
  height: 40%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

  .popUpHeader {
    display: flex;
    justify-content: space-between;
    margin: 15px 15px 5px 15px;
  }
}

.popUpInfo::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
}

.tail.popUpInfo::after {
  border-top-color: #f0f0f0;
  bottom: -40px;
  left: 74%;
}

</style>