export default class LayoutAdapter {
  static toApi(layout) {
    return {
      ...layout,
    }
  }

  static toStore(layout) {
    return {
      ...layout,
    }
  }
}