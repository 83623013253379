<template>
  <div style="padding-bottom: 80px">
    <endereco-reversa-side-bar
      :active.sync="showEnderecoReversaSidebar"
      @aoSalvar="atualizarPagina"
    />
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header
        class="p-2"
        style="display:flex; align-items: center; justify-content: start"
      >
        <b-card-title class="font-medium-2 card-title">
          <span class="align-middle ml-50">
            Endereço Reversa
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <div class="p-2">
        <b-row>
          <b-col>
            <div class="mb-2">
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-input
                    v-model="search"
                    class="d-inline-block mr-1"
                    placeholder="Buscar..."
                    debounce="500"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <label>Mostrando</label>
                  <v-select
                    v-model="perPage"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <label>endereços por página</label>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <b-form-checkbox
                    v-model="mostrarInativos"
                  >
                    Mostrar Endereços Inativas
                  </b-form-checkbox>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="d-flex justify-content-end"
                >
                  <b-button 
                    variant="primary"
                    @click="showEnderecoReversaSidebar = true"
                  >
                    Registrar novo endereço
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <b-table 
              ref="tableRef"
              :items="enderecos"
              :fields="fields"
              :per-page="perPage"
              :filter="search"
              :current-page="currentPage"
              caption-top
              show-empty
              empty-text="Não há itens a exibir"
            >
              <template #cell(ativo)="data">
                <b-badge
                  v-if="data.item.ativo"
                  variant="success"
                >
                  SIM
                </b-badge>
                <b-badge
                  v-else
                  variant="light-primary"
                >
                  NÃO
                </b-badge>
              </template>
              <template #cell(dataGeracao)="data">
                <span>{{ maskDate(data.item.dataGeracao) }}</span>
              </template>
              <template #cell(acoes)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item 
                    :to="{ name: 'endereco-reversa-editar', params: { enderecoReversaId: data.item.id } }"
                  >
                    <feather-icon icon="Edit3Icon"/>
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="m-2">
              <b-row v-if="totalRows > 0">
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Mostrando do 
                    {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
                    {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                      totalRows
                    }} resultados</span>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from '@vue/composition-api'
import store from '@/store'
import Utils from '@/helpers/utils'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import EnderecoReversaSideBar from './components/endereco-reversa-sidebar'

export default {
  name: 'EnderecoReversa',
  components: { EnderecoReversaSideBar },
  data() {
    return {
      fields: [
        { label: 'id', key: 'id', sortable: true },
        { label: 'Nome Identificador', key: 'nomeIdentificador', sortable: true },
        { label: 'data geração', key: 'dataGeracao', sortable: true },
        { label: 'nome destinatário', key: 'nomeFantasia', sortable: true },
        { label: 'logradouro', key: 'logradouro', sortable: true },
        { label: 'ativo', key: 'ativo', sortable: true },
        { label: 'Ações', key: 'acoes', sortable: false },
      ],
    }
  },
  setup() {
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))
    const enderecoReversaStore = createNamespacedHelpers('enderecoReversa')

    const state = reactive({
      enderecos: enderecoReversaStore.useGetters(['getEnderecoReversa']).getEnderecoReversa,
      showEnderecoReversaSidebar: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      carregando: false,
      search: '',
      mostrarInativos: false,
    })

    watch(() => state.enderecos, value => {
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    watch(() => state.mostrarInativos, value => {
      if (!value) {
        store.dispatch('enderecoReversa/findAll', clienteId)
          .then(() => store.dispatch('enderecoReversa/mostrarInativos'))
      } else {
        store.dispatch('enderecoReversa/findAll', clienteId)
      }
    })

    const sendRequest = () => {
      state.carregando = true
      store.dispatch('enderecoReversa/findAll', clienteId)
        .then(() => {
          store.dispatch('enderecoReversa/mostrarInativos')
          state.carregando = false
        })
    }

    const atualizarPagina = () => {
      state.showEnderecoReversaSidebar = false
      sendRequest()
    }

    sendRequest()

    return {
      ...toRefs(state),
      ...Utils,
      atualizarPagina,
      sendRequest,
    }
  },
}
</script>

<style>
</style>
