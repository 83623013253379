import EnderecoReversaService from '@/service/EnderecoReversaService'

export default {
  namespaced: true,
  state: {
    enderecoReversa: [],
    selected: {},
    enderecoReversaOptions: [],
  },

  getters: {
    getEnderecoReversa(state) {
      return state.enderecoReversa
    },
    getSelected(state) {
      return state.selected
    },
    getEnderecoReversaOptions(state) {
      return state.enderecoReversaOptions
    },
  },

  mutations: {
    SAVE(state, enderecoReversa) {
      state.enderecoReversa.push(enderecoReversa)
    },
    UPDATE(state, enderecoReversa) {
      state.enderecoReversa = enderecoReversa
    },
    UPDATE_SELECTED(state, enderecoReversa) {
      state.selected = enderecoReversa
    },
    EDIT(state, enderecoReversa) {
      state.selected = enderecoReversa
    },
    UPDATE_OPTIONS(state, enderecoReversa) {
      const enderecosAtivos = enderecoReversa.filter(end => end.ativo)
      const options = [{
        label: 'Padrão',
        value: 0,
      }].concat(enderecosAtivos.map(end => ({
        label: end.nomeFantasia,
        value: end.id,
      })))
      state.enderecoReversaOptions = options
    },
    MOSTRAR_INATIVOS(state) {
      state.enderecoReversa = state.enderecoReversa.filter(end => end.ativo === true)
    },
  },

  actions: {
    async save({ commit }, enderecoReversa) {
      const endRev = await EnderecoReversaService.save(enderecoReversa)
      if (endRev.success) {
        const id = endRev.body
        const endSalvo = { ...enderecoReversa, id }
        commit('SAVE', endSalvo)
      }
      return endRev
    },

    async edit({ commit }, enderecoReversa) {
      const endRev = await EnderecoReversaService.edit(enderecoReversa)
      if (endRev.success) {
        commit('EDIT', endRev.body)
      }
      return endRev
    },

    async findAll({ commit }, clientId) {
      const endRev = await EnderecoReversaService.findAll(clientId)
      commit('UPDATE', endRev)
      commit('UPDATE_OPTIONS', endRev)
    },

    async findById({ commit }, id) {
      const endRev = await EnderecoReversaService.findById(id)
      commit('UPDATE_SELECTED', endRev)
      return endRev
    },

    async changeStatus(_, id) {
      await EnderecoReversaService.changeStatus(id)
    },

    async mostrarInativos({ commit }) {
      commit('MOSTRAR_INATIVOS')
    },
  },
}