<template>
  <div style="padding-bottom: 80px">
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header
        class="p-2"
        style="display:flex; align-items: center; justify-content: flex-start"
      >
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="FileIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Criar Layout de Importação Reversa
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <div class="p-2">
        <b-row>
          <b-col>
            <h5>Dados do Arquivo Importado:</h5>
            <b-table 
              v-if="items.length > 0"
              :items="items"
              :fields="fields"
              responsive
              sticky-header="275px"
            >
              <template #cell(index)="item">
                {{ item.index + 1 }}
              </template>
            </b-table>
            <div 
              v-else
              class="mt-0 mb-3"
            >
              <b-form-textarea
                :value="texto"
                rows="3"
                max-rows="6"
                disabled
              />
            </div>
          </b-col>
        </b-row>
        <hr class="my-0">
        <h5 class="mt-3 mb-1">
          Defina o nome e linha inicial do Layout:
        </h5>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Nome do Layout"
              rules="required"
            >
              <b-form-group
                label="Nome do Layout"
                label-for="nome-layout"
              >
                <b-form-input
                  id="nome-layout"
                  v-model="layout.nomeLayout"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="Número da Linha Inicial"
              :rules="{ required: true, numeric: true }"
            >
              <b-form-group
                label="Número da Linha Inicial"
                label-for="numero-linha-inicial"
              >
                <b-form-input
                  id="numero-linha-inicial"
                  v-model="layout.linhaInicial"
                  :formatter="apenasNumeros"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <h5 class="mt-2 mb-1">
          Defina {{ dadosArquivo.tipoArquivo === 'csv' ? 'as colunas' : 'os locais' }} do arquivo para os campos necessários para a importação de envios reversos:
        </h5>
        <b-row 
          v-for="(campo, index) in layoutParametros"
          :key="index"
        >
          <b-col
            align-self="center"
            cols="12"
            md="2"
          >
            <label>{{ `${campo.label} ${campo.obrigatorio ? '*' : ''}` }}:</label>
          </b-col>
          <template v-if="dadosArquivo.tipoArquivo === 'csv'">
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                :vid="`Coluna ${index}`"
                name="Coluna"
                :rules="{ required: campo.obrigatorio }"
              >
                <b-form-group
                  label="Escolha a Coluna"
                >
                  <v-select
                    v-model="layout.parametros[index]"
                    :options="campo.options"
                    :reduce="value => value.dados"
                    :clearable="true"
                    @input="(value) => resultadoColuna(index, value, validaColuna)"
                  />
                  <span class="undermessage-error">
                    {{ validationContext.errors[0] }}
                  </span>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                :ref="`validationColuna${index}`"
                #default="validationContext"
                name="Resultado da coluna"
                :rules="{ 
                  max: campo.tamanhoMaximo,
                  numeric: campo.formatoParametro === 'INTEIRO',
                  regex: campo.formatoParametro === 'DATA' ? /^\d{2}\/\d{2}\/\d{4}$/ : 
                    (campo.nomeParametro === 'cep_remetente' ? /^[0-9]{5}-?[0-9]{3}$/ : '')
                }"
              >
                <b-form-group
                  label="Resultado da coluna escolhida"
                >
                  <b-form-input
                    v-model="resultadoColunaList[index]"
                    :state="getValidationState(validationContext)"
                    readonly
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              align-self="center"
              cols="12"
              md="4"
            >
              <label>{{ campo.observacao }}</label>
            </b-col>
          </template>
          <template v-else>
            <b-col
              cols="12"
              md="2"
            >
              <validation-provider
                #default="validationContext"
                :vid="`Início ${index}`"
                name="Início"
                :rules="{ required: campo.obrigatorio }"
              >
                <b-form-group
                  label="Escolha o início"
                >
                  <b-form-input
                    v-model="layout.parametros[index].inicio"
                    :formatter="apenasNumeros"
                    :state="getValidationState(validationContext)"
                    @change="() => resultadoIntervalo(index, validaColuna)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <validation-provider
                #default="validationContext"
                :vid="`Fim ${index}`"
                name="Fim"
                :rules="{ 
                  required: campo.obrigatorio, 
                  minValue: (Number(layout.parametros[index].inicio) + 1)
                }"
              >
                <b-form-group
                  label="Escolha o fim"
                >
                  <b-form-input
                    v-model="layout.parametros[index].fim"
                    :formatter="apenasNumeros"
                    :state="getValidationState(validationContext)"
                    @change="() => resultadoIntervalo(index, validaColuna)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                :ref="`validationColuna${index}`"
                #default="validationContext"
                name="Resultado da coluna"
                :rules="{ 
                  max: campo.tamanhoMaximo,
                  numeric: campo.formatoParametro === 'INTEIRO',
                  regex: campo.formatoParametro === 'DATA' ? /^\d{2}\/\d{2}\/\d{4}$/ : 
                    (campo.nomeParametro === 'cep_destinatario' ? /^[0-9]{5}-?[0-9]{3}$/ : '')
                }"
              >
                <b-form-group
                  label="Resultado da coluna escolhida"
                >
                  <b-form-input
                    v-model="resultadoColunaList[index]"
                    :state="getValidationState(validationContext)"
                    readonly
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              align-self="center"
              cols="12"
              md="3"
            >
              <label>{{ campo.observacao }}</label>
            </b-col>
          </template>
        </b-row>
      </div>
      <hr class="my-0">
      <div class="p-2">
        <b-row class="flex-row-reverse">
          <div
            style="display:flex; align-items: center; justify-content: center"
          >
            <b-button
              variant="primary"
              class="mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Concluir
              <feather-icon
                icon="CheckIcon"
              />
            </b-button>
          </div>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { watch, reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import store from '@/store'

export default {
  name: 'Campos',
  props: {
    getValidationState: {
      type: Function,
      required: true,
    },
    layout: {
      type: undefined,
      required: true,
    },
  },
  beforeMount() {
    this.$store.dispatch('layoutParametro/findAllReversa')
  },
  setup(props) {
    const dadosArquivoStore = createNamespacedHelpers('arquivoImportacao')

    const layoutParametroStore = createNamespacedHelpers('layoutParametro')

    const state = reactive({
      dadosArquivo: dadosArquivoStore.useGetters(['dadosArquivo']).dadosArquivo,
      items: [],
      fields: [],
      headers: [],
      anteriorPrimeiraLinha: [],
      primeiraLinha: [],
      layoutParametrosSt: layoutParametroStore.useGetters(['layoutParametros']).layoutParametros,
      resultadoColunaList: [],
      optionsObj: [],
      texto: '',
    })
    
    const layoutParametros = []
    
    watch(() => state.dadosArquivo, value => {
      if (value.tipoArquivo === 'csv') {
        state.layoutParametrosSt.forEach(lp => layoutParametros.push(lp))
        state.fields = []
        state.items = []
        state.headers = []
        state.anteriorPrimeiraLinha = []
        state.primeiraLinha = []
        state.resultadoColunaList = []
        state.texto = ''
        Object.values(value.linhas).slice(1).forEach(linha => state.items.push(linha))
        Object.values(value.linhas[0]).forEach((header, index) => { 
          state.fields.push({ label: header, key: `${index}`, sortable: true, thStyle: { minWidth: '300px' } })
        })
        state.headers = Object.values(value.linhas[0])
        Object.values(value.linhas[1]).forEach(texto => { 
          state.anteriorPrimeiraLinha.push(texto)
        })
        Object.values(value.linhas[2]).forEach(texto => { 
          state.primeiraLinha.push(texto)
        })
        state.optionsObj = state.headers.map((header, index) => ({
          descricao: `${header} - ${state.anteriorPrimeiraLinha[index]}`,
          coluna: index,
          resultadoColuna: state.primeiraLinha[index],
        }))
        store.dispatch('layoutParametro/addOptions', state.optionsObj)
        layoutParametros.forEach(() => { 
          state.resultadoColunaList.push('')
        })
      } else {
        Object.values(value.linhas).forEach(texto => {
          state.texto += texto
        })
        Object.values(value.linhas[1]).forEach(texto => {
          state.anteriorPrimeiraLinha.push(texto)
        })
        Object.values(value.linhas[2]).forEach(texto => {
          state.primeiraLinha.push(texto)
        })
        state.layoutParametrosSt.forEach(lp => {
          props.layout.parametros.push({
            nomeParametro: lp.nomeParametro,
            descricaoColuna: lp.label,
            inicio: null,
            fim: null,
          })
          layoutParametros.push(lp)
        })
        layoutParametros.forEach(() => { 
          state.resultadoColunaList.push('')
        })
      }
    })

    const resultadoColuna = (index, value, validaColuna) => {
      state.resultadoColunaList.splice(index, 1, value ? state.primeiraLinha[value.coluna] : '')
      validaColuna(index, state.resultadoColunaList[index])
    }

    const resultadoIntervalo = (index, validaColuna) => {
      const { inicio, fim } = props.layout.parametros[index]
      if (inicio && fim) {
        const resultado = state.primeiraLinha[0].substring((inicio - 1), fim).trim()
        state.resultadoColunaList.splice(index, 1, resultado)
        props.layout.parametros[index].resultadoColuna = resultado
      } else {
        state.resultadoColunaList.splice(index, 1, '')
      }
      validaColuna(index, state.resultadoColunaList[index])
    }

    watch(() => props.layout.linhaInicial, value => {
      const valoresAnteriorPrimeiraLinha = state.dadosArquivo.linhas[value - (value > 1 ? 1 : 0)]
      const valoresPrimeiraLinha = state.dadosArquivo.linhas[value]
      if (valoresPrimeiraLinha) {
        valoresPrimeiraLinha.forEach((texto, index) => {
          state.primeiraLinha.splice(index, 1, texto)
        })
      }
      if (valoresAnteriorPrimeiraLinha) {
        valoresAnteriorPrimeiraLinha.forEach((texto, index) => {
          state.anteriorPrimeiraLinha.splice(index, 1, texto)
        })
        state.headers.forEach((header, index) => {
          const newOptionsObj = {
            descricao: `${header} - ${state.anteriorPrimeiraLinha[index]}`,
            coluna: index,
            resultadoColuna: state.primeiraLinha[index],
          }
          state.optionsObj.splice(index, 1, newOptionsObj)
        })
        store.dispatch('layoutParametro/addOptions', state.optionsObj)
      }
    })
    
    return {
      ...toRefs(state),
      layoutParametros,
      resultadoColuna,
      resultadoIntervalo,
    }
  },
  methods: {
    validaColuna(index, valor) {
      const validation = this.$refs[`validationColuna${index}`]
      validation[0].validate(valor)
    },
    apenasNumeros(value) {
      return value.replace(/\D/g, '')
    },
  },
}
</script>