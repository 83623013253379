import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'
import { $themeConfig } from '@themeConfig'

// Routes
// import MainPage from '@/views/main-page'
import LoginPage from '@/views/authentication/login'
import VerifyEmail from '@/views/authentication/verify-email'
import ForgotPassword from '@/views/authentication/forgot-password'
import GerarEtiquetas from '@/views/envios/gerar-etiquetas'
import GerarReversa from '@/views/envios/gerar-reversa/index.vue'
import GerarReversaManual from '@/views/envios/gerar-reversa/manual'
import GerarReversaImportacao from '@/views/envios/gerar-reversa/importacao'
import GerarRomaneio from '@/views/envios/gerar-romaneio'
import GerarEtiquetasManual from '@/views/envios/gerar-etiquetas/manual'
import GerarEtiquetasImportacao from '@/views/envios/gerar-etiquetas/importacao'
import GerarEtiquetasIntegracao from '@/views/envios/gerar-etiquetas/integracao'
import GerarEtiquetasIntegracaoVendas from '@/views/envios/gerar-etiquetas/integracao/vendas'
import ImprimirEtiquetas from '@/views/envios/imprimir-etiquetas'
import ImprimirEtiquetasChave from '@/views/envios/imprimir-etiquetas-chave'
import ImprimirReversa from '@/views/envios/imprimir-reversa/index.vue'
import CotacaoFrete from '@/views/envios/cotacao-frete'
import Pedidos from '@/views/envios/pedidos'
import TratativaEntregas from '@/views/envios/tratativa-entregas/index.vue'
import Faturas from '@/views/envios/faturas'
import OverviewEnvios from '@/views/envios/overview-envios'
import FaturasVisualizar from '@/views/envios/faturas/visualizar'
import Boleto from '@/views/envios/faturas/boleto'
import LayoutsImportacao from '@/views/cadastro/layouts-importacao'
import LayoutsImportacaoReversa from '@/views/cadastro/layouts-importacao-reversa'
import LayoutsVisualizar from '@/views/cadastro/layouts-importacao/visualizar'
import LayoutsVisualizarReversa from '@/views/cadastro/layouts-importacao-reversa/visualizar'
import NovoLayoutImportacao from '@/views/cadastro/layouts-importacao/criar'
import NovoLayoutImportacaoReversa from '@/views/cadastro/layouts-importacao-reversa/criar'
import Relatorios from '@/views/envios/relatorios'
import RaioXServicos from '@/views/envios/raio-x-servicos'
import EnderecoReversa from '@/views/cadastro/enderaco-reversa'
import EnderecoReversaEditar from '@/views/cadastro/enderaco-reversa/editar'
import LogsComponent from '@core/components/logs'
import TrackExterno from '@/views/track-externo/index.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', component: OverviewEnvios, name: 'main-page' },
    { path: '/track', component: TrackExterno, name: 'track-externo', meta: { layout: 'none' } },
    { path: '/track/:id', component: TrackExterno, name: 'track-externo-id', meta: { layout: 'none' } },
    { path: '/auth/login', component: LoginPage, name: 'login', meta: { layout: 'full' } },
    { path: '/auth/login/:email/:senha', component: LoginPage, name: 'login', meta: { layout: 'full' } },
    { path: '/auth/verify-email', component: VerifyEmail, name: 'verify-email', meta: { layout: 'full' } },
    { path: '/auth/forgot-password', component: ForgotPassword, name: 'forgot-password', meta: { layout: 'full' } },
    { path: '/envios/etiquetas', component: GerarEtiquetas, name: 'geracao-etiquetas' },
    { path: '/envios/etiquetas/:page', component: GerarEtiquetas, name: 'geracao-etiquetas' },
    { path: '/envios/gerar-reversa', component: GerarReversa, name: 'gerar-reversa' },
    { path: '/envios/gerar-reversa/:page', component: GerarReversa, name: 'gerar-reversa' },
    { path: '/envios/gerar-reversa-manual', component: GerarReversaManual, name: 'geracao-reversa-manual' },
    { path: '/envios/gerar-reversa/importacao', component: GerarReversaImportacao, name: 'geracao-reversa-importacao' },
    { path: '/envios/gerar-romaneio', component: GerarRomaneio, name: 'gerar-romaneio' },
    { path: '/envios/etiquetas-manual', component: GerarEtiquetasManual, name: 'geracao-etiquetas-manual' },
    { path: '/envios/etiquetas/importacao', component: GerarEtiquetasImportacao, name: 'geracao-etiquetas-importacao' },
    { path: '/envios/etiquetas/importacao/:redirecionado', component: GerarEtiquetasImportacao, name: 'geracao-etiquetas-importacao' },
    { path: '/envios/etiquetas/integracao', component: GerarEtiquetasIntegracao, name: 'geracao-etiquetas-integracao' },
    { path: '/envios/etiquetas/integracao/bling', component: GerarEtiquetasIntegracao, name: 'geracao-etiquetas-integracao' },
    { path: '/envios/etiquetas/integracao/vendas/:lojaId', component: GerarEtiquetasIntegracaoVendas, name: 'geracao-etiquetas-integracao-vendas' },
    { path: '/envios/imprimir-etiquetas', component: ImprimirEtiquetas, name: 'imprimir-etiquetas' },
    { path: '/envios/imprimir-etiquetas/:dataDeHoje', component: ImprimirEtiquetas, name: 'imprimir-etiquetas' },
    { path: '/envios/imprimir-etiquetas-chave', component: ImprimirEtiquetasChave, name: 'imprimir-etiquetas-chave' },
    { path: '/envios/imprimir-reversa', component: ImprimirReversa, name: 'imprime-reversa' },
    { path: '/envios/imprimir-reversa/:dataDeHoje', component: ImprimirReversa, name: 'imprime-reversa' },
    { path: '/envios/cotacao-frete', component: CotacaoFrete, name: 'cotacao-frete' },
    { path: '/envios/pedidos', component: Pedidos, name: 'pedidos' },
    { path: '/envios/overview-envios', component: OverviewEnvios, name: 'overview-envios' },
    { path: '/envios/tratativa-entregas', component: TratativaEntregas, name: 'tratativa-entregas' },
    { path: '/envios/relatorios', component: Relatorios, name: 'relatorios' },
    { path: '/envios/raio-x-servicos', component: RaioXServicos, name: 'raio-x-servicos' },
    { path: '/envios/faturas', component: Faturas, name: 'faturas' },
    { path: '/envios/faturas/visualizar', component: FaturasVisualizar, name: 'faturas-visualizar' },
    { path: '/envios/faturas/boleto', component: Boleto, name: 'boleto' },
    { path: '/cadastro/layouts-importacao', component: LayoutsImportacao, name: 'layouts-importacao' },
    { path: '/cadastro/layouts-importacao-reversa', component: LayoutsImportacaoReversa, name: 'layouts-importacao-reversa' },
    { path: '/cadastro/layouts-importacao/novoLayout', component: NovoLayoutImportacao, name: 'novo-layout-importacao' },
    { path: '/cadastro/layouts-importacao-reversa/novoLayout', component: NovoLayoutImportacaoReversa, name: 'novo-layout-importacao-reversa' },
    { path: '/cadastro/layouts-importacao/visualizar/:layoutId', component: LayoutsVisualizar, name: 'layouts-visualizar' },
    { path: '/cadastro/layouts-importacao-reversa/visualizar/:layoutId', component: LayoutsVisualizarReversa, name: 'layouts-visualizar-reversa' },
    { path: '/cadastro/endereco-reversa', component: EnderecoReversa, name: 'endereco-reversa' },
    { path: '/cadastro/endereco-reversa/:enderecoReversaId', component: EnderecoReversaEditar, name: 'endereco-reversa-editar' },
    { path: '/logs', component: LogsComponent, name: 'logs' },
    { path: '*', redirect: 'error-404' },
  ],
})

router.beforeEach((to, _, next) => {
  const { currentUser } = firebase.auth()
  if (to.path.startsWith('/track')) {
    return next()
  }
  if (window.location.href.includes('/track')) {
    return next({ name: 'track-externo-id' })
  }
  const loggedIn = localStorage.getItem('loggedIn')
  if (currentUser && currentUser.emailVerified && to.fullPath.indexOf('/auth') >= 0) {
    localStorage.setItem('loggedIn', new Date())
    return next({ name: 'main-page' })
  }
  if (currentUser && !currentUser.emailVerified && to.fullPath.indexOf('/auth') < 0) {
    return next({ name: 'verify-email', params: null })
  }
  if (!currentUser && to.fullPath.indexOf('/auth') < 0 && !loggedIn) {
    return next({ name: 'login' })
  }
  if (to.fullPath.indexOf('/auth') >= 0) {
    return next()
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  const { appCarregando } = $themeConfig.app
  appCarregando.hide()
})

export default router
