import HttpClient from './http-clients/portaljad'

const baseUrl = 'departamento'

export default class DepartamentoService {
  static async findAll(clienteId) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'departamentos', clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAllByUsuarioId(usuarioId) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'departamentos-usuario', usuarioId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(departamentoId) {
    try {
      if (!departamentoId) {
        return undefined
      }
      const { data } = await HttpClient.get([baseUrl, departamentoId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}