import httpClient from './http-clients/portaljad'

const baseUrl = 'endereco-reversa'

export default class EnderecoReversaService {
  static async save(enderecoReversa) {
    try {
      const { data } = await httpClient.post(baseUrl, enderecoReversa)
      return data
    } catch (error) {
      return error.response.data
    }
  }

  static async edit(enderecoReversa) {
    try {
      const { data } = await httpClient.put(baseUrl, enderecoReversa)
      return data
    } catch (error) {
      return error.response.data
    }
  }

  static async findById(id) {
    try {
      const { data } = await httpClient.get([baseUrl, 'end', id].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAll(clientId) {
    try {
      const { data } = await httpClient.get([baseUrl, clientId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async changeStatus(id) {
    try {
      await httpClient.put([baseUrl, id].join('/'))
    } catch (error) {
      throw error.response.data
    }
  }
}