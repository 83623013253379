<template>
  <div>
    <tratativa-sidebar
      :active.sync="showTratativaSidebar"
      @aoSalvar="atualizarPagina"
    />
    <cte-tracking-sidebar
      :active.sync="showCteTrackingSidebar"
      @aoFechar="limpaCte"
    />
    <b-card
      no-body
      class="mb-2"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="validationSearch"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit('NOVA_PESQUISA'))">
          <b-card-header class="p-2">
            <b-card-title class="font-medium-2 card-title">
              <feather-icon
                icon="SearchIcon"
                size="18"
              />
              <span class="align-middle ml-50">Pesquisa Envios</span>
            </b-card-title>
          </b-card-header>
          <hr class="my-0">
          <div class="p-2">
            <b-row>
              <b-col
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange"/>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="CTE"
                >
                  <b-form-group
                    label="CTE"
                    label-for="cte"
                  >
                    <b-form-input
                      id="cte"
                      v-model="filtros.cte"
                      :state="getValidationState(validationContext)"
                      placeholder="000"
                    />
                  </b-form-group>
                  <span class="undermessage-error">
                    {{ validationContext.errors[ 0 ] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="Shippment Id"
                >
                  <b-form-group
                    label="Shippment Id"
                    label-for="shippmentId"
                  >
                    <b-form-input
                      id="shippmentId"
                      v-model="filtros.shippmentId"
                      :state="getValidationState(validationContext)"
                      placeholder="000"
                    />
                  </b-form-group>
                  <span class="undermessage-error">
                    {{ validationContext.errors[ 0 ] }}
                  </span>
                </validation-provider>
              </b-col>

              <b-col
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  rules="noAlpha"
                  name="Nota Fiscal"
                >
                  <b-form-group
                    label="Nota Fiscal"
                    label-for="notaFiscal"
                  >
                    <b-form-input
                      id="notaFiscal"
                      v-model="filtros.numeroNotaFiscal"
                      :state="getValidationState(validationContext)"
                      placeholder="00000"
                    />
                  </b-form-group>
                  <span class="undermessage-error">
                    {{ validationContext.errors[ 0 ] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
              >
                <b-form-group
                  label="Tipo de Envio"
                  label-for="tipo"
                >
                  <v-select
                    id="tipo"
                    v-model="filtros.reversa"
                    :options="reversaOptions"
                    :reduce="val=>val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                class="justify-content-end d-flex"
              >
                <div class="pt-2">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mb-sm-0 mr-0 mr-sm-1"
                  >
                    Buscar
                    <feather-icon
                      class="ml-1 d-md-none d-xl-inline d-inline"
                      icon="SearchIcon"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card
      no-body
      class="mb-2"
    >
      <template v-if="showResultsTable">
        <b-card-header class="p-2">
          <b-card-title class="font-medium-2 card-title">
            <feather-icon
              icon="SearchIcon"
              size="18"
            />
            <span class="align-middle ml-50"> Resultados </span>
          </b-card-title>
          <b-col
            md="3"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              class="px-3"
              @click="gerarExcel"
            >
              <span class="text-nowrap">Exportar Excel</span>
            </b-button>
          </b-col>
        </b-card-header>
        <hr class="my-0">
        <div class="m-2">
          <b-row>
            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="search"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
                  debounce="1000"
                />
              </div>
            </b-col>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrando</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="changePerPage"
              />
              <label>envios por página</label>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="tableRef"
          responsive
          class="position-relative"
          :items="dados.lista"
          :fields="resultsTableColumns"
          show-empty
          stacked="md"
          :empty-text="carregando ? 'Carregando...' : 'Nenhum resultado encontrado.'"
          :no-local-sorting="true"
          @sort-changed="sortingChanged"
        >
          <template #cell(qtd)="{}">
            <div>
              1
            </div>
          </template>
          <template #cell(dataEnvio)="data">
            <div>
              {{ data.item.dataEnvio ? maskDate(data.item.dataEnvio) : '' }}
            </div>
          </template>
          <template #cell(nomeServico)="data">
            <div>
              {{ serviceDictionary(data.item.nomeServico) }}
            </div>
          </template>
          <template #cell(dataPrazoEstimado)="data">
            <div>
              {{ data.item.dataPrazoEstimado ? maskDate(data.item.dataPrazoEstimado) : '' }}
            </div>
          </template>
          <template #cell(cte)="data">
            <div
              class="hyperlink"
              @click="abrirCteTracking(data.item.cte)"
            >
              {{ data.item.cte }}
            </div>
          </template>
          <template #cell(shippmentId)="data">
            <a
              :href="abirTrack(data.item.shippmentId)" 
              target="_blank"
              rel="noopener noreferrer"
              class="hyperlink"
            >
              {{ data.item.shippmentId }}
            </a>
          </template>
          <template #cell(cep)="data">
            <div>
              {{ data.item.cep ? maskCep(data.item.cep) : '' }}
            </div>
          </template>
          <template #cell(nome)="data">
            <div>
              {{ data.item.nome }}
            </div>
          </template>
          <template #cell(valorParaSeguro)="data">
            <div>
              {{ data.item.valorParaSeguro ? maskCurrency(Number(data.item.valorParaSeguro)) : '' }}
            </div>
          </template>
          <template #cell(valorTotalTaxado)="data">
            <div>
              {{ data.item.valorTotalTaxado ? maskCurrency(Number(data.item.valorTotalTaxado)) : '' }}
            </div>
          </template>
          <template #cell(possuiTratativa)="data">
            <div>
              {{ data.item.possuiTratativa ? 'Sim' : 'Não' }}
            </div>
          </template>
          <template #cell(dataUltimoEvento)="data">
            <div>
              {{ data.item.dataUltimoEvento ? maskDateHour(data.item.dataUltimoEvento) : '' }}
            </div>
          </template>
          <template #cell(reversa)="data">
            {{ data.item.reversa ? 'Reversa' : 'Envio' }}
          </template>
          <template #cell(situacao)="data">
            <b-badge
              class="badge badge-light-secondary"
            >
              {{ data.item.situacao }}
            </b-badge>
          </template>
          <template #cell(detalhes)="data">
            <b-button
              variant="link"
              @click="openModal(data)"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
              />
            </b-button>
          </template>
          <template 
            #cell(acoes)="data"
          >
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item 
                @click="abrirAdicionarTariva(data)"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">Abrir Tratativa</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template slot="bottom-row">
            <td/><td/>
            <td class="table-footer">
              TOTAL
            </td>
            <td class="table-footer">
              {{ dados != null && dados.totalQuantidade || '0' }}
            </td>
            <td class="table-footer">
              {{ dados != null && dados.totalValorParaSeguro ? maskCurrency(dados.totalValorParaSeguro) : '0' }}
            </td>
            <td class="table-footer">
              {{ dados != null && dados.totalValorTotalTaxado ? maskCurrency(dados.totalValorTotalTaxado) : '0' }}
            </td>
          </template>
        </b-table>
        <hr class="mt-0 mb-1">
        <div class="m-2">
          <b-row v-if="dados && dados.totalRows > 0">
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Mostrando do 
                {{ Math.min(((currentPage - 1) * perPage) + 1, dados.totalRows) }}º até o 
                {{ Math.min(((currentPage - 1) * perPage) + perPage, dados.totalRows) }}º de {{
                  dados.totalRows
                }} resultados</span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="dados.totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
    <b-modal
      ref="modal"
      title="Detalhes"
      ok-only
      ok-title="OK"
      size="xl"
      style="max-height: 1000px"
    >
      <details-card :data="modalData" />
    </b-modal>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { onBeforeUnmount, reactive, ref, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import moment from 'moment'
import utils from '@/helpers/utils'
import swal from 'sweetalert2/dist/sweetalert2.min'
import store from '@/store'
import ExcelComposition from '@/components/exportar/excel.composition'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import DateRange from '@/components/form/DateRange.vue'
import DetailsCard from '../components/results_modal'
import TratativaSidebar from '../components/tratativa-sidebar'
import CteTrackingSidebar from '../components/cte-tracking-sidebar'

export default {
  name: 'IndexVue',
  components: {
    DetailsCard,
    DateRange,
    TratativaSidebar,
    CteTrackingSidebar,
  },
  setup() {
    const { appCarregando } = $themeConfig.app
    const pedidosStore = createNamespacedHelpers('pedidos')
    const movimentacaoEventoStore = createNamespacedHelpers('movimentacaoEvento')

    const { setCte } = movimentacaoEventoStore.useActions(['setCte'])
    const { setDataEnvio } = movimentacaoEventoStore.useActions(['setDataEnvio'])

    const tratativaStore = createNamespacedHelpers('tratativa')
    const setSelectedTratativa = tratativaStore.useActions(['setSelected']).setSelected

    const modal = ref(null)
    const tableRef = ref(null)

    const { clienteId, empresaId } = JSON.parse(localStorage.getItem('userName'))

    const reversaOptions = [
      { value: false, label: 'Envio' },
      { value: true, label: 'Reversa' },
    ]

    const state = reactive({
      dateRange: {
        startDate: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        endDate: new Date().toISOString().split('T')[0],
      },
      filtros: {
        cte: null,
        numeroNotaFiscal: null,
        shippmentId: null,
        filtroGeral: '',
        dataInicial: new Date().toISOString().split('T')[0], 
        dataFinal: new Date().toISOString().split('T')[0],
        reversa: false,
        clienteId,
      },
      dados: pedidosStore.useGetters(['dados']).dados,
      movimentacaoIds: pedidosStore.useGetters(['movimentacaoIds']).movimentacaoIds,
      modalData: pedidosStore.useGetters(['detalhes']).detalhes,
      showResultsTable: false,
      showTratativaSidebar: false,
      showCteTrackingSidebar: false,
      reversaOptions,
      validationSearch: null,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      carregando: false,
      search: '',
      order: {},
    })

    watch(() => state.carregando, value => {
      if (value) {
        appCarregando.show()
      } else {
        appCarregando.hide()
      }
    })

    const resultsTableColumns = [
      { key: 'shippmentId', sortable: true, thStyle: { whiteSpace: 'nowrap' } },
      { key: 'cte', sortable: true },
      { key: 'nomeServico', sortable: true, label: 'Serviço' },
      { key: 'quantidade', sortable: true, label: 'Qtd. Vol.', thStyle: { whiteSpace: 'nowrap' } },
      { key: 'valorParaSeguro', sortable: true, label: 'VD' },
      { key: 'valorTotalTaxado', sortable: true, label: 'Valor' },
      { key: 'dataEnvio', sortable: true, label: 'Data Envio', tdClass: 'text-nowrap', thStyle: { whiteSpace: 'nowrap' } },
      { key: 'dataPrazoEstimado', sortable: true, label: 'Prazo Estimado', thStyle: { whiteSpace: 'nowrap' } },
      { key: 'nome', sortable: true, label: 'Destinatário', tdClass: 'text-nowrap' },
      { key: 'cep', sortable: true, label: 'CEP Dest.', thStyle: { whiteSpace: 'nowrap' }, tdClass: 'text-nowrap' },
      { key: 'numeroNotaFiscal', sortable: true, label: 'NF', tdClass: 'text-nowrap' },
      { key: 'reversa', sortable: true, label: 'Tipo' },
      { key: 'possuiTratativa', sortable: true, label: 'Tratativa' },
      { key: 'dataUltimoEvento', sortable: true, tdClass: 'text-nowrap' },
      { key: 'ultimoEvento', sortable: true, label: 'Evento' },
      { key: 'Detalhes', sortable: false, label: 'Detalhes' },
      { key: 'acoes', sortable: false, label: 'Ações' },
    ]

    const reset = () => {
      state.filtros = {
        cte: null,
        numeroNotaFiscal: null,
        shippmentId: null,
        dataInicial: new Date().toISOString().split('T')[0], 
        dataFinal: new Date().toISOString().split('T')[0],
        clienteId,
        reversa: false,
      }
    }

    const resetDados = () => {
      state.dados.movimentacaoIds = []
      state.dados.totalQuantidade = 0
      state.dados.totalValorParaSeguro = 0
      state.dados.totalValorTotalTaxado = 0
      state.currentPage = 1
      state.dados.totalRows = 0
      state.search = ''
      state.order = {}
    }

    const sendRequest = filtros => {
      state.carregando = true
      store.dispatch('pedidos/findAllBusca', filtros).then(() => {
        resultsTableColumns[8].label = (state.filtros.reversa === true ? 'Remetente' : 'Destinatário')
        resultsTableColumns[9].label = (state.filtros.reversa === true ? 'CEP Reme.' : 'CEP Dest.')
        tableRef.value.refresh()
        state.carregando = false
      }).catch(() => {
        appCarregando.hide()
        swal.fire({
          icon: 'warning',
          title: 'Sem dados com os parametros de busca',
          showConfirmButton: false,
          timer: 3000,
        })
      })
    }

    const onSubmit = tipoPesquisa => {
      if (tipoPesquisa === 'NOVA_PESQUISA') {
        resetDados()
      }
      const filter = {
        ...state.filtros,
        dataInicial: state.dateRange.startDate,
        dataFinal: state.dateRange.endDate,
        situacao: state.situacoes,
        clienteId,
        order: state.order,
        currentPage: state.currentPage - 1,
        perPage: state.perPage,
        search: state.search,
        tipoPesquisa,
        selecionados: [],
      }
      if (tipoPesquisa === 'ORDENACAO') {
        filter.selecionados = state.dados.movimentacaoIdsSelecionados.filter(m => m.selecionado).map(m => m.id)
      }
      sendRequest(filter)
      state.showTratativaSidebar = false
      state.showResultsTable = true
    }

    onSubmit('NOVA_PESQUISA')

    onBeforeUnmount(() => {
      reset()
    })
 
    const { getValidationState, resetForm } = formValidation(reset)

    const openModal = data => {
      const { movimentacaoId } = data.item
      store.dispatch('pedidos/findDetalhesByMovimentacaoId', movimentacaoId).then(() => modal.value.show())
    }

    const atualizarPagina = () => {
      onSubmit('ATUALIZAR')
    }

    const limpaCte = () => {
      setCte('')
    }

    const abrirAdicionarTariva = data => {
      state.showTratativaSidebar = true
      setSelectedTratativa(data.item)
    }

    const abirTrack = data => (`/track/${empresaId}-${data}`)

    const abrirCteTracking = data => {
      setCte(data)
      setDataEnvio(state.dateRange.startDate)
      state.showCteTrackingSidebar = true
    }

    const { downloadSpreadsheet } = ExcelComposition()
    const downloadExcel = () => {
      const ids = state.dados.movimentacaoIds
      store.dispatch('pedidos/pesquisaExportar', { movimentacaoIds: ids, reversa: state.filtros.reversa })
        .then(dados => {
          if (dados.length === 0) {
            appCarregando.hide()
            swal.fire({
              icon: 'warning',
              title: 'Sem dados para exportar',
              text: 'O Resultado da sua busca não retornou dados para exportação.',
              showConfirmButton: false,
              timer: 3000,
            })
          } else {
            appCarregando.hide()
            // eslint-disable-next-line array-callback-return
            dados.map(d => {
              d.reversa = d.reversa ? 'Sim' : 'Não'
            })
            downloadSpreadsheet(dados, 'Pedidos')
          }
        })
    }
    const gerarExcel = () => {
      appCarregando.show()
      setTimeout(() => {
        downloadExcel()
      }, 100)
    }

    watch(() => state.search, () => {
      state.currentPage = 1
      onSubmit('FILTRAGEM')
    })

    const changePerPage = () => {
      state.currentPage = 1
      onSubmit('PAGINACAO')
    }

    const changePage = value => {
      state.currentPage = value
      onSubmit('PAGINACAO')
    }

    const sortingChanged = ctx => {
      state.order = {
        ...ctx,
      }
      if (state.dados && state.dados.totalRows > 1) {
        onSubmit('ORDENACAO')
      }
    }

    return {
      ...toRefs(state),
      ...utils,
      resultsTableColumns,
      getValidationState,
      atualizarPagina,
      abrirAdicionarTariva,
      abrirCteTracking,
      onSubmit,
      resetForm,
      modal,
      tableRef,
      openModal,
      gerarExcel,
      limpaCte,
      changePerPage,
      changePage,
      sortingChanged,
      abirTrack,
    }
  },
}
</script>
<style scoped>
  .form-group {
    min-width: 100%;
  }
  .table-footer {
    font-weight: bold;
    background: #E9E9E9;
    border-bottom: 3px solid #F55559;
    font-size: 1.2rem;
  }
  .widerTh {
    min-width: 30rem;
    margin: 0 30px;
  }
  .hyperlink {
    color: #263bdb;
    text-decoration: underline;
    cursor: pointer;
  }
</style>