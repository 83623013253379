import MovimentacaoErroService from '@/service/MovimentacaoErroService'

export default {
  namespaced: true,
  state: {
    movimentacaoErro: [],
  },
  getters: { 
    movimentacaoErro(state) { 
      return state.movimentacaoErro 
    },
  },
  mutations: {
    SET_MOVIMENTACAO_ERRO(state, movimentacaoErro) {
      state.movimentacaoErro = []
      movimentacaoErro.forEach(erro => {
        if (erro.erroTipo === 'IMPORTACAO_FTP') {
          const erroTratado = erro.erroMensagem.split(';').filter(em => em.trim() !== '')
          state.movimentacaoErro.push({
            title: 'Importação FTP parcialmente gerado',
            subtitle: erroTratado.map((et, index) => `${index + 1} ${et.trim()};`),
            data: erro.data,
            type: 'light-warning',
            icon: 'AlertTriangleIcon',
            erroTipo: erro.erroTipo,
            lido: erro.lido,
            layoutId: erro.layoutId,
          })
        } else {
          state.movimentacaoErro.push({
            title: 'Etiquetas não geradas',
            data: erro.data,
            subtitle: `${erro.quantidade} ${erro.erroMensagem}`,
            type: 'light-warning',
            icon: 'AlertTriangleIcon',
            erroTipo: erro.erroTipo,
            lido: erro.lido,
            layoutId: erro.layoutId,
          })
        }
      })
    },
  },
  actions: {
    async findAllByClienteId({ commit }, clienteId) {
      const movimentacaoErroLista = await MovimentacaoErroService.findAllByClienteId(clienteId)
      commit('SET_MOVIMENTACAO_ERRO', movimentacaoErroLista)
      return movimentacaoErroLista
    },
    async lerTodas(_, clienteId) {
      await MovimentacaoErroService.lerTodas(clienteId)
    },
  },
}
