import ImprimirRomaneioService from '@/service/ImprimirRomaneioService'

export default {
  namespaced: true,
  state: {
    romaneios: [],
    urlRomaneios: [],
  },
  getters: {
    romaneios(state) {
      return state.romaneios
    },
    urlRomaneios(state) {
      return state.urlRomaneios
    },
  },
  mutations: {
    SET_ROMANEIOS(state, romaneios) {
      state.romaneios = romaneios
    },
    SELECT_BY_ID(state, { etiquetaId, bool }) {
      const escolhido = state.romaneios.filter(obj => obj.movimentacaoId
        === etiquetaId)
      escolhido[0].selected = bool
    },
    SELECT_ALL_ROMANEIOS(state, bool) {
      state.romaneios = state.romaneios.map(romaneio => ({ ...romaneio, selected: bool }))
    },
    POST_ROMANEIO_URL(state, romaneios) {
      state.urlRomaneios = romaneios
    },
  },
  actions: {
    async findAll({ commit }, obj) {
      const lista = await ImprimirRomaneioService.findByDateAndSituation(obj)
      const romaneios = await lista.reduce((acc, romaneio) => [...acc, { ...romaneio, selected: false }], [])
      commit('SET_ROMANEIOS', romaneios)
    },
    selectById({ commit }, { etiquetaId, bool }) {
      commit('SELECT_BY_ID', { etiquetaId, bool })
    },
    selectAll({ commit }, bool) {
      commit('SELECT_ALL_ROMANEIOS', bool)
    },
    async deleteById(context, romaneioId) {
      await ImprimirRomaneioService.removeRomaneio(romaneioId)
    },
    async imprimirRomaneio({ commit }, { ids }) {
      await ImprimirRomaneioService.imprimirRomaneio(ids)
        .then(url => {
          commit('POST_ROMANEIO_URL', url)
        })
    },
    async reimprimirRomaneios({ commit }, { romaneiosIds }) {
      await ImprimirRomaneioService.reimprimirRomaneios(romaneiosIds)
        .then(url => {
          commit('POST_ROMANEIO_URL', url)
        })
    },
  },
}