<template>
  <div style="padding-bottom: 80px">
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header
        class="p-2"
        style="display:flex; align-items: center; justify-content: flex-start"
      >
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="FileIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Layout de Importação
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <div class="pt-2 px-2">
        <feather-icon
          icon="ArrowUpIcon"
          size="18"
        />
        <span class="align-middle ml-50 mr-3">
          Arquivo utilizado
        </span>
        <a
          :href="`${layout.urlArquivo}`"
          target="_blank"
        >
          {{ layout.urlArquivo ? layout.urlArquivo.split('/')[layout.urlArquivo.split('/').length - 1] : '' }}
        </a>
      </div>
      <hr class="my-2">
      <div class="p-2">
        <b-row>
          <b-col>
            <h5>Dados do Arquivo Importado:</h5>
            <b-table 
              v-if="items.length > 0"
              :items="items"
              :fields="fields"
              responsive
              sticky-header="275px"
            >
              <template #cell(index)="item">
                {{ item.index + 1 }}
              </template>
            </b-table>
            <div 
              v-else
              class="mt-0 mb-3"
            >
              <b-form-textarea
                :value="texto"
                rows="3"
                max-rows="6"
                disabled
              />
            </div>
          </b-col>
        </b-row>
        <hr class="my-0">
        <h5 class="mt-3 mb-1">
          Linha inicial do Layout:
        </h5>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-input
              id="nome-layout"
              v-model="layout.nomeLayout"
              readonly
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-input
              id="numero-linha-inicial"
              v-model="layout.linhaInicial"
              readonly
            />
          </b-col>
        </b-row>
        <h5 class="mt-2 mb-1">
          {{ dadosArquivo.tipoArquivo === 'csv' ? 'Colunas' : 'Locais' }} do arquivo para os campos necessários para a importação de envios:
        </h5>
        <b-row 
          v-for="(campo, index) in layoutParametros"
          :key="index"
        >
          <b-col
            align-self="center"
            cols="12"
            md="2"
          >
            <label>{{ campo.descricaoColuna }}:</label>
          </b-col>
          <template v-if="dadosArquivo.tipoArquivo === 'csv'">
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Coluna"
              >
                <b-form-input
                  v-model="campo.descricao"
                  readonly
                />
              </b-form-group>
            </b-col>
          </template>
          <template v-else>
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                label="Início"
              >
                <b-form-input
                  v-model="campo.inicio"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                label="Fim"
              >
                <b-form-input
                  v-model="campo.fim"
                  readonly
                />
              </b-form-group>
            </b-col>
          </template>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Resultado da coluna escolhida"
            >
              <b-form-input
                v-model="campo.resultadoColuna"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <hr class="my-0">
      <div class="p-2">
        <b-row class="flex-row-reverse">
          <div
            style="display:flex; align-items : center; justify-content: center"
          >
            <b-button
              :to="{ name: 'layouts-importacao' }"
            >
              Voltar
            </b-button>
          </div>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { watch, reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default {
  name: 'VisualizarLayout',
  beforeMount() {
    this.$store.dispatch('layout/findById', this.$route.params.layoutId)
    this.$store.dispatch('clienteLayoutParametro/findAllByLayoutId', this.$route.params.layoutId)
    this.$store.dispatch('arquivoImportacao/montaGridPeloLayoutId', this.$route.params.layoutId)
  },
  setup() {
    const dadosArquivoStore = createNamespacedHelpers('arquivoImportacao')
    const layoutStore = createNamespacedHelpers('layout')
    const layoutParametroStore = createNamespacedHelpers('clienteLayoutParametro')

    const state = reactive({
      layout: layoutStore.useGetters(['layout']).layout,
      dadosArquivo: dadosArquivoStore.useGetters(['dadosArquivo']).dadosArquivo,
      items: [],
      fields: [],
      headers: [],
      anteriorPrimeiraLinha: [],
      primeiraLinha: [],
      layoutParametros: layoutParametroStore.useGetters(['layoutParametros']).layoutParametros,
      resultadoColunaList: [],
      options: [],
      texto: '',
    })
    
    watch(() => state.dadosArquivo, value => {
      if (value.tipoArquivo === 'csv') {
        state.fields = []
        state.items = []
        Object.values(value.linhas).slice(1).forEach(linha => state.items.push(linha))
        Object.values(value.linhas[0]).forEach((header, index) => { 
          state.fields.push({ label: header, key: `${index}`, sortable: true, thStyle: { minWidth: '300px' } })
        })
        state.headers = Object.values(value.linhas[0])
      } else {
        Object.values(value.linhas).forEach(texto => {
          state.texto += texto
        })
      }
    })

    return {
      ...toRefs(state),
    }
  },
}
</script>