<template>
  <b-card 
    no-body 
    class="mb-2 p-2"
  >
    <b-row>
      <b-col md="10">
        <div class="titulo">
          <small>Serviço</small>
        </div>
        <div class="subtitulo font-weight-bold">
          {{ dado.agrupamento }}
        </div>
      </b-col>
      <b-col md="2">
        <b-form-checkbox
          v-model="dado.selecionado"
        />
      </b-col>
    </b-row>
    <b-row 
      v-if="!mostrarDetalhes"
      class="my-2"
    >
      <b-col md="4">
        <div class="text-center">
          Total
        </div>
        <div class="text-center card-detalhes">
          <small class="font-weight-bold">6 meses</small>
        </div>
      </b-col>
      <b-col md="3">
        <div class="text-center">
          Qtd.
        </div>
        <div class="text-center card-detalhes">
          <small class="font-weight-bold">{{ dado.quantidade }}</small>
        </div>
      </b-col>
      <b-col md="5">
        <div class="text-center">
          Valor
        </div>
        <div class="text-center card-detalhes">
          <small class="font-weight-bold">{{ maskCurrency(Number(dado.valor)) }}</small>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="mostrarDetalhes"
      class="my-1"
    >
      <b-col md="10">
        <div class="titulo">
          ÚLTIMOS 6 MESES
        </div>
      </b-col>
    </b-row>
    <template v-if="mostrarDetalhes">
      <b-row>
        <b-col md="4">
          <div class="text-center">
            Mês
          </div>
        </b-col>
        <b-col md="3">
          <div class="text-center">
            Qtd.
          </div>
        </b-col>
        <b-col md="5">
          <div class="text-center">
            Valor
          </div>
        </b-col>
      </b-row>
      <b-row
        v-for="(dadoMes, index) in dadoMeses"
        :key="index"
      >
        <b-col md="4">
          <div class="text-center card-detalhes mb-1">
            <small class="font-weight-bold">{{ dadoMes.agrupamento }}</small>
          </div>
        </b-col>
        <b-col md="3">
          <div class="text-center card-detalhes mb-1">
            <small class="font-weight-bold">{{ dadoMes.quantidade }}</small>
          </div>
        </b-col>
        <b-col md="5">
          <div class="text-center card-detalhes mb-1">
            <small class="font-weight-bold">{{ maskCurrency(Number(dadoMes.valor)) }}</small>
          </div>
        </b-col>
      </b-row>
    </template>
    <b-row
      v-if="mostrarDetalhes"
      class="my-2"
      @click="switchDetalhes()"
    >
      <b-col cols="10">
        <div class="mais-detalhes">
          Minimizar detalhes
        </div>
      </b-col>
      <b-col cols="2">
        <feather-icon
          class="plus-icon"
          icon="MinusIcon"
          size="15"
        />
      </b-col>
    </b-row>
    <b-row
      v-else
      class="my-2"
      @click="switchDetalhes()"
    >
      <b-col cols="10">
        <div class="mais-detalhes">
          Mais detalhes
        </div>
      </b-col>
      <b-col cols="2">
        <feather-icon
          class="plus-icon"
          icon="PlusIcon"
          size="15"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import utils from '@/helpers/utils'

export default {
  name: 'ServicoCard',
  props: {
    dado: {
      type: Object,
      required: true,
    },
    dadoMeses: {
      type: Array,
      required: true,
    },
  },
  
  setup() {
    const mostrarDetalhes = ref(false)
    const switchDetalhes = () => {
      mostrarDetalhes.value = !mostrarDetalhes.value
    }
    
    return {
      mostrarDetalhes,
      switchDetalhes,
      ...utils,
    }
  },
}
</script>

<style>
.card-detalhes {
  background-color: #EBF3FF !important;
  padding: 0.4em;
  margin-top: 5px;
  border-radius: 5px;
}
</style>