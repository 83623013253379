import httpClient from './http-clients/portaljad'

const baseUrl = 'arquivo-importacao'

export default class ArquivoImportacaoService {
  static async getDadosArquivo(formData) {
    try {
      const { data } = await httpClient.post([baseUrl, 'ler-arquivo'].join('/'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
  
  static async montaGridDadosArquivo(arquivoRequest) {
    try {
      const { data } = await httpClient.post([baseUrl, 'monta-grid'].join('/'), arquivoRequest, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async montaGridPeloLayoutId(layoutId) {
    try {
      const { data } = await httpClient.post([baseUrl, 'monta-grid-layout-id', layoutId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}