import httpClient from './http-clients/portaljad'

const baseUrl = 'gerar-etiqueta'

export default class MovimentacaoService {
  static async etiquetasPost(etiquetaId, tipoEtiqueta) {
    try {
      const { data } = await httpClient.post([baseUrl, tipoEtiqueta].join('/'), etiquetaId, { responseType: 'arraybuffer' })
      const blobPdf = new Blob([data], { type: 'application/pdf' })
      const url = URL.createObjectURL(blobPdf)
      return url
    } catch (error) {
      throw error.response.data
    }
  }

  static async etiquetasPostReversa(etiquetaId, tipoEtiqueta) {
    try {
      const { data } = await httpClient.post([baseUrl, 'reversa', tipoEtiqueta].join('/'), etiquetaId, { responseType: 'arraybuffer' })
      const blobPdf = new Blob([data], { type: 'application/pdf' })
      const url = URL.createObjectURL(blobPdf)
      return url
    } catch (error) {
      throw error.response.data
    }
  }

  static async etiquetasPostReversaQrCode(etiquetasId, clienteId) {
    try {
      const { data } = await httpClient.post([baseUrl, 'reversa', 'etiqueta', clienteId].join('/'), etiquetasId, { responseType: 'arraybuffer' })
      if (data.byteLength > 0) {
        const blobPdf = new Blob([data], { type: 'application/pdf' })
        const url = URL.createObjectURL(blobPdf)
        return url
      }
      return null
    } catch (error) {
      throw error.response.data
    }
  }

  static async declaracaoConteudoPost(etiquetaId, tipoEtiqueta, reversa) {
    try {
      const { data } = await httpClient.post([baseUrl, 'declaracao-conteudo', tipoEtiqueta, reversa].join('/'), etiquetaId, { responseType: 'arraybuffer' })
      if (data.byteLength > 0) {
        const blobPdf = new Blob([data], { type: 'application/pdf' })
        const url = URL.createObjectURL(blobPdf)
        return url
      }
      return null
    } catch (error) {
      return null
    }
  }

  static async danfePost(etiquetaId, tipoEtiqueta, reversa) {
    try {
      const { data } = await httpClient.post([baseUrl, 'danfe', tipoEtiqueta, reversa].join('/'), etiquetaId, { responseType: 'arraybuffer' })
      if (data.byteLength > 0) {
        const blobPdf = new Blob([data], { type: 'application/pdf' })
        const url = URL.createObjectURL(blobPdf)
        return url
      }
      return null
    } catch (error) {
      return null
    }
  }
}