import HttpClient from '@/service/http-clients/portaljad'

const baseUrl = 'logs'

export default class LogsService {
  static async findAllByLocal({ logLocal, idCliente }) {
    try {
      const { data } = await HttpClient.post([baseUrl, logLocal].join('/'), idCliente)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async saveLogLoginCliente(saveLogLogin) {
    try {
      await HttpClient.post([baseUrl, 'login', 'cliente'].join('/'), saveLogLogin)
    } catch (error) {
      throw error.response.data
    }
  }
}