import RemetenteService from '@/service/RemetenteService'
import adapter from '@/adapters/RemetenteAdapter'

export default {
  namespaced: true,
  state: {
    remetentes: [],
  },
  
  getters: {
    remetentes(state) {
      return state.remetentes
    },
  },
  mutations: {
    SAVE_DESTINATARIO(state, remetente) {
      state.remetentes.push(remetente)
    },
    UPDATE_DESTINATARIO(state, remetente) {
      state.remetentes = remetente
    },

  },
  actions: {
    async save({ commit }, remetente) {
      const remetenteId = await RemetenteService.save(adapter.toApi(remetente))
      const remetenteToSave = { ...remetente, remetenteId }
      commit('SAVE_DESTINATARIO', remetenteToSave)
    },
    async findAll({ commit }, clienteId) {
      const remetentes = await RemetenteService.findAll(clienteId)
      commit('UPDATE_DESTINATARIO', remetentes.map(dpto => adapter.toStore(dpto)))
      return remetentes
    },

    async findById({ commit }, remetenteId) {
      const remetente = await RemetenteService.findById(remetenteId)
      commit('UPDATE_DESTINATARIO', adapter.toStore(remetente))
    },
  },
}
