<!-- eslint-disable vue/no-template-shadow -->
<template>
  <b-form-group>
    <b-input-group>
      <template #prepend>
        <b-input-group-text 
          v-if="showSymbol"
        >
          R$
        </b-input-group-text>
      </template>
      <b-form-input
        ref="inputRef"
        :style="!showSymbol ? 'width: 100%!important' : ''"
        :readonly="readonly"
        placeholder="0,00"
        type="text"
        :state="validationState ? validationState(validationContext) : null"
      />
    </b-input-group>
    <span class="undermessage-error">
      {{ (validationState && !validationState(validationContext) && msgErro) ? msgErro : validationContext.errors[0] }}
    </span>
  </b-form-group>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: {
    id: {
      type: String,
      default: 'valor-monetario',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: 'Valor',
    },
    required: {
      type: Boolean,
      default: true,
    },
    validationState: {
      type: Function,
      default: null,
    },
    validationContext: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showSymbol: {
      type: Boolean,
      default: false,
    },
    msgErro: {
      type: String,
      default: null,
    },
  },
  watch: {
    value(value) {
      this.setValue(value)
    },
  },
  setup() {
    const options = {
      locale: 'pt-BR',
      currency: 'BRL',
      currencyDisplay: 'hidden',
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: true,
      useGrouping: true,
      accountingSign: false,
      precision: 2,
    }
    const { inputRef, setValue } = useCurrencyInput(options)

    return { 
      inputRef, 
      setValue,
    }
  },
}
</script>