import PesquisaService from '@/service/PesquisaService'

export default {
  namespaced: true,
  state: {
    dados: null,
  },
  getters: {
    dados(state) {
      return state.dados
    },
  },
  mutations: {
    SET_DADOS(state, dados) {
      state.dados = dados
    },
  },
  actions: {
    async pesquisar({ commit }, obj) {
      const dados = await PesquisaService.pesquisaRelatorio(obj)
      commit('SET_DADOS', dados)
    },
  },
}
