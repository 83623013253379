import httpTrack from './http-clients/portaljad'

const baseUrl = 'track-externo'

export default class FaturasService {
  static async findTrackValues(track) {
    try {
      const { data } = await httpTrack.get([baseUrl, track].join('/'), { skipFirebaseAuth: true })
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}