export default class EtiquetaAdapter {
  static toApi(etiqueta) {
    return {
      ...etiqueta,
      numeroDocumento: etiqueta.destinatario.numeroDocumento ? etiqueta.destinatario.numeroDocumento.replaceAll(/[.,()/\s-]+/g, '') : '',
      telefone: etiqueta.destinatario.celular ? etiqueta.destinatario.celular.replaceAll(/[.,()/\s-]+/g, '') : '',
      cep: etiqueta.destinatario.cep ? etiqueta.destinatario.cep.replaceAll(/[.,()/\s-]+/g, '') : '',
      ativo: true,
    }
  }

  static toStore(etiqueta) {
    return {
      ...etiqueta,
    }
  }
}