import HttpClient from './http-clients/portaljad'

const baseUrl = 'destinatario'

export default class DestinatarioService {
  static async save(destinatario) {
    try {
      const { data } = await HttpClient.post(baseUrl, destinatario)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
  
  static async findAll(clienteId) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'cliente', clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(destinatarioId) {
    try {
      if (!destinatarioId) {
        return undefined
      }
      const { data } = await HttpClient.get([baseUrl, destinatarioId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}
