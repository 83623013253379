import HttpClient from './http-clients/portaljad'

const baseUrl = 'usuarios/firebaseUid'

export default class UsuariosService {
  static async findByUid(firebaseUid, senha) {
    try {
      if (!firebaseUid) {
        return undefined
      }
      const { data } = await HttpClient.post(baseUrl, { firebaseUid, senha })
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}