import store from '@/store'
import router from '@/router'

export default function onAuthStateChanged(user) {
  const currentPath = router.currentRoute.path
  if (user) {
    store.dispatch('firebase/setFirebaseUser', user)
  }
  if (user && !user.emailVerified && currentPath !== '/verify-email') {
    router.push('/auth/verify-email').catch(() => {
    })
  }
  if (!user && currentPath.indexOf('/auth') < 0) {
    localStorage.removeItem('userToken')
    localStorage.removeItem('userName')
    localStorage.removeItem('msg-erro')
    store.dispatch('currentUser/setCurrentUser', null)
    router.push('/auth/login').catch(() => {
    })
  } else if (user && user.emailVerified && currentPath.indexOf('/auth') >= 0) {
    localStorage.setItem('loggedIn', new Date())
  }
}