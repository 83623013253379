<template>
  <div style="padding-bottom: 80px">
    <form-wizard
      ref="refFormWizard"
      color="#F55559"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      hide-buttons
      class="checkout-form-wizard steps-transparent"
      transition="fade"
      @on-change="resetArquivo"
    >
      <tab-content
        title="Escolha o arquivo"
        icon="feather icon-file"
      >
        <validation-observer
          ref="validatorArquivo"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(formWizardNextStep)"
            @reset.prevent="resetForm"
          >
            <arquivo-step 
              :get-validation-state="getValidationState"
              :data.sync="importacao"
            />
          </b-form>
        </validation-observer>
      </tab-content>
      <tab-content
        title="Configure os Campos"
        icon="feather icon-grid"
        :before-change="() => {}"
      >
        <validation-observer
          ref="validatorCampos"
          #default="{ validateWithInfo, handleSubmit }"
        >
          <b-form
            @submit.prevent="validaForm(validateWithInfo), handleSubmit(salvaLayoutImportacao)"
            @reset.prevent="resetForm"
          >
            <campos-step 
              :get-validation-state="getValidationState"
              :layout.sync="layout"
            />
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { reactive, ref, toRefs } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import swal from 'sweetalert2/dist/sweetalert2.min'
import { $themeConfig } from '@themeConfig'
import ArquivoStep from './steps/arquivo'
import CamposStep from './steps/campos'

export default {
  name: 'NovoLayoutImportacao',
  components: {
    FormWizard,
    TabContent,
    ArquivoStep,
    CamposStep,
  },
  setup() {
    const appLoading = $themeConfig.app.appLoadingImage

    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const state = reactive({
      importacao: {
        arquivo: null,
      },
      layout: {
        clienteId,
        nomeLayout: '',
        tipoLayout: 'CSV_ETIQUETA',
        linhaInicial: null,
        reversa: false,
        parametros: [],
      },
      dadosArquivo: {},
    })
    
    const refFormWizard = ref(null)
    
    const formWizardNextStep = () => {
      appLoading.show()
      state.dadosArquivo = {}
      const formData = new FormData()
      formData.append('file', state.importacao.arquivo)
      store.dispatch('arquivoImportacao/lerArquivo', formData)
        .then(dados => {
          state.dadosArquivo = dados
          state.layout = {
            ...state.layout,
            tipoLayout: dados.tipoArquivo === 'csv' ? 'CSV_ETIQUETA' : 'TXT_ETIQUETA',
          }
          refFormWizard.value.nextTab()
          appLoading.hide()
        })
    }
    
    const { refFormObserver, getValidationState, resetForm } = formValidation()

    const salvaLayoutImportacao = () => {
      appLoading.show()
      const multipart = new FormData()
      multipart.append('arquivo', state.importacao.arquivo)
      multipart.append('layout', JSON.stringify(
        { 
          ...state.layout,
        },
      ))
      store.dispatch('layout/save', multipart)
        .then(() => {
          router.push('/cadastro/layouts-importacao').then(appLoading.hide())
        }).catch(() => {
          appLoading.hide()
          swal.fire({
            icon: 'warning',
            html: 'Erro ao cadastrar Layout',
            showConfirmButton: true,
          })
        })
    }

    const resetArquivo = (prevIndex, nextIndex) => {
      if (nextIndex === 0) {
        state.importacao.arquivo = null
      }
    }
    
    return {
      ...toRefs(state),
      refFormWizard,
      formWizardNextStep,
      refFormObserver, 
      getValidationState, 
      resetForm,
      salvaLayoutImportacao,
      resetArquivo,
    }
  },
  methods: {
    validaForm(validate) {
      validate().then(v => {
        if (!v.isValid) {
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title:
                  'Há erros no formulário. Verifique!',
                icon: 'AlertCircleIcon',
                variant: 'primary',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}

.wizard-tab-content {
  display: flex;

  .wizard-tab-container {
    display: block;
    animation: fadeInRight 0.3s;
  }
}
</style>
