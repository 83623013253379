<template>
  <div class="">
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header class="p-2">
        <b-card-title class="font-medium-2 card-title">
          <feather-icon size="18"/>
          <span class="align-middle ml-50"> Faturas </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <b-table
        ref="refResultsTable"
        responsive
        class="position-relative"
        :items="faturasEncontradas"
        :fields="tableColumns"
        show-empty
        stacked="md"
        empty-text="Nenhum resultado encontrado."
      >
        <template #cell(valor)="data">
          <span>{{ maskCurrency(data.item.valor) }}</span>
        </template>
        <template #cell(tipoServico)="data">
          {{ serviceDictionary(data.item.tipoServico) }}
        </template>
      </b-table>
    </b-card>
    <b-card
      no-body
      class="mb-2"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { reactive, toRefs, ref, watch } from '@vue/composition-api'
import utils from '@/helpers/utils'

export default {
  name: 'FaturasVisualizar',

  beforeMount() {
    this.$store.dispatch('visualizar/findByInvoice', this.$route.params.faturaId)
  },
  setup() {
    const faturasStore = createNamespacedHelpers('visualizar')
    const state = reactive({
      faturasStore: faturasStore.useGetters(['faturas']).faturas,
      faturasEncontradas: [],
    })

    watch(() => state.faturasStore, options => {
      options.forEach(option => state.faturasEncontradas.push({
        shippmentId: option.shippmentId,
        cep: option.cliente.cep,
        valor: option.valorFaturado,
        nomeDestinatario: option.nomeDestinatario,
        tipoServico: option.nomeServico,
        peso: option.peso,
      }))
    })

    const table = {
      tableRef: ref(null),
      currentPage: ref(1),
      perPage: ref(10),
      perPageOptions: [10, 25, 50, 100],
      search: ref(''),
      sortBy: ref('nome'),
      isSortDirDesc: ref(false),
      tableColumns: [
        { key: 'shippmentId', sortable: true, label: 'shippment Id' },
        { key: 'cep', sortable: true, label: 'CEP' },
        { key: 'valor', sortable: true, label: 'valor' },
        { key: 'nomeDestinatario', sortable: true, label: 'nome destinatario' },
        { key: 'tipoServico', sortable: true, label: 'tipo servico' },
        { key: 'peso', sortable: true, label: 'Peso' },
      ],
    }

    return { ...toRefs(state), ...table, faturasStore, ...utils }
  },

}

</script>