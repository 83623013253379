import ImprimirEtiquetaService from '@/service/ImprimirEtiquetaService'

export default {
  namespaced: true,
  state: {
    dados: {
      lista: [],
    },
    movimentacaoIds: [],
    movimentacaoIdsSelecionados: [],
    detalhes: {},
  },

  getters: {
    dados(state) {
      return state.dados
    },
    movimentacaoIds(state) {
      return state.movimentacaoIds
    },
    detalhes(state) {
      return state.detalhes
    },
  },
  mutations: {
    SET_DADOS(state, dados) {
      state.dados = {
        ...dados,
      }
    },
    SET_DADOS_PAGINACAO(state, dados) {
      const { movimentacaoIdsSelecionados, movimentacaoIds, totalRows } = state.dados
      state.dados = {
        lista: dados.lista,
        movimentacaoIdsSelecionados,
        movimentacaoIds,
        totalRows,
      }
    },
    RESET_DADOS(state) {
      state.dados = {
        lista: [],
      }
      state.detalhes = {}
    },
    SET_DETALHES(state, det) {
      state.detalhes = det
    },
    RESET_MOVIMENTACAO_IDS(state) {
      state.movimentacaoIds = []
    },
    SET_MOVIMENTACAO_IDS(state, movimentacaoIds) {
      state.movimentacaoIds = movimentacaoIds
    },
    SELECT_ALL_ETIQUETAS(state, bool) {
      state.dados.movimentacaoIdsSelecionados.forEach(mov => {
        mov.selecionado = bool
      })
    },
  },
  actions: {
    async findAll({ commit }, obj) {
      if (obj.tipoPesquisa === 'NOVA_PESQUISA') {
        commit('RESET_DADOS')
        commit('RESET_MOVIMENTACAO_IDS')
      }
      const dados = await ImprimirEtiquetaService.findByDateAndSituation(obj)
      if (obj.tipoPesquisa === 'NOVA_PESQUISA' || obj.tipoPesquisa === 'ORDENACAO') {
        commit('SET_MOVIMENTACAO_IDS', dados.movimentacaoIds)
      }
      commit('SET_DADOS', dados)
    },
    async findAllByFilter({ commit }, filter) {
      if (filter.tipoPesquisa === 'NOVA_PESQUISA') {
        commit('RESET_DADOS')
        commit('RESET_MOVIMENTACAO_IDS')
      }
      const dados = await ImprimirEtiquetaService.findByFilter(filter)
      if (filter.tipoPesquisa === 'PAGINACAO') {
        commit('SET_DADOS_PAGINACAO', dados)
      } else {
        commit('SET_DADOS', dados)
      }
    },
    async findDetalhesByMovimentacaoId({ commit }, movId) {
      const dados = await ImprimirEtiquetaService.findDetalhesByMovimentacaoId(movId)
      commit('SET_DETALHES', dados)
    },
    async findByChave(_, { clienteId, chave }) {
      const etiqueta = await ImprimirEtiquetaService.findByChave(clienteId, chave)
      return etiqueta
    },
    async exportar(_, obj) {
      const dados = await ImprimirEtiquetaService.exportar(obj)
      return dados
    },
    selectAll({ commit }, bool) {
      commit('SELECT_ALL_ETIQUETAS', bool)
    },
    resetData({ commit }) {
      commit('RESET_DADOS')
    },
  },
}