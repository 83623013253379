import FaturasService from '@/service/FaturasService'

export default {
  namespaced: true,
  state: {
    faturas: [],
  },
  
  getters: {
    faturas(state) {
      return state.faturas
    },
  },
  mutations: {
    SET_FATURAS(state, faturas) {
      state.faturas = faturas
    },
  },
  actions: {
    async findAll({ commit }, filtros) {
      const faturas = await FaturasService.findByFilter(filtros)
      commit('SET_FATURAS', faturas)
    },

    async findById({ commit }, faturaId) {
      const fatura = await FaturasService.findById(faturaId)
      commit('SET_FATURAS', fatura)
    },
  },
}
