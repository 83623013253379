<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
      >
        <b-img
          fluid
          :src="imgLogo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Validar seu e-mail
          </b-card-title>
          <b-card-text class="mb-2">
            Verifique se o endereço de e-mail está correto e clique em "Validar"
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="firebaseUser.email"
                    disabled
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                @click="validar()"
              >
                Validar
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { ref } from '@vue/composition-api'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data() {
    return {
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      appLogo: $themeConfig.app.appLogoImageRed,
      appLoading: $themeConfig.app.appLoadingImage,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.appLogo = $themeConfig.app.appLogoImageWhite
        return this.appLogo
      }
      return this.appLogo
    },
  },
  created() {
    this.appLoading.hide()
  },
  methods: {
    validar() {
      this.appLoading.show()
      firebase.auth().currentUser.sendEmailVerification()
        .then(() => {
          this.appLoading.hide()
          this.success()
        })
        .catch(() => {
          this.appLoading.hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao enviar o Email, tente novamente em alguns instantes',
              icon: 'MailIcon',
              variant: 'warning',
            },
          })
        })
      firebase.auth().signOut()
        .then(() => {
          this.$router.push('/auth/login')
        })
    },
    success() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Não esqueça de verificar sua caixa de spam!',
          icon: 'MailIcon',
          variant: 'warning',
        },
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Email Enviado!',
          icon: 'MailIcon',
          variant: 'success',
        },
      })
    },
  },
  setup() {
    const firebaseUser = ref({ email: '' })
    firebase.auth().onAuthStateChanged(user => {
      firebaseUser.value = user
    })
    return { firebaseUser }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
