import httpClient from './http-clients/portaljad'

const baseUrl = 'pesquisa-envios'

export default class PesquisaService {
  static async pesquisa(filtros) {
    try {
      const { data } = await httpClient.post(baseUrl, filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAllBusca(filtros) {
    try {
      const { data } = await httpClient.post([baseUrl, 'cliente'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findDetalhesByMovimentacaoId(movId) {
    try {
      const { data } = await httpClient.get([baseUrl, 'detalhes', movId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async pesquisaExportar(filtros) {
    try {
      const { data } = await httpClient.post([baseUrl, 'cliente-exportar'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async pesquisaOverview(filtros) {
    try {
      const { data } = await httpClient.post([baseUrl, 'overview'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async pesquisaRelatorio(filtros) {
    try {
      const { data } = await httpClient.post([baseUrl, 'relatorio'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async raioXServicosFindServicos(filtros) {
    try {
      const { data } = await httpClient.post([baseUrl, 'raio-x-servicos-find-servicos'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async raioXServicos(filtros) {
    try {
      const { data } = await httpClient.post([baseUrl, 'raio-x-servicos'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async changeStatus(row) {
    try {
      const { data } = await httpClient.put([baseUrl, row.item.movimentoEnvioId].join('/'), row.item.statusTratativa)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}