<template>
  <b-card-text
    v-if="data"
  >
    <b-row>
      <b-col
        cols="12"
        lg="4"
      >
        <b-card-text>
          <h4 class="my-2">
            Destinatário
          </h4>
          <hr class="my-0" >
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Nome:</span>
              <span>{{ data.nome }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold"> Documento: </span>
              <span>
                {{ data.numeroDocumento }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">CEP:</span>
              <span>{{ data.cep ? maskCep(`${data.cep}`) : '' }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">UF:</span>
              <span>{{ data.uf }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Cidade:</span>
              <span>{{ data.cidade }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold"> Bairro: </span>
              <span>
                {{ data.bairro }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold"> Logradouro: </span>
              <span>
                {{ data.endereco }}
              </span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        style="border-left: 1px solid #ebe9f1"
      >
        <b-card-text>
          <h4 class="my-2">
            Informações do Pedido
          </h4>
          <hr class="my-0" >
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold"> Peso(kg): </span>
              <span>
                {{ data.pesoTotalAferido ? Number(Number(data.pesoTotalAferido)/1000).toFixed(2) : (data.pesoTotalTaxado ? Number(Number(data.pesoTotalTaxado)/1000).toFixed(2) : 0) }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold"> Quantidade: </span>
              <span>
                {{ data.quantidade }}
              </span>
            </b-row>
            <template>
              <b-row class="justify-content-between pb-1">
                <span class="font-weight-bold"> Situação: </span>
                <span>
                  {{ data.situacaoTracking }}
                </span>
              </b-row>
              <b-row class="justify-content-between pb-1">
                <span class="font-weight-bold"> Eventos: </span>
                <span>
                  {{ data.ultimoEvento }}
                </span>
              </b-row>
              <b-row class="justify-content-between pb-1">
                <span class="font-weight-bold"> Data/Hora ultimo eventos: </span>
                <span>
                  {{ data.dataUltimoEvento }}
                </span>
              </b-row>
            </template>
          </div>
        </b-card-text>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        style="border-left: 1px solid #ebe9f1"
      >
        <b-card-text>
          <h4 class="my-2">
            Serviço
          </h4>
          <hr class="my-0" >
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold"> Nome: </span>
              <span>
                {{ serviceDictionary(data.nomeServico) }}
              </span>
            </b-row>
            <b-row 
              v-if="data.numeroNotaFiscal"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold"> Nota Fiscal: </span>
              <span>
                {{ data.numeroNotaFiscal }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span
                class="font-weight-bold"
                style="font-weight-bold"
              >
                DADOS FISCAIS
              </span>
            </b-row>
            <b-row 
              v-if="data.valorNotaFiscal"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">Valor Nota Fiscal: </span>
              <span>
                {{ data.valorNotaFiscal ? maskCurrency(data.valorNotaFiscal) : maskCurrency(0) }}
              </span>
            </b-row>
            <b-row 
              v-if="data.valorDeclaracaoConteudo"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">Valor Declaração: </span>
              <span>
                {{ data.valorDeclaracaoConteudo ? maskCurrency(data.valorDeclaracaoConteudo) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold"> Valor Para Seguro: </span>
              <span>
                {{ data.valorParaSeguro ? maskCurrency(data.valorParaSeguro) : '' }}
              </span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>
    </b-row>
  </b-card-text>
</template>

<script>
import utils from '@/helpers/utils'

export default {
  name: 'ModalVue',
  props: {
    data: {
      type: undefined,
      required: true,
    },
  },
  data() {
    return {
      ...utils,
    }
  },
}
</script>

<style scoped></style>
