import ClienteLayoutParametroService from '@/service/ClienteLayoutParametroService'

export default {
  namespaced: true,
  state: {
    layoutParametros: [],
  },
  getters: {
    layoutParametros(state) {
      return state.layoutParametros
    },
  },
  mutations: {
    UPDATE_LAYOUTS(state, layoutParametros) {
      state.layoutParametros = layoutParametros
    },
  },
  actions: {
    async findAllByLayoutId({ commit }, layoutId) {
      const layoutParametros = await ClienteLayoutParametroService.findAllByLayoutId(layoutId)
      commit('UPDATE_LAYOUTS', layoutParametros)
    },
  },
}