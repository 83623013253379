import axios from 'axios'
import firebase from 'firebase/app'

const http = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Content-Type': 'application/json',
  },
})

http.interceptors.request.use(async request => {
  const req = request
  if (req.skipFirebaseAuth) {
    const token = '!@#$%(trackExternoTOKEN).123*&%$#@!'
    req.headers.Authorization = `Bearer ${token}`
    return req
  }

  if (firebase.auth().currentUser) {
    const token = await firebase.auth().currentUser.getIdToken()
    localStorage.setItem('userToken', token)
    req.headers.Authorization = `Bearer ${token}`
  } else if (localStorage.getItem('userToken')) {
    const token = localStorage.getItem('userToken')
    req.headers.Authorization = `Bearer ${token}`
  }
  return req
})

http.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 403) {
      localStorage.setItem('msg-erro', 'Sua sessão expirou. Faça novamente o login!')
      firebase.auth().signOut()
    }
  },
)

export default http
