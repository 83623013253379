<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
      >
        <b-img
          fluid
          :src="imgLogo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bem vindo(a) ao Portal Envios!
          </b-card-title>
          <b-card-text class="mb-2">
            Faça o seu login abaixo.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="validaCampos"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="email"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="seuemail@dominio.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label>Password</label>
                  <b-link
                    :to="{name:'forgot-password'}"
                  >
                    <small>Esqueceu a sua senha?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="login('CLIENTE')"
              >
                Entrar
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import store from '@/store'
import firebase from 'firebase/app'
import UsuariosService from '@/service/UsuarioService'
import utils from '@/helpers/utils'
import ClienteService from '@/service/ClienteService'

export default {
  mixins: [togglePasswordVisibility],
  data() {
    const msgErro = localStorage.getItem('msg-erro')
    if (msgErro) {
      this.$swal({
        icon: 'info',
        text: msgErro,
        showConfirmButton: false,
        timer: 4000,
      })
      localStorage.removeItem('msg-erro')
    }
    return {
      email: null,
      password: null,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      appLogo: $themeConfig.app.appLogoImageRed,
      appLoading: $themeConfig.app.appLoadingImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.appLogo = $themeConfig.app.appLogoImageWhite
        return this.appLogo
      }
      return this.appLogo
    },
  },
  created() {
    const { email, senha } = this.$router.currentRoute.params
    if (email && senha) {
      const senhaDecrypt = utils.decrypt(senha.replaceAll('-', '/'), email)
      this.email = email
      this.password = senhaDecrypt.substring(1, senhaDecrypt.length - 1)
      this.login('UNIDADE')
    }
  },
  methods: {
    async validaCampos() {
      await this.$refs.loginValidation.validate()
    },
    login(local) {
      if (this.email && this.password) {
        this.appLoading.show()
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        firebase.auth().signInWithEmailAndPassword(this.email, this.password)
          .then(userCredential => {
            if (userCredential.user.emailVerified) {
              userCredential.user.getIdToken()
                .then(token => {
                  UsuariosService.findByUid(userCredential.user.uid, utils.encrypt(this.password, this.email))
                    .then(dadosUsuario => {
                      if (dadosUsuario.clienteId && dadosUsuario.ativo) {
                        ClienteService.findById(dadosUsuario.clienteId)
                          .then(cliente => {
                            const dados = {
                              ...dadosUsuario,
                              urlLogo: cliente.urlLogo,
                              unidade: cliente.nomeEmpresa,
                              nomeFantasia: cliente.nomeFantasia,
                              nome: dadosUsuario.nome ? dadosUsuario.nome : dadosUsuario.email,
                              clienteDocumento: cliente.numeroDocumento,
                            }
                            const { clienteId, usuarioId } = dados
                            const saveLogLogin = {
                              usuarioId,
                              clienteId,
                              localEntrada: local,
                            }
                            window.localStorage.setItem('userToken', token)
                            window.localStorage.setItem('userName', JSON.stringify(dados))
                            this.$store.dispatch('currentUser/setCurrentUser', dados)
                              .then(() => {
                                this.$store.dispatch('movimentacaoErro/findAllByClienteId', dadosUsuario.clienteId)
                                  .then(() => {
                                    store.dispatch('logs/saveLogLoginCliente', saveLogLogin)
                                    this.success()
                                  })
                              })
                          })
                      } else {
                        firebase.auth().signOut()
                        const msg = dadosUsuario.ativo ? null : 'Falha ao Efetuar o Login! Este cliente está como Inativo no cadastro da Unidade!'
                        this.error(msg)
                      }
                    })
                })
            }
          })
          .catch(() => { this.error() })
      }
    },
    success() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Login bem sucedido!',
          icon: 'EditIcon',
          variant: 'success',
        },
      }, {
        timeout: 2000,
        draggable: true,
        hideProgressBar: false,
      })
      this.$router.push('/')
        .finally(() => { this.appLoading.hide() })
    },
    error(msg) {
      this.appLoading.hide()
      this.$swal({
        icon: 'error',
        title: 'Algo deu errado',
        text: msg || 'Verifique se email e senha estão corretos',
        showConfirmButton: false,
        timer: 3000,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
