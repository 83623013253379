import { $themeConfig } from '@themeConfig'
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'

export default () => {
  const { appCarregando } = $themeConfig.app
  const downloadSpreadsheet = (dados, arquivo = 'Dados') => {
    const ws = XLSX.utils.json_to_sheet(dados)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
  
    const fileName = `${arquivo}.xlsx`
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    
    FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName)
    appCarregando.hide()
  }

  const downloadSpreadsheetWithHeader = (nomeRelatorio, heading, dataInicio, dataFim, dados, arquivo = 'Dados') => {
    const dataHeaders = [[nomeRelatorio]]
    const dataHeaders2 = [[`${dataInicio.split('-')[2]}/${dataInicio.split('-')[1]}/${dataInicio.split('-')[0]} a ${dataFim.split('-')[2]}/${dataFim.split('-')[1]}/${dataFim.split('-')[0]}`]]
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, dataHeaders)
    XLSX.utils.sheet_add_json(ws, dataHeaders2, { origin: 'A2', skipHeader: true })
    XLSX.utils.sheet_add_json(ws, heading, { origin: 'A3', skipHeader: true })
    XLSX.utils.sheet_add_json(ws, dados, { origin: 'A4', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    /* generate an XLSX file */
    const fileName = `${arquivo}.xlsx`
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    /* save the file */
    FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName)
    appCarregando.hide()
  }

  return {
    downloadSpreadsheet,
    downloadSpreadsheetWithHeader,
  }
}