import ServicoService from '@/service/ServicoService'

export default {
  namespaced: true,
  state: {
    servicos: null,
    servicosOptionsDefault: [],
    servicosOptions: [],
  },
  getters: {
    servicos(state) {
      return state.servicos
    },
    servicosOptionsDefault(state) {
      return state.servicosOptionsDefault
    },
    servicosOptions(state) {
      return state.servicosOptions
    },
  },
  mutations: {
    UPDATE_SERVICOS(state, servicos) {
      state.servicos = servicos
    },
    UPDATE_SERVICOS_OPTIONS_DEFAULT(state, servicosOptionsDefault) {
      state.servicosOptionsDefault = servicosOptionsDefault
    },
    UPDATE_SERVICOS_OPTIONS(state, servicosOptions) {
      state.servicosOptions = servicosOptions
    },
  },
  actions: {
    async findAll({ commit }, clienteId) {
      const servicos = await ServicoService.findAll(clienteId)
      commit('UPDATE_SERVICOS', servicos)
      return servicos
    },
    setOptionsDefault({ commit }, servicos) {
      const options = servicos.map(servico => ({
        label: servico.nomeServico,
        value: servico.id,
      }))
      commit('UPDATE_SERVICOS_OPTIONS_DEFAULT', options)
    },
    setOptions({ commit }, servicos) {
      const options = [{
        label: 'Definido no arquivo',
        dados: { servicoId: 999999 },
      }].concat(servicos.map(servico => ({
        label: servico.nomeServico,
        dados: { servicoId: servico.id },
      })))
      commit('UPDATE_SERVICOS_OPTIONS', options)
    },
  },
}