import httpClient from './http-clients/portaljad'

const baseUrl = 'loja'

export default class LojaService {
  static async findAll(clienteId) {
    try {
      const { data } = await httpClient.get(`${baseUrl}/all/${clienteId}`)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async saveAuth(integrateAuth) {
    try {
      const { data } = await httpClient.post([baseUrl, 'auth'].join('/'), integrateAuth)
      return data
    } catch (error) {
      throw error.response.data()
    }
  }

  static async findById(lojaId) {
    try {
      const { data } = await httpClient.get(`${baseUrl}/${lojaId}`)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
  
  static async save(loja) {
    try {
      const { data } = await httpClient.post(baseUrl, loja)
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async edit(lojaId, loja) {
    try {
      const { data } = await httpClient.put(`${baseUrl}/${lojaId}`, loja)
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async delete(lojaId) {
    try {
      const { data } = await httpClient.delete(`${baseUrl}/${lojaId}`)
      return data
    } catch (error) {
      throw error.response.data
    }
  }
}