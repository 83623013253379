<template>
  <b-card 
    no-body 
    class="mb-2 p-2"
  >
    <b-link :to="{ name: 'geracao-etiquetas-integracao-vendas', params: { lojaId: loja.lojaId } }">
      <b-row
        class="align-items-center mb-2"
        style="height: 60px;"
      >
        <b-col cols="4">
          <img
            :src="urlLogoIntegracao(loja.integracaoId)"
            style="width: 90px;"
          >
        </b-col>
        <b-col 
          cols="8"
          style="text-align: right;"
        >
          <span 
            class="card-text-title d-inline-block"
            style="max-height: 60px; text-align: left; overflow: hidden;"
            :title="loja.nome"
          >
            {{ loja.nome }}
          </span>
        </b-col>
      </b-row>
    </b-link>
    <template v-if="mostrarDetalhes">
      <b-row
        class="align-items-center py-2 bg-light"
      >
        <b-col 
          cols="12"
        >
          <span>
            <b>TOKEN: </b>{{ loja.token }}
          </span>
        </b-col>
      </b-row>
      <b-row
        class="align-items-center"
      >
        <b-col 
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button 
            class="text-danger"
            variant="link"
            @click="confirmaExclusao(loja.lojaId)"
          >
            <feather-icon
              icon="TrashIcon"
              size="18"
            />
          </b-button>
        </b-col>
      </b-row>
    </template>
    <b-row
      v-if="mostrarDetalhes"
      class="mt-2"
      @click="switchDetalhes()"
    >
      <b-col cols="10">
        <div class="mais-detalhes">
          Minimizar detalhes
        </div>
      </b-col>
      <b-col cols="2">
        <feather-icon
          class="plus-icon"
          icon="MinusIcon"
          size="15"
        />
      </b-col>
    </b-row>
    <b-row
      v-else
      class="mt-2"
      @click="switchDetalhes()"
    >
      <b-col cols="10">
        <div class="mais-detalhes">
          Mais detalhes
        </div>
      </b-col>
      <b-col cols="2">
        <feather-icon
          class="plus-icon"
          icon="PlusIcon"
          size="15"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  name: 'LojaCard',
  props: {
    loja: {
      type: Object,
      required: true,
    },
    urlLogoIntegracao: {
      type: Function,
      required: true,
    },
    confirmaExclusao: {
      type: Function,
      required: true,
    },
  },
  
  setup() {
    const mostrarDetalhes = ref(false)
    const switchDetalhes = () => {
      mostrarDetalhes.value = !mostrarDetalhes.value
    }
    
    return {
      mostrarDetalhes,
      switchDetalhes,
    }
  },
}
</script>

<style>

</style>