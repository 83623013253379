import httpClient from './http-clients/portaljad'

const baseUrl = 'layout'

export default class LayoutService {
  static async findAll(clienteId) {
    try {
      const { data } = await httpClient.get([baseUrl, 'cliente', clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAllReversa(clienteId) {
    try {
      const { data } = await httpClient.get([baseUrl, 'reversa', 'cliente', clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(layoutId) {
    try {
      const { data } = await httpClient.get([baseUrl, layoutId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async save(multipart) {
    try {
      const { data } = await httpClient.post(baseUrl, multipart, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async deleteById(layoutId) {
    try {
      await httpClient.delete([baseUrl, layoutId].join('/'))
    } catch (error) {
      throw error.response.data
    }
  }
}