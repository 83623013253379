<template>
  <b-card>
    <validation-observer
      ref="refFormObserver"
    >
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="NomeIdentificador"
              rules="required"
            >
              <b-form-group
                label="Nome Identificador"
                label-for="nomeIdentificador"
              >
                <b-form-input
                  id="nomeIdentificador"
                  v-model="enderecoReversa.nomeIdentificador"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Editar nome identificador"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="NomeFantasia"
              rules="required"
            >
              <b-form-group
                label="Nome Destinatário"
                label-for="nomeFantasia"
              >
                <b-form-input
                  id="nomeFantasia"
                  v-model="enderecoReversa.nomeFantasia"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Editar Nome Destinatario"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              v-slot="validationContext"
              name="tipoDocumento"
              rules="required"
            >
              <b-form-group
                label="Tipo de Documento *"
                label-for="tipoDocumento"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="enderecoReversa.tipoDocumento"
                  :reduce="val=>val.value"
                  :options="tipoDocumentoOptions"
                  :clearable="false"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              v-slot="validationContext"
              name="numeroDocumento"
              rules="required"
            >
              <b-form-group
                label="Número do Documento *"
                label-for="numeroDocumento"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="numeroDocumento"
                  :key="cleaveKey"
                  v-model="enderecoReversa.numeroDocumento"
                  v-cleave="enderecoReversa.tipoDocumento ? cleaveOptions[enderecoReversa.tipoDocumento.toLowerCase()] : 
                    cleaveOptions.cpf"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="000.000.000-00 ou 00.000.000/0000-00"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="enderecoReversa.email"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Editar email"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="telefone"
            >
              <b-form-group
                label="Telefone"
                label-for="telefone"
              >
                <b-form-input
                  id="telefone"
                  v-model="enderecoReversa.telefone"
                  v-cleave="cleaveOptions.phone"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Editar telefone"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="cep"
              rules="required|min:8|max:8"
            >
              <b-form-group
                label="Cep"
                label-for="cep"
              >
                <b-form-input
                  id="cep"
                  v-model="enderecoReversa.cep"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Editar cep"
                  @blur="buscaEndereco"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="uf"
              rules="required"
            >
              <b-form-group
                label="UF"
                label-for="uf"
              >
                <b-form-input
                  id="uf"
                  v-model="enderecoReversa.uf"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Editar uf"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="cidade"
              rules="required"
            >
              <b-form-group
                label="Cidade"
                label-for="cidade"
              >
                <b-form-input
                  id="cidade"
                  v-model="enderecoReversa.cidade"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Editar cidade"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="bairro"
              rules="required"
            >
              <b-form-group
                label="Bairro"
                label-for="bairro"
              >
                <b-form-input
                  id="bairro"
                  v-model="enderecoReversa.bairro"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Editar bairro"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="logradouro"
              rules="required"
            >
              <b-form-group
                label="Logradouro"
                label-for="logradouro"
              >
                <b-form-input
                  id="logradouro"
                  v-model="enderecoReversa.logradouro"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Editar langradouro"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="numero"
              rules="required"
            >
              <b-form-group
                label="Número"
                label-for="numero"
              >
                <b-form-input
                  id="numero"
                  v-model="enderecoReversa.numero"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Editar numero"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="complemento"
            >
              <b-form-group
                label="Complemento"
                label-for="complemento"
              >
                <b-form-input
                  id="complemento"
                  v-model="enderecoReversa.complemento"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Editar complemento"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-1 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="salvarEdicao(enderecoReversa)"
    >
      Salvar Mudanças
      <feather-icon
        icon="SaveIcon"
        class="ml-1"
      />
    </b-button>
    <b-button

      variant="outline-secondary"
      class="mr-sm-1 mr-0 mb-1 mb-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$router.go(-1)"
    >
      Cancelar Alterações
      <feather-icon
        icon="RefreshCwIcon"
        class="ml-1"
      />
    </b-button>
    <b-button
      class="mr-sm-1 mr-0 mb-1 mb-sm-1"
      :variant="enderecoReversa.ativo ? 'danger' : 'primary'"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="changeStatus()"
    >
      {{ enderecoReversa.ativo ? 'Inativar' : 'Ativar' }}
      <feather-icon
        :icon="enderecoReversa.ativo ? 'TrashIcon' : 'SaveIcon'"
        class="ml-1"
      />
    </b-button>
  </b-card>
</template>
<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import cleaveOptions from '@/libs/cleave/options'
import { reactive, toRefs, watch, ref } from '@vue/composition-api'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import Utils from '@/helpers/utils'
import { $themeConfig } from '@themeConfig'
import swal from 'sweetalert2/dist/sweetalert2.min'
import CepService from '@/service/CepService'
import store from '@/store'

export default {
  name: 'EditarEnderecoReversa',
  data() {
    return {
      enderecoReversaId: this.$router.currentRoute.params.enderecoReversaId,
      tipoDocumentoOptions: [
        { label: 'CPF', value: 'CPF' },
        { label: 'CNPJ', value: 'CNPJ' },
      ],
      appCarregando: $themeConfig.app.appCarregando,
    }
  },
  beforeMount() {
    this.appCarregando.show()
    this.$store.dispatch('enderecoReversa/findById', this.enderecoReversaId).then(this.appCarregando.hide())
  },
  methods: {
    changeStatus() {
      swal.fire({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Você tem certeza que quer inativar essa endereço?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('enderecoReversa/changeStatus', this.enderecoReversaId).then(() => {
            this.$router.go(-1)
          })
        }
      })
    },
    salvarEdicao(data) {
      this.appCarregando.show()
      swal.fire({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Gostaria de salvar as alterações?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        buttonsStyling: false,
      }).then(result => {
        this.appCarregando.show()
        if (result.value) {
          this.appCarregando.hide()
          const dataEditada = {
            ...data,
            numeroDocumento: Utils.removeSpecialCharacters(data.numeroDocumento),
            telefone: Utils.removeSpecialCharacters(data.telefone),
            cep: Utils.removeSpecialCharacters(data.cep),
          }
          store.dispatch('enderecoReversa/edit', dataEditada).then(response => {
            this.appCarregando.hide()
            if (response) {
              if (response.success) {
                swal.fire({
                  icon: 'success',
                  html: 'Alterações salvas',
                  showConfirmButton: false,
                  timer: 3000,
                }).then(() => {
                  this.$router.go(-1)
                })
              } else {
                swal.fire({
                  icon: 'warning',
                  text: 'Erro ao salvar as alterações',
                  showConfirmButton: false,
                  timer: 3000,
                })
              }
            } else {
              swal.fire({
                icon: 'error',
                text: 'Erro ao salvar as alterações',
                showConfirmButton: false,
                timer: 3000,
              })
            }
            this.appCarregando.hide()
          })
        }
      })
    },
  },
  setup() {
    const enderecoReversaStore = createNamespacedHelpers('enderecoReversa')
    const { appCarregando } = $themeConfig.app
    const cleaveKey = ref(0)

    appCarregando.hide()

    const state = reactive({
      enderecoReversa: enderecoReversaStore.useGetters(['getSelected']).getSelected,
    })

    function incrementCleaveKey() {
      cleaveKey.value += 1
    }

    watch(() => state.enderecoReversa.tipoDocumento, () => {
      incrementCleaveKey()
    })

    const buscaEndereco = event => {
      CepService.buscarEndereco(event.target.value).then(endereco => {
        if (endereco) {
          if (endereco.success) {
            const dadosEndereco = JSON.parse(endereco.body)
            state.enderecoReversa.uf = dadosEndereco.uf
            state.enderecoReversa.cidade = dadosEndereco.cidade
          } else {
            swal.fire({
              icon: 'info',
              text: 'Cep informado Não existe, gostaria de usar assim mesmo? Isso poderá acarretar erros no futuro',
              showConfirmButton: true,
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary ml-1',
              },
            }).then(result => {
              if (!result.isConfirmed) {
                state.enderecoReversa.cep = ''
              }
            })
          } 
        }
      })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation()

    return {
      ...toRefs(state),
      cleaveOptions,
      cleaveKey,
      refFormObserver,
      getValidationState,
      resetForm,
      buscaEndereco,
    }
  },
}
</script>