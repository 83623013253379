import CotacaoService from '@/service/CotacaoService'
import adapter from '@/adapters/ClienteAdapter'

export default {
  namespaced: true,
  state: {
    cotacao: null,
    servicoEscolhido: null,
  },

  getters: {
    cotacao(state) {
      return state.cotacao
    },
    servicoEscolhido(state) {
      return state.servicoEscolhido
    },
  },

  mutations: {
    POST_COTACAO(state, cotacao) {
      state.cotacao = cotacao
    },
    SAVE_COTACAO(state, servicoEscolhido) {
      state.servicoEscolhido = servicoEscolhido
    },
  },

  actions: {
    async cotarFrete({ commit }, cotacaoObj) {
      const cotacao = await CotacaoService.cotarFrete(cotacaoObj)
      if (cotacao) {
        commit('POST_COTACAO', adapter.toStore(cotacao.data))
      }
      return cotacao
    },
    async escolhaServico({ commit }, Obj) {
      commit('SAVE_COTACAO', Obj)
    },
  },
}