export default class DestinatarioAdapter {
  static toApi(destinatario) {
    return {
      ...destinatario,
      numeroDocumento: destinatario.numeroDocumento ? destinatario.numeroDocumento.replaceAll(/[.,()/\s-]+/g, '') : null,
      celular: destinatario.celular ? destinatario.celular.replaceAll(/[.,()/\s-]+/g, '') : null,
      cep: destinatario.cep ? destinatario.cep.replaceAll(/[.,()/\s-]+/g, '') : null,
      ativo: true,
    }
  }

  static toStore(destinatario) {
    return {
      ...destinatario,
      clienteDepartamentoId: null,
      salvar: false,
    }
  }
}