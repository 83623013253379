import IntegracaoService from '@/service/IntegracaoService'

export default {
  namespaced: true,
  state: {
    integracoes: [],
    integracoesOptions: [],
    volumesV3: [],
  },
  getters: {
    integracoes(state) {
      return state.integracoes
    },
    integracoesOptions(state) {
      return state.integracoesOptions
    },
    volumesV3(state) {
      return state.volumesV3
    },
  },
  mutations: {
    UPDATE_INTEGRACOES(state, integracoes) {
      state.integracoes = integracoes
    },
    UPDATE_INTEGRACOES_OPTIONS(state, integracoesOptions) {
      state.integracoesOptions = integracoesOptions
    },
    UPDATE_VOLUMES_V3(state, volumes) {
      state.volumesV3 = volumes
    },
  },
  actions: {
    async findAll({ commit }) {
      const integracoes = await IntegracaoService.findAll()
      commit('UPDATE_INTEGRACOES', integracoes)
      const integracoesOptions = integracoes.map(i => ({
        value: i.integracaoId,
        label: i.nome,
      }))
      commit('UPDATE_INTEGRACOES_OPTIONS', integracoesOptions)
      return integracoes
    },
  },
}