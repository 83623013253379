import visualizarService from '@/service/visualizarService'

export default {
  namespaced: true,
  state: {
    faturas: [],
  },
  
  getters: {
    faturas(state) {
      return state.faturas
    },
  },
  mutations: {
    SET_FATURAS(state, faturas) {
      state.faturas = faturas
    },
  },
  actions: {
    async findByInvoice({ commit }, faturaId) {
      const fatura = await visualizarService.findByInvoice(faturaId)
      commit('SET_FATURAS', fatura)
    },
  },
}