<template>
  <b-card-text>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-card-text>
          <h4 class="my-2">
            Destinatário
          </h4>
          <hr class="my-0">
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Nome:</span>
              <span>{{ data.nomeDestinatario }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Tipo de Documento
              </span>
              <span>
                {{ data.destinatarioTipoDocumento }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Documento:
              </span>
              <span>
                {{ formataDocumento(data) }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Email
              </span>
              <span>
                {{ data.email }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Telefone:
              </span>
              <span>
                {{ data.celular ? maskPhone(data.celular) : '' }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">CEP:</span>
              <span>{{ data.cep }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">UF:</span>
              <span>{{ data.uf }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Cidade:</span>
              <span>{{ data.cidade }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Bairro:
              </span>
              <span>
                {{ data.bairro }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Logradouro:
              </span>
              <span>
                {{ data.endereco }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Número:
              </span>
              <span>
                {{ data.numero }} 
              </span>
            </b-row>
            <b-row
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">
                Complemento:
              </span>
              <span>
                {{ data.complemento }} 
              </span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>
      <b-col
        cols="12"
        md="4"
        style="border-left: 1px solid #EBE9F1"
      >
        <b-card-text>
          <h4 class="my-2">
            Informações do Pedido
          </h4>
          <hr class="my-0" >
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold"> Peso(kg): </span>
              <span>
                {{ data.pesoTotalAferido ? Number(Number(data.pesoTotalAferido)/1000).toFixed(2) : (data.pesoTotalTaxado ? Number(Number(data.pesoTotalTaxado)/1000).toFixed(2) : 0) }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold"> Quantidade: </span>
              <span>
                {{ data.quantidade }}
              </span>
            </b-row>
            <template>
              <b-row class="justify-content-between pb-1">
                <span class="font-weight-bold"> Medidas: </span>
                <span>
                  {{ data.medidas }}
                </span>
              </b-row>
            </template>
            <template>
              <b-row class="justify-content-between pb-1">
                <span class="font-weight-bold"> Situação: </span>
                <span>
                  {{ data.situacaoTracking }}
                </span>
              </b-row>
              <b-row class="justify-content-between pb-1">
                <span class="font-weight-bold"> Eventos: </span>
                <span>
                  {{ data.ultimoEvento }}
                </span>
              </b-row>
              <b-row class="justify-content-between pb-1">
                <span class="font-weight-bold"> Data - Hora ultimo eventos: </span>
                <span>
                  {{ data.dataUltimoEvento ? maskDateHour(data.dataUltimoEvento) : '' }}
                </span>
              </b-row>
            </template>
          </div>
        </b-card-text>
      </b-col>
      <b-col
        cols="12"
        md="4"
        style="border-left: 1px solid #EBE9F1"
      >
        <b-card-text>
          <h4 class="my-2">
            Serviço
          </h4>
          <hr class="my-0" >
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold"> Nome: </span>
              <span>
                {{ serviceDictionary(data.nomeServico) }}
              </span>
            </b-row>
            <b-row 
              v-if="data.numeroNotaFiscal"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold"> Nota Fiscal: </span>
              <span>
                {{ data.numeroNotaFiscal }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span
                class="font-weight-bold"
                style="font-weight-bold"
              >
                DADOS FISCAIS
              </span>
            </b-row>
            <b-row 
              v-if="data.valorNotaFiscal"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">Valor Nota Fiscal: </span>
              <span>
                {{ data.valorNotaFiscal ? maskCurrency(data.valorNotaFiscal) : '' }}
              </span>
            </b-row>
            <b-row 
              v-if="data.valorDeclaracaoConteudo"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">Valor Declaração: </span>
              <span>
                {{ data.valorDeclaracaoConteudo ? maskCurrency(data.valorDeclaracaoConteudo) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold"> Valor Para Seguro: </span>
              <span>
                {{ data.valorNotaFiscal ? maskCurrency(data.valorNotaFiscal) : maskCurrency(data.valorParaSeguro) }}
              </span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>
    </b-row>
    <b-row
      class="row-style"
    >
      <b-col
        cols="12"
      >
        <b-card-text>
          <h4 class="my-2">
            Tratativas
          </h4>
          <hr class="my-0">
          <b-table
            ref="tableRef"
            responsive
            class="position-relative"
            :items="data.tratativaEntregaDescricaoDTO"
            :fields="resultsTableColumns"
            show-empty
            stacked="md"
            empty-text="Nenhum resultado encontrado."
          >
            <template #cell(dataGeracao)="result">
              <div>
                {{ result.item.dataGeracao ? maskDateHour(result.item.dataGeracao) : '' }}
              </div>
            </template>
            <template #cell(acareacao)="result">
              <div>
                {{ result.item.acareacao ? 'Sim' : 'Não' }}
              </div>
            </template>
            <template #cell(status)="result">
              <div>
                {{ ajusteStatus(result.item.status) }}
              </div>
            </template>
          </b-table>
        </b-card-text>
      </b-col>
    </b-row>
  </b-card-text>
</template>

<script>
import Utils from '@/helpers/utils'

export default {
  name: 'ModalVue',
  props: {
    data: {
      type: undefined,
      required: true,
    },
  },
  data() {
    return {
      mostrarDepartamento: false,
    }
  },
  setup() {
    const resultsTableColumns = [
      { key: 'atendimentoId', sortable: true, label: 'Atendimento', tdClass: 'text-nowrap', thStyle: { whiteSpace: 'nowrap' } },
      { key: 'dataGeracao', sortable: true, label: 'Data Geração', thStyle: { minWidth: '100px' } },
      { key: 'descricao', sortable: true, label: 'Descrição', thStyle: { minWidth: '350px' } },
      { key: 'usuarioCriacaoEmail', sortable: true, label: 'Usuário Geração' },
      { key: 'acareacao', sortable: true, label: 'Acareação' },
      { key: 'status', sortable: true, label: 'Posição' },
    ]

    const formataDocumento = data => {
      if (data.destinatarioNumeroDocumento) {
        return data.destinatarioTipoDocumento === 'CPF' ? Utils.maskCpf(data.destinatarioNumeroDocumento) : Utils.maskCnpj(data.destinatarioNumeroDocumento)
      } return ''
    }

    const ajusteStatus = data => {
      const dictionary = {
        FINALIZADO: 'Finalizado',
        PENDENTE: 'Pendente',
        PENDENTE_CLIENTE: 'Pendente Cliente',
        PENDENTE_JADLOG: 'Pendente JadLog',
        DEFAULT: '',
      }
      return dictionary[data] ? dictionary[data] : dictionary.DEFAULT
    }

    return {
      ...Utils,
      ajusteStatus,
      resultsTableColumns,
      formataDocumento,
    }
  },
}
</script>

<style scoped>
 .row-style {
  border-top: 1px solid #EBE9F1;
  margin-top: 8px;
 }
</style>