import PesquisaService from '@/service/PesquisaService'

export default {
  namespaced: true,
  state: {
    dados: {
      lista: [],
    },
    movimentacaoIds: [],
    detalhes: {},
  },
  getters: {
    dados(state) {
      return state.dados
    },
    movimentacaoIds(state) {
      return state.movimentacaoIds
    },
    detalhes(state) {
      return state.detalhes
    },
  },
  mutations: {
    SET_DADOS(state, dados) {
      state.dados = {
        ...dados,
      }
    },
    SET_DETALHES(state, det) {
      state.detalhes = det
    },
    RESET_DADOS(state) {
      state.dados = {
        lista: [],
      }
    },
    RESET_MOVIMENTACAO_IDS(state) {
      state.movimentacaoIds = []
    },
    SET_MOVIMENTACAO_IDS(state, movimentacaoIds) {
      state.movimentacaoIds = movimentacaoIds
    },
  },
  actions: {
    async findAll({ commit }, obj) {
      if (obj.tipoPesquisa === 'NOVA_PESQUISA') {
        commit('RESET_DADOS')
        commit('RESET_MOVIMENTACAO_IDS')
      }
      const dados = await PesquisaService.pesquisa(obj)
      if (obj.tipoPesquisa === 'NOVA_PESQUISA' || obj.tipoPesquisa === 'ORDENACAO') {
        commit('SET_MOVIMENTACAO_IDS', dados.movimentacaoIds)
      }
      commit('SET_DADOS', dados)
    },
    async findAllBusca({ commit }, filtro) {
      if (filtro.tipoPesquisa === 'NOVA_PESQUISA') {
        commit('RESET_DADOS')
        commit('RESET_MOVIMENTACAO_IDS')
      }
      const dados = await PesquisaService.findAllBusca(filtro)
      if (filtro.tipoPesquisa === 'NOVA_PESQUISA' || filtro.tipoPesquisa === 'ORDENACAO') {
        commit('SET_MOVIMENTACAO_IDS', dados.movimentacaoIds)
      }
      commit('SET_DADOS', dados)
    },
    async findDetalhesByMovimentacaoId({ commit }, movId) {
      const dados = await PesquisaService.findDetalhesByMovimentacaoId(movId)
      commit('SET_DETALHES', dados)
    },
    async pesquisaExportar(_, obj) {
      const dados = await PesquisaService.pesquisaExportar(obj)
      return dados
    },
    async changeStatus(context, row) {
      await PesquisaService.changeStatus(row)
    },
  },
}