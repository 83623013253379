import httpClient from './http-clients/portaljad'

const baseUrl = 'movimentacao-erro'

export default class MovimentacaoErroService {
  static async findAllByClienteId(clienteId) {
    try {
      const { data } = await httpClient.get([baseUrl, 'cliente', clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async lerTodas(clienteId) {
    try {
      const { data } = await httpClient.put([baseUrl, 'cliente/lerTodas', clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}