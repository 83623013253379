import httpClient from './http-clients/portaljad'

const baseUrl = 'faturas-movimentacoes'

export default class FaturasService {
  static async findByInvoice(faturaId) {
    try {
      if (!faturaId) {
        return undefined
      }
      const { data } = await httpClient.get([baseUrl, faturaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}