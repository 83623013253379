<template>
  <b-card-text v-if="data">
    <b-row>

      <b-col
        cols="12"
        md="4"
      >
        <b-card-text>
          <h4 class="my-2">
            Destinatário
          </h4>
          <hr class="my-0">
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Nome:</span>
              <span>{{ data.nome }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Tipo de Documento:
              </span>
              <span>
                {{ data.tipoDocumento }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Documento:
              </span>
              <span>
                {{ data.tipoDocumento === 'CPF' ? 
                  (data.numeroDocumento.includes('.') ? data.numeroDocumento : maskCpf(data.numeroDocumento)) : 
                  (data.numeroDocumento.includes('.') ? data.numeroDocumento : maskCnpj(data.numeroDocumento)) }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Email
              </span>
              <span>
                {{ data.email }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Telefone:
              </span>
              <span>
                {{ data.celular ? (data.celular.includes('-') ? data.celular : maskPhone(data.celular)) : '' }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">CEP:</span>
              <span>{{ data.cep }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">UF:</span>
              <span>{{ data.uf }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Cidade:</span>
              <span>{{ data.cidade }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Bairro:
              </span>
              <span>
                {{ data.bairro }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Logradouro:
              </span>
              <span>
                {{ data.endereco }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Número:
              </span>
              <span>
                {{ data.numero }} 
              </span>
            </b-row>
            <b-row
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">
                Complemento:
              </span>
              <span>
                {{ data.complemento }} 
              </span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>

      <b-col
        cols="12"
        md="4"
        style="border-left: 1px solid #EBE9F1"
      >
        <b-card-text>
          <h4 class="my-2">
            Remetente
          </h4>
          <hr class="my-0">
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Tipo de Documento:
              </span>
              <span>
                {{ data.tipoDocumentoCliente }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Documento:
              </span>
              <span>
                {{ data.tipoDocumentoCliente === 'CPF' ? maskCpf(data.numeroDocumentoCliente) : maskCnpj(data.numeroDocumentoCliente) }} 
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Email:
              </span>
              <span>
                {{ data.emailCliente }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Telefone:
              </span>
              <span>
                {{ data.telefoneCliente ? maskPhone(data.telefoneCliente) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">CEP:</span>
              <span>{{ data.cepCliente ? maskCep(data.cepCliente) : '' }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">UF:</span>
              <span>{{ data.ufCliente }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Cidade:</span>
              <span>{{ data.cidadeCliente }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Bairro:
              </span>
              <span>
                {{ data.bairroCliente }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Logradouro:
              </span>
              <span>
                {{ data.logradouroCliente }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Número:
              </span>
              <span>
                {{ data.numeroCliente }}
              </span>
            </b-row>
            <b-row
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">
                Complemento:
              </span>
              <span>
                {{ data.complementoCliente }}
              </span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>
      <b-col
        cols="12"
        md="4"
        style="border-left: 1px solid #EBE9F1"
      >
        <b-card-text>
          <h4 class="my-2">
            Detalhes
          </h4>
          <hr class="my-0">
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Serviço:
              </span>
              <span>
                {{ serviceDictionary(data.nomeServico) }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Shippment ID:
              </span>
              <span>
                {{ data.shippmentId }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                JAD Code:
              </span>
              <span>
                {{ data.movimentacaoId }}
              </span>
            </b-row>
            <b-row
              v-if="data.valorNotaFiscal"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">
                Valor Nota Fiscal:
              </span>
              <span>
                {{ data.valorNotaFiscal ? maskCurrency(data.valorNotaFiscal) : maskCurrency(0) }}
              </span>
            </b-row>
            <b-row
              v-if="data.valorDeclaracaoConteudo"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">
                Valor Declaração:
              </span>
              <span>
                {{ data.valorDeclaracaoConteudo ? maskCurrency(data.valorDeclaracaoConteudo) : maskCurrency(0) }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Valor Seguro:
              </span>
              <span>
                {{ data.valorNotaFiscal ? maskCurrency(data.valorNotaFiscal) : maskCurrency(data.valorParaSeguro) }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Qtd. volumes:
              </span>
              <span>
                {{ data.quantidade }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Peso total:
              </span>
              <span>
                {{ data.peso ? ((data.peso * data.quantidade) / 1000).toFixed(2) : 0 }} kg
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Data geração:
              </span>
              <span>
                {{ data.dataGeracao ? createDate(data.dataGeracao) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Usuário geração:
              </span>
              <span>
                {{ data.nomeUsuarioGeracao ? data.nomeUsuarioGeracao : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Data Impressão:
              </span>
              <span>
                {{ data.dataImpressao ? createDate(data.dataImpressao) : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Usuário Impressão:
              </span>
              <span>
                {{ data.nomeUsuarioImpressao ? data.nomeUsuarioImpressao : '' }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Tipo de Geração:
              </span>
              <span>
                {{ data.metodoInsercaoTexto }}
              </span>
            </b-row>
            <b-row
              v-if="data.dataRecebimentoEmail"
              class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Data recebimento e-mail:
              </span>
              <span>
                {{ data.dataRecebimentoEmail ? maskDateHour(data.dataRecebimentoEmail) : "" }}
              </span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Cancelado:
              </span>
              <span>
                {{ data.cancelado ? 'Sim' : 'Não' }}
              </span>
            </b-row>
            <b-row 
              v-if="data.cancelado"
              class="justify-content-between pb-1"
            >
              <span class="font-weight-bold">
                Cancelado Data Hora:
              </span>
              <span>
                {{ data.canceladoDataHora ? maskDateHour(data.canceladoDataHora) : "" }}
              </span>
            </b-row>
            <b-row
              v-if="data.cancelado"
              class="justify-content-between pb-1">
              <span class="font-weight-bold">
                Cancelado Usuário:
              </span>
              <span>
                {{ data.canceladoUsuario }}
              </span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>
    </b-row>
  </b-card-text>
</template>

<script>
import utils from '@/helpers/utils'

export default {
  name: 'ModalVue',
  props: {
    data: {
      type: undefined,
      required: true,
    },
  },
  data() {
    return {
      ...utils,
      mostrarDepartamento: false,
    }
  },
  methods: {
    createDate(dateString) {
      const date = new Date(dateString)
      const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }
      const output = date.toLocaleString('pt-BR', options)
      return output
    },
  },
}
</script>

<style scoped>

</style>