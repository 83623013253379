import TarifaService from '@/service/TarifaService'

export default {
  namespaced: true,
  state: {
    tarifas: [],
    selected: {},
  },
  getters: {
    tarifas(state) {
      return state.tarifas
    },
    selected(state) {
      return state.selected
    },
  },
  mutations: {
    UPDATE_TARIFAS(state, tarifas) {
      state.tarifas = tarifas
    },
    UPDATE_SELECTED(state, selected) {
      state.selected = selected
    },
    SAVE_TARIFA(state, tarifa) {
      state.tarifas.push(tarifa)
    },
    EDIT_TARIFA(state, tarifa) {
      const index = state.tarifas.findIndex(tar => tar.tarifaId === tarifa.tarifaId)
      Object.assign(state.tarifas[index], tarifa)
    },
    DELETE_TARIFA(state, tarifaId) {
      const index = state.tarifas.findIndex(tar => tar.tarifaId === tarifaId)
      state.tarifas.splice(index, 1)
    },
  },
  actions: {
    async findAll({ commit }) {
      const tarifas = await TarifaService.findAll()
      commit('UPDATE_TARIFAS', tarifas)
    },
    async findById({ commit }, tarifaId) {
      const tarifa = await TarifaService.findById(tarifaId)
      commit('UPDATE_SELECTED', tarifa)
    },
    async save({ commit }, multipart) {
      const tarifa = await TarifaService.uploadMultipart(multipart)
      commit('SAVE_TARIFA', tarifa)
    },
    async edit({ commit }, { tarifaId, tarifa }) {
      const edited = await TarifaService.edit(tarifaId, tarifa)
      commit('EDIT_TARIFA', edited)
    },
    async deleteById({ commit }, tarifaId) {
      await TarifaService.deleteById(tarifaId)
      commit('DELETE_TARIFA', tarifaId)
    },
  },
}