import HttpClient from './http-clients/portaljad'

const baseUrl = 'remetente'

export default class RemetenteService {
  static async save(remetente) {
    try {
      const { data } = await HttpClient.post(baseUrl, remetente)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
  
  static async findAll(clienteId) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'cliente', clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(remetenteId) {
    try {
      if (!remetenteId) {
        return undefined
      }
      const { data } = await HttpClient.get([baseUrl, remetenteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}
