<template>
  <div>
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header class="p-2">
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="DollarSignIcon"
            size="18"
          />
          <span class="align-middle ml-50"> Faturas </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <validation-observer #default="{ handleSubmit }">

        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div class="p-2">
            <b-row>
              <b-col
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range
                    label="Data Vencimento Inicial - Data Vencimento Final"
                    :date-range.sync="dateRange"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="8"
              >
                <div class="p-2 d-flex justify-content-end">
                  <b-button
                    variant="primary"
                    class="mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="submit"
                  >
                    Pesquisar
                    <feather-icon
                      class="ml-1"
                      icon="SearchIcon"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card
      v-show="showResultados"
      no-body
      class="mb-2"
    >
      <b-card-header>
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="TagIcon"
            size="18"
          />
          <span class="align-middle ml-50"> Resultados </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
                debounce="500"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>movimentações por página</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        responsive
        :items="faturas"
        :fields="tableColumns"
        :filter="search"
        primary-key="faturaId"
        show-empty
        stacked="md"
        class="table-body"
        :empty-text="carregando ? 'Carregando...' : 'Nenhum resultado encontrado.'"
        empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
        @filtered="onFiltered"
      >
        <template #cell(dataEmissao)="data">
          <span>{{ maskDate(data.item.dataEmissao) }}</span>
        </template>
        <template #cell(dataImportacao)="data">
          <span>{{ maskDate(data.item.dataImportacao) }}</span>
        </template>
        <template #cell(dataVencimento)="data">
          <span>{{ maskDate(data.item.dataVencimento) }}</span>
        </template>
        <template #cell(valor)="data">
          <span>{{ maskCurrency(data.item.valor) }}</span>
        </template>
        <template #cell(urlFatura)="data">
          <a
            class="d-flex justify-content-center"
            :href="data.item.urlFatura"
            download
          ><b-icon-receipt font-scale="1.5"/></a>
        </template>
        <template #cell(urlBoleto)="data">
          <a 
            class="d-flex justify-content-center"
            :href="data.item.urlBoleto"
            download
          >
            <b-icon-sim font-scale="1.5"/>
          </a>
        </template>
        <template #cell(urlZip)="data">
          <div class="d-flex justify-content-center">
            <a 
              v-for="fileZip in data.item.urlZip ? data.item.urlZip.split(';') : ''"
              :key="fileZip"
              :href="fileZip"
              download
            > 
              <b-icon-file-zip font-scale="1.5"/>
            </a>
          </div>

        </template>
      </b-table>
      <hr class="mt-0 mb-1">
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import moment from 'moment'
import { BIconFileZip, BIconReceipt, BIconSim } from 'bootstrap-vue'
import utils from '@/helpers/utils'
import store from '@/store'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import DateRange from '@/components/form/DateRange.vue'

export default {
  name: 'Faturas',
  components: { DateRange, BIconFileZip, BIconReceipt, BIconSim },
  setup() {
    const { appCarregando } = $themeConfig.app
    const showResultados = ref(false)
    const faturasStore = createNamespacedHelpers('faturas')
    const modal = ref(null)
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const state = reactive({
      dateRange: {
        startDate: moment()
          .subtract(30, 'days')
          .format('YYYY-MM-DD'),
        endDate: new Date().toISOString().split('T')[0],
      },
      tableRef: ref(null),
      faturas: faturasStore.useGetters(['faturas']).faturas,
      situacoes: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      carregando: false,
    })

    watch(() => state.carregando, value => {
      if (value) {
        appCarregando.show()
      } else {
        appCarregando.hide()
      }
    })

    watch(() => state.faturas, value => {
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    watch(() => state.carregando, value => {
      if (value) {
        appCarregando.show()
      } else {
        appCarregando.hide()
      }
    })

    watch(() => state.faturas, value => {
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    const sendRequest = filtros => {
      state.carregando = true
      store.dispatch('faturas/findAll', filtros).then(() => {
        state.carregando = false
      })
    }

    const table = {
      tableRef: ref(null),
      currentPage: ref(1),
      perPage: ref(10),
      perPageOptions: [10, 25, 50, 100],
      search: ref(''),
      sortBy: ref('nome'),
      isSortDirDesc: ref(false),
      tableColumns: [
        { key: 'dataEmissao', sortable: true, label: 'Data Emissão' },
        { key: 'dataImportacao', sortable: true, label: 'Data Importação' },
        { key: 'dataVencimento', sortable: true, label: 'Vencimento' },
        { key: 'urlFatura', sortable: true, label: 'Fatura' },
        { key: 'urlBoleto', sortable: true, label: 'Boleto' },
        { key: 'urlZip', sortable: true, label: 'Zip' },
        { key: 'valor', sortable: true, label: 'Valor' },
      ],
    }

    const onSubmit = () => {
      state.currentPage = 1
      table.search.value = ''
      const filtro = {
        clienteId,
        dataInicial: state.dateRange.startDate,
        dataFinal: state.dateRange.endDate,
      }
      sendRequest(filtro)
      showResultados.value = true
    }

    const onFiltered = filteredItems => {
      state.currentPage = 1
      state.totalRows = filteredItems.length
    }

    onSubmit()

    return {
      ...toRefs(state),
      ...utils,
      ...table,
      modal,
      showResultados,
      onSubmit,
      onFiltered,
    }
  },
}
</script>
<style lang="scss" scoped>
  .table-body {
    min-height: 30vh;
  }
  a {
    color: #3366CC;
  }
</style>