<template>
  <div v-if="!carregando">
    <div class="d-flex pt-1 justify-content-center">
      <track-externo-header
        :situacao="situacao"
        class="track-icons custom-width align-content-center"
      />
    </div>
    <b-row class="d-flex justify-content-center">
      <b-row class="info-header mb-1 text-center text-md-left justify-content custom-width">
        <b-col class="text-left align-content-center mb-1 m-md-0">
          <img
            v-if="movimentacaoInfo.urlLogo !== null"
            class="ml-0 ml-md-1"
            :src="movimentacaoInfo.urlLogo"
            width="100px"
            alt="logo-empresa"
          >
        </b-col>
        <b-col class="d-flex py-1 align-items-center">
          <div class="d-flex flex-column m-md-0 text-left ml-md-1">
            <span class="pedido-text">NFe: {{ movimentacaoInfo.numeroNotaFiscal }}</span>
            <span style="font-weight: bold;">Rastreador: {{ movimentacaoInfo.cte }}</span>
            <span style="font-size: 11px;">Consultado às: {{ dataAgora() }}</span>
          </div>
        </b-col>
        <b-col class="d-flex justify-content-md-end justify-content-center align-items-center m-1 m-md-0">
          <!-- YOUTUBE -->
          <a 
            v-if="clienteMidiaSocial !== null && clienteMidiaSocial.urlYoutube"
            :href="clienteMidiaSocial.urlYoutube"
            target="_blank"
          >
            <svg
              fill="#A5A5A5"
              height="2rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 96 96"
            >
              <path
                d="M88.5 16.5C88.5 16.5 75 12 48 12C21 12 7.5 16.5 7.5 16.5C7.5 16.5 3 30 3 48C3 66 7.5 79.5 7.5 79.5C7.5 79.5 21 84 48 84C75 84 88.5 79.5 88.5 79.5C88.5 79.5 93 66 93 48C93 30 88.5 16.5 88.5 16.5ZM39 63.588V32.412L66 48L39 63.588Z"
              />
            </svg>
          </a>
          <!-- INSTAGRAN -->
          <a 
            v-if="clienteMidiaSocial !== null && clienteMidiaSocial.urlInstagram"
            :href="clienteMidiaSocial.urlInstagram"
            target="_blank"
          >
            <svg
              fill="#A5A5A5"
              height="2rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 96 96"
            >
              <path
                d="M32 12C20.956 12 12 20.956 12 32V64C12 75.044 20.956 84 32 84H64C75.044 84 84 75.044 84 64V32C84 20.956 75.044 12 64 12H32ZM72 20C74.208 20 76 21.792 76 24C76 26.208 74.208 28 72 28C69.792 28 68 26.208 68 24C68 21.792 69.792 20 72 20ZM48 28C59.044 28 68 36.956 68 48C68 59.044 59.044 68 48 68C36.956 68 28 59.044 28 48C28 36.956 36.956 28 48 28ZM48 36C44.8174 36 41.7652 37.2643 39.5147 39.5147C37.2643 41.7652 36 44.8174 36 48C36 51.1826 37.2643 54.2348 39.5147 56.4853C41.7652 58.7357 44.8174 60 48 60C51.1826 60 54.2348 58.7357 56.4853 56.4853C58.7357 54.2348 60 51.1826 60 48C60 44.8174 58.7357 41.7652 56.4853 39.5147C54.2348 37.2643 51.1826 36 48 36Z"
              />
            </svg>
          </a>
          <!-- FACEBOOK -->
          <a
            v-if="clienteMidiaSocial !== null && clienteMidiaSocial.urlFacebook"
            :href="clienteMidiaSocial.urlFacebook"
            target="_blank"
          >
            <svg
              fill="#A5A5A5"
              height="2rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 96 96"
            >
              <path
                d="M76 12H20C15.58 12 12 15.58 12 20V76C12 80.42 15.58 84 20 84H50.484V56.156H41.112V45.256H50.484V37.236C50.484 27.94 56.168 22.872 64.464 22.872C67.26 22.864 70.052 23.008 72.832 23.292V33.012H67.12C62.6 33.012 61.72 35.148 61.72 38.3V45.24H72.52L71.116 56.14H61.656V84H76C80.42 84 84 80.42 84 76V20C84 15.58 80.42 12 76 12Z"
              />
            </svg>
          </a>
          <!-- X -->
          <a
            v-if="clienteMidiaSocial !== null && clienteMidiaSocial.urlX"
            :href="clienteMidiaSocial.urlX"
            target="_blank"
          >
            <svg
              fill="#A5A5A5"
              height="2rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 96 96"
            >
              <path
                d="M11.4688 12L38.9453 51.2734L10.9375 84H21.5234L43.6797 58.0391L61.8438 84H85.4844L56.6953 42.7891L82.9766 12H72.5547L51.9844 36.0391L35.1953 12H11.4688Z"
              />
            </svg>

          </a>
          <!-- LINKEDIN -->
          <a 
            v-if="clienteMidiaSocial !== null && clienteMidiaSocial.urlLinkedin"
            :href="clienteMidiaSocial.urlLinkedin"
            target="_blank"
          >
            <svg
              fill="#A5A5A5"
              height="2rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 96 96"
            >
              <path
                d="M76 12H20C15.58 12 12 15.58 12 20V76C12 80.42 15.58 84 20 84H76C80.42 84 84 80.42 84 76V20C84 15.58 80.42 12 76 12ZM30.952 68C28.164 68 25.904 65.74 25.904 62.952V45.044C25.908 42.26 28.168 40 30.952 40C33.74 40 36 42.26 36 45.048V62.956C36 65.74 33.74 68 30.952 68ZM30.776 34.868C27.692 34.868 25.632 32.812 25.632 30.068C25.632 27.324 27.688 25.268 31.116 25.268C34.2 25.268 36.26 27.324 36.26 30.068C36.26 32.812 34.204 34.868 30.776 34.868ZM67.116 68C64.42 68 62.232 65.812 62.232 63.116V52.696C62.232 48.464 59.628 48 58.652 48C57.676 48 54.42 48.14 54.42 52.696V63.116C54.42 65.812 52.232 68 49.536 68H49.212C46.516 68 44.328 65.812 44.328 63.116V45.048C44.328 42.26 46.588 40 49.376 40C52.164 40 54.424 42.26 54.424 45.048C54.424 45.048 55.552 40 63.216 40C68.092 40 72 43.908 72 52.696V63.116C72 65.812 69.812 68 67.116 68Z"
              />
            </svg>
          </a>
          <!-- TIKTOK -->
          <a 
            v-if="clienteMidiaSocial !== null && clienteMidiaSocial.urlTiktok"
            :href="clienteMidiaSocial.urlTiktok"
            target="_blank"
          >
            <svg
              fill="#A5A5A5"
              height="2rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 96 96"
            >
              <path
                d="M24 12C17.4201 12 12 17.4201 12 24V72C12 78.5799 17.4201 84 24 84H72C78.5799 84 84 78.5799 84 72V24C84 17.4201 78.5799 12 72 12H24ZM48 28H56C56 32.02 61.884 36 64 36V44C61.58 44 58.672 42.9366 56 41.1406V56C56 62.616 50.616 68 44 68C37.384 68 32 62.616 32 56C32 49.384 37.384 44 44 44V52C41.792 52 40 53.796 40 56C40 58.204 41.792 60 44 60C46.208 60 48 58.204 48 56V28Z"
              />
            </svg>
          </a>
          <!-- PINTEREST -->
          <a
            v-if="clienteMidiaSocial !== null && clienteMidiaSocial.urlPinterest"
            :href="clienteMidiaSocial.urlPinterest"
            target="_blank"
          >
            <svg
              v-if="clienteMidiaSocial !== null && clienteMidiaSocial.urlPinterest"
              fill="#A5A5A5"
              height="2rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 96 96"
            >
              <path
                d="M48 8C25.908 8 8 25.908 8 48C8 64.948 18.544 79.42 33.424 85.248C33.076 82.084 32.756 77.228 33.564 73.776C34.292 70.656 38.252 53.892 38.252 53.892C38.252 53.892 37.056 51.496 37.056 47.956C37.056 42.392 40.28 38.244 44.292 38.244C47.704 38.244 49.352 40.808 49.352 43.876C49.352 47.308 47.168 52.44 46.04 57.192C45.096 61.176 48.036 64.42 51.964 64.42C59.072 64.42 64.536 56.924 64.536 46.104C64.536 36.528 57.656 29.832 47.828 29.832C36.448 29.832 29.768 38.368 29.768 47.192C29.768 50.628 31.092 54.316 32.744 56.32C33.072 56.716 33.116 57.064 33.02 57.468C32.716 58.732 32.044 61.448 31.912 62.004C31.74 62.736 31.332 62.892 30.576 62.536C25.58 60.208 22.456 52.904 22.456 47.04C22.456 34.424 31.624 22.832 48.888 22.832C62.764 22.832 73.548 32.72 73.548 45.936C73.548 59.724 64.856 70.816 52.792 70.816C48.74 70.816 44.928 68.708 43.624 66.224C43.624 66.224 41.616 73.86 41.132 75.736C40.228 79.208 37.792 83.568 36.16 86.224C39.9 87.372 43.876 88 48 88C70.088 88 88 70.088 88 48C88 25.912 70.092 8 48 8Z"
              />
            </svg>
          </a>
        </b-col>
      </b-row>
      <b-row class="info-center custom-width">
        <b-col
          md="4"
          class="info-box pt-1 ml-md-0 mr-md-1 d-flex mb-1 mb-md-0 flex-column"
        >
          <h5
            class="ml-1 mt-1"
            style="font-weight: 700;"
          >
            HISTÓRICO DO ENVIO
          </h5>
          <div class="line-text ml-1 mb-2 mr-1"/>
          <div>
            <!--  ENTREGUE  -->
            <b-row
              v-if="Object.keys(situacao)[0] === 'ENTREGUE'"
              class="py-1"
            >
              <b-col cols="1">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.75"
                    y="0.75"
                    width="14.5"
                    height="14.5"
                    rx="7.25"
                    :stroke="alterColor('icon', Object.keys(situacao).includes('ENTREGUE'))"
                    stroke-width="1.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.99998 11.3333H9.99998C10.2766 11.3333 10.5133 11.1666 10.6133 10.9266L11.62 8.57663C11.65 8.49996 11.6666 8.41996 11.6666 8.33329V7.66663C11.6666 7.29996 11.3666 6.99996 11 6.99996H8.89665L9.21331 5.47663L9.22331 5.36996C9.22331 5.23329 9.16665 5.10663 9.07665 5.01663L8.72331 4.66663L6.52665 6.86329C6.40665 6.98329 6.33331 7.14996 6.33331 7.33329V10.6666C6.33331 11.0333 6.63331 11.3333 6.99998 11.3333ZM6.99998 7.33329L8.44665 5.88663L7.99998 7.66663H11V8.33329L9.99998 10.6666H6.99998V7.33329ZM5.66665 7.33329H4.33331V11.3333H5.66665V7.33329Z"
                    :fill="alterColor('icon', Object.keys(situacao).includes('ENTREGUE'))"
                  />
                </svg>
              </b-col>
              <b-col cols="10">
                <span :style="alterColor('text', Object.keys(situacao).includes('ENTREGUE'))">Entregue {{ formatDateToPtBr(situacao.ENTREGUE[0].dataHora) }}</span>
              </b-col>
            </b-row>
            <b-row
              v-else
              class="py-1"
            >
              <b-col cols="1">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.75"
                    y="0.75"
                    width="14.5"
                    height="14.5"
                    rx="7.25"
                    :stroke="alterColor('icon', Object.keys(situacao)[0] === 'ENTREGUE')"
                    stroke-width="1.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.99998 11.3333H9.99998C10.2766 11.3333 10.5133 11.1666 10.6133 10.9266L11.62 8.57663C11.65 8.49996 11.6666 8.41996 11.6666 8.33329V7.66663C11.6666 7.29996 11.3666 6.99996 11 6.99996H8.89665L9.21331 5.47663L9.22331 5.36996C9.22331 5.23329 9.16665 5.10663 9.07665 5.01663L8.72331 4.66663L6.52665 6.86329C6.40665 6.98329 6.33331 7.14996 6.33331 7.33329V10.6666C6.33331 11.0333 6.63331 11.3333 6.99998 11.3333ZM6.99998 7.33329L8.44665 5.88663L7.99998 7.66663H11V8.33329L9.99998 10.6666H6.99998V7.33329ZM5.66665 7.33329H4.33331V11.3333H5.66665V7.33329Z"
                    :fill="alterColor('icon', Object.keys(situacao)[0] === 'ENTREGUE')"
                  />
                </svg>
              </b-col>
              <b-col cols="10">
                <span :style="alterColor('text', Object.keys(situacao)[0] === 'ENTREGUE')"> Entregue </span>
              </b-col>
            </b-row>
            <div class="line-text ml-2 mb-1 mr-1"/>
            <!--  TRANSITO  -->
            <b-row
              v-if="!Object.keys(situacao).includes('TRANSITO')"
              class="py-1"
            >
              <b-col cols="1">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.75"
                    y="0.75"
                    width="14.5"
                    height="14.5"
                    rx="7.25"
                    :stroke="alterColor('icon', Object.keys(situacao)[0] === 'TRANSITO')"
                    stroke-width="1.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.6667 6.66671H9.66668V5.33337H5.00001C4.63334 5.33337 4.33334 5.63337 4.33334 6.00004V9.66671H5.00001C5.00001 10.22 5.44668 10.6667 6.00001 10.6667C6.55334 10.6667 7.00001 10.22 7.00001 9.66671H9.00001C9.00001 10.22 9.44668 10.6667 10 10.6667C10.5533 10.6667 11 10.22 11 9.66671H11.6667V8.00004L10.6667 6.66671ZM10.5 7.16671L11.1533 8.00004H9.66668V7.16671H10.5ZM5.66668 9.66671C5.66668 9.85004 5.81668 10 6.00001 10C6.18334 10 6.33334 9.85004 6.33334 9.66671C6.33334 9.48337 6.18334 9.33337 6.00001 9.33337C5.81668 9.33337 5.66668 9.48337 5.66668 9.66671ZM6.74001 9.00004C6.55668 8.79671 6.29668 8.66671 6.00001 8.66671C5.70334 8.66671 5.44334 8.79671 5.26001 9.00004H5.00001V6.00004H9.00001V9.00004H6.74001ZM9.66668 9.66671C9.66668 9.85004 9.81668 10 10 10C10.1833 10 10.3333 9.85004 10.3333 9.66671C10.3333 9.48337 10.1833 9.33337 10 9.33337C9.81668 9.33337 9.66668 9.48337 9.66668 9.66671Z"
                    :fill="alterColor('icon', Object.keys(situacao)[0] === 'TRANSITO')"
                  />
                </svg>
              </b-col>
              <b-col
                cols="10"
                class="justify-content-start"
              >
                <span :style="alterColor('text', Object.keys(situacao)[0] === 'TRANSITO')">
                  Transito
                </span>
              </b-col>
            </b-row>
            <b-row
              v-else
              class="py-1"
            >
              <b-col cols="1">
                <svg
                  v-if="(situacao.TRANSITO[0].situacaoTracking === 'PROBLEMA_NA_ENTREGA' || situacao.TRANSITO[0].situacaoTracking === 'EXTRAVIADO')"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.75"
                    y="0.75"
                    width="14.5"
                    height="14.5"
                    rx="7.25"
                    :stroke="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'PROBLEMA_NA_ENTREGA')"
                    stroke-width="1.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.8333 7.83333C10.8333 6.36333 9.63667 5.16667 8.16667 5.16667C7.69667 5.16667 7.25667 5.29 6.87667 5.50333L6.39 5.01667C6.90333 4.69 7.51333 4.5 8.16667 4.5C10.0067 4.5 11.5 5.99333 11.5 7.83333C11.5 8.48667 11.31 9.09667 10.9833 9.61333L10.4967 9.12667C10.71 8.74333 10.8333 8.30333 10.8333 7.83333ZM8.87333 7.5H9.83333V8.16667H9.54L8.87333 7.5ZM4.97 4.54333L4.5 5.01333L5.42667 5.94C5.05333 6.47667 4.83333 7.13 4.83333 7.83333C4.83333 9.67333 6.32667 11.1667 8.16667 11.1667C8.87 11.1667 9.52333 10.9467 10.06 10.5733L10.9867 11.5L11.4567 11.03L4.97 4.54333ZM5.5 7.83333C5.5 9.30333 6.69667 10.5 8.16667 10.5C8.68667 10.5 9.16667 10.35 9.57667 10.09L7.65333 8.16667H6.5V7.5H6.98667L5.91 6.42333C5.65 6.83333 5.5 7.31333 5.5 7.83333Z"
                    :stroke="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'PROBLEMA_NA_ENTREGA')"
                  />
                </svg>
                <svg
                  v-else-if="situacao.TRANSITO[0].situacaoTracking === 'DEVOLVIDO'"
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  :stroke="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'DEVOLVIDO')"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.28571 6.85714C2.28571 3.07048 5.35619 0 9.14286 0C12.9295 0 16 3.07048 16 6.85714C16 10.6438 12.9295 13.7143 9.14286 13.7143C7.24571 13.7143 5.53905 12.9448 4.29714 11.7029L5.37905 10.621C6.33905 11.5886 7.67238 12.1905 9.14286 12.1905C12.0914 12.1905 14.4762 9.80571 14.4762 6.85714C14.4762 3.90857 12.0914 1.52381 9.14286 1.52381C6.19429 1.52381 3.80952 3.90857 3.80952 6.85714H6.09524L3.01714 9.92762L2.96381 9.82095L0 6.85714H2.28571ZM8.38096 7.61903V3.80951H9.52382V6.97141L12.2057 8.56379L11.6191 9.53903L8.38096 7.61903Z"
                    :stroke="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'DEVOLVIDO')"
                  />
                </svg>
                <svg
                  v-else-if="situacao.TRANSITO[0].situacaoTracking === 'BLOQUEADO'"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  :stroke="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'BLOQUEADO')"

                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 0.333374C3.584 0.333374 0 3.91737 0 8.33337C0 12.7494 3.584 16.3334 8 16.3334C12.416 16.3334 16 12.7494 16 8.33337C16 3.91737 12.416 0.333374 8 0.333374ZM1.60002 8.33365C1.60002 4.79765 4.46402 1.93365 8.00002 1.93365C9.48002 1.93365 10.84 2.43765 11.92 3.28565L2.95202 12.2537C2.10402 11.1737 1.60002 9.81365 1.60002 8.33365ZM4.08 13.3815C5.16 14.2295 6.52 14.7335 8 14.7335C11.536 14.7335 14.4 11.8695 14.4 8.33345C14.4 6.85345 13.896 5.49345 13.048 4.41345L4.08 13.3815Z"
                    :stroke="alterColor('icon', situacao.TRANSITO[0].situacaoTracking === 'BLOQUEADO')"
                  />
                </svg>

                <svg
                  v-else-if="situacao.TRANSITO[0].situacaoTracking === 'AGUARDANDO_RETIRADA'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.75"
                    y="0.75"
                    width="14.5"
                    height="14.5"
                    rx="7.25"
                    :stroke="alterColor('icon', Object.keys(situacao)[0] === 'AGUARDANDO_RETIRADA')"
                    stroke-width="1.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.3833 5.18333L10.8467 5.74333C10.9433 5.85667 11 6.00667 11 6.16667V10.3333C11 10.7 10.7 11 10.3333 11H5.66667C5.3 11 5 10.7 5 10.3333V6.16667C5 6.00667 5.05667 5.85667 5.15333 5.74333L5.61333 5.18333C5.70667 5.07 5.84333 5 6 5H10C10.1567 5 10.2933 5.07 10.3833 5.18333ZM9.92 5.66667H6.08L5.81333 5.99H10.19L9.92 5.66667ZM5.66667 10.3333V6.66667H10.3333V10.3333H5.66667ZM7.51667 7.33333H8.48333V8.33333H9.33333L8 9.66667L6.66667 8.33333H7.51667V7.33333Z"
                    :stroke="alterColor('icon', Object.keys(situacao)[0] === 'AGUARDANDO_RETIRADA')"
                  />
                </svg>
                <svg
                  v-else
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.75"
                    y="0.75"
                    width="14.5"
                    height="14.5"
                    rx="7.25"
                    :stroke="alterColor('icon', Object.keys(situacao)[0] === 'TRANSITO')"
                    stroke-width="1.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.6667 6.66671H9.66668V5.33337H5.00001C4.63334 5.33337 4.33334 5.63337 4.33334 6.00004V9.66671H5.00001C5.00001 10.22 5.44668 10.6667 6.00001 10.6667C6.55334 10.6667 7.00001 10.22 7.00001 9.66671H9.00001C9.00001 10.22 9.44668 10.6667 10 10.6667C10.5533 10.6667 11 10.22 11 9.66671H11.6667V8.00004L10.6667 6.66671ZM10.5 7.16671L11.1533 8.00004H9.66668V7.16671H10.5ZM5.66668 9.66671C5.66668 9.85004 5.81668 10 6.00001 10C6.18334 10 6.33334 9.85004 6.33334 9.66671C6.33334 9.48337 6.18334 9.33337 6.00001 9.33337C5.81668 9.33337 5.66668 9.48337 5.66668 9.66671ZM6.74001 9.00004C6.55668 8.79671 6.29668 8.66671 6.00001 8.66671C5.70334 8.66671 5.44334 8.79671 5.26001 9.00004H5.00001V6.00004H9.00001V9.00004H6.74001ZM9.66668 9.66671C9.66668 9.85004 9.81668 10 10 10C10.1833 10 10.3333 9.85004 10.3333 9.66671C10.3333 9.48337 10.1833 9.33337 10 9.33337C9.81668 9.33337 9.66668 9.48337 9.66668 9.66671Z"
                    :fill="alterColor('icon', Object.keys(situacao)[0] === 'TRANSITO')"
                  />
                </svg>
              </b-col>
              <b-col
                cols="10"
                class="justify-content-start"
              >
                <span
                  v-if="situacao.TRANSITO[0].situacaoTracking === 'TRANSITO'"
                  :style="alterColor('text', Object.keys(situacao)[0] === 'TRANSITO')"
                >
                  Transito
                </span>
                <span
                  v-else-if="situacao.TRANSITO[0].situacaoTracking === 'AGUARDANDO_RETIRADA'" 
                  :style="alterColor('text', situacao.TRANSITO[0].situacaoTracking === 'AGUARDANDO_RETIRADA')"
                > 
                  Aguardando Retirada
                </span>
                <span
                  v-else-if="situacao.TRANSITO[0].situacaoTracking === 'BLOQUEADO'" 
                  :style="alterColor('text', situacao.TRANSITO[0].situacaoTracking === 'BLOQUEADO')"
                > 
                  Bloqueado
                </span>
                <span
                  v-else-if="situacao.TRANSITO[0].situacaoTracking === 'PROBLEMA_NA_ENTREGA'" 
                  :style="alterColor('text', situacao.TRANSITO[0].situacaoTracking === 'PROBLEMA_NA_ENTREGA')"
                > 
                  Problema na Entrega
                </span>
                <span
                  v-else-if="situacao.TRANSITO[0].situacaoTracking === 'DEVOLVIDO'" 
                  :style="alterColor('text', situacao.TRANSITO[0].situacaoTracking === 'DEVOLVIDO')"
                > 
                  Devolvido
                </span>
              </b-col>
            </b-row>
            <div class="line-text ml-2 mb-1 mr-1"/>
            <div v-if="Object.keys(situacao)[0] === 'TRANSITO'">
              <b-collapse
                id="collapse-transito"
                visible
              >
                <b-row>
                  <div class="row-container">
                    <div
                      class="row-colapse ml-2"
                      :style="alterColor('line', Object.keys(situacao)[0] === 'TRANSITO')"
                    />
                  </div>
                  <b-col>
                    <h6>{{ formatDateToPtBr(situacao.TRANSITO[0].dataHora) }} - {{ maskHourMin(situacao.TRANSITO[0].dataHora) }}</h6>
                    <b-row class="mb-1 align-items-center">
                      <b-col>
                        <span>{{ situacao.TRANSITO[0].codigoReferencia }}</span>
                      </b-col>
                      <b-col v-if="situacao.TRANSITO[0].unidade">
                        <span>{{ situacao.TRANSITO[0].unidade }}</span>
                      </b-col>
                    </b-row>
                    <div class="line-text mb-1 mr-1"/>
                  </b-col>
                </b-row>
              </b-collapse>
            </div>
            <div>
              <b-collapse
                v-for="(item, index) in situacao.TRANSITO"
                id="collapse-transito"
                :key="index"
              >
                <b-row>
                  <div class="row-container">
                    <div
                      class="row-colapse ml-2"
                      :style="alterColor('line', Object.keys(situacao)[0] === 'TRANSITO')"
                    />
                  </div>
                  <b-col>
                    <h6>{{ formatDateToPtBr(item.dataHora) }} - {{ maskHourMin(item.dataHora) }}</h6>
                    <b-row class="mb-1 align-items-center">
                      <b-col>
                        <span>{{ item.codigoReferencia }}</span>
                      </b-col>
                      <b-col v-if="item.unidade">
                        <span>{{ item.unidade }}</span>
                      </b-col>
                    </b-row>
                    <div class="line-text mb-1 mr-1"/>
                  </b-col>
                </b-row>
              </b-collapse>
            </div>
            <!--  ENVIADO  -->
            <b-row
              v-if="Object.keys(situacao).length === 0"
              class="py-1"
            >
              <b-col cols="1">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1"
                    y="1"
                    width="14"
                    height="14"
                    rx="7"
                    :stroke="alterColor('icon', Object.keys(situacao).length === 0)"
                    stroke-width="2"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.25 4.5H8.4375V8.65625L12 10.77L11.4062 11.7438L7.25 9.25V4.5Z"
                    :fill="alterColor('icon', Object.keys(situacao).length === 0)"
                  />
                </svg>
              </b-col>
              <b-col cols="10">
                Coleta Solicitada
              </b-col>
            </b-row>
            <b-row v-else-if="Object.keys(situacao)[0] === 'ENVIADO'">
              <b-col cols="1">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  :stroke="alterColor('icon', Object.keys(situacao).length === 0)"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.66666 0C4.25066 0 0.666664 3.584 0.666664 8C0.666664 12.416 4.25066 16 8.66666 16C13.0827 16 16.6667 12.416 16.6667 8C16.6667 3.584 13.0827 0 8.66666 0ZM7.06665 11.5997L11.8666 7.99972L7.06665 4.39972V11.5997ZM2.26668 8.00028C2.26668 11.5283 5.13868 14.4003 8.66668 14.4003C12.1947 14.4003 15.0667 11.5283 15.0667 8.00028C15.0667 4.47228 12.1947 1.60028 8.66668 1.60028C5.13868 1.60028 2.26668 4.47228 2.26668 8.00028Z"
                    :fill="alterColor('icon', Object.keys(situacao).length === 0)"
                  />
                </svg>
              </b-col>
              <b-col cols="10">
                Enviado
              </b-col>
            </b-row>
            <b-row
              v-else
              class="py-1"
            >
              <b-col cols="1">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  :stroke="alterColor('icon', Object.keys(situacao).length === 0)"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.66666 0C4.25066 0 0.666664 3.584 0.666664 8C0.666664 12.416 4.25066 16 8.66666 16C13.0827 16 16.6667 12.416 16.6667 8C16.6667 3.584 13.0827 0 8.66666 0ZM7.06665 11.5997L11.8666 7.99972L7.06665 4.39972V11.5997ZM2.26668 8.00028C2.26668 11.5283 5.13868 14.4003 8.66668 14.4003C12.1947 14.4003 15.0667 11.5283 15.0667 8.00028C15.0667 4.47228 12.1947 1.60028 8.66668 1.60028C5.13868 1.60028 2.26668 4.47228 2.26668 8.00028Z"
                    :fill="alterColor('icon', Object.keys(situacao).length === 0)"
                  />
                </svg>
              </b-col>
              <b-col
                cols="10"
                class="justify-content-start"
              >
                <span :style="alterColor('text')">
                  Enviado
                </span>
              </b-col>
            </b-row>
            <div class="line-text ml-2 mb-1 mr-1"/>
            <div v-if="Object.keys(situacao)[0] === 'ENVIADO'">
              <b-collapse
                id="collapse-enviado"
                visible
              >
                <b-row>
                  <div class="row-container">
                    <div
                      class="row-colapse ml-2"
                      :style="alterColor('line', Object.keys(situacao)[0] === 'ENVIADO')"
                    />
                  </div>
                  <b-col>
                    <h6>{{ formatDateToPtBr(situacao.ENVIADO[0].dataHora) }} - {{ maskHourMin(situacao.ENVIADO[0].dataHora) }}</h6>
                    <b-row class="mb-1 align-items-center">
                      <b-col>
                        <span>{{ situacao.ENVIADO[0].codigoReferencia }}</span>
                      </b-col>
                    </b-row>
                    <div class="line-text mb-1 mr-1"/>
                  </b-col>
                </b-row>
              </b-collapse>
            </div>
            <div>
              <b-collapse
                v-for="(item, index) in situacao.ENVIADO"
                id="collapse-enviado"
                :key="index"
              >
                <b-row>
                  <div class="row-container">
                    <div
                      class="row-colapse ml-2"
                      :style="alterColor('line', Object.keys(situacao)[0] === 'ENVIADO')"
                    />
                  </div>
                  <b-col>
                    <h6>{{ formatDateToPtBr(item.dataHora) }} - {{ maskHourMin(item.dataHora) }}</h6>
                    <b-row class="mb-1 align-items-center">
                      <b-col>
                        <span>{{ item.codigoReferencia }}</span>
                      </b-col>
                      <b-col v-if="item.unidade">
                        <span>{{ item.unidade }}</span>
                      </b-col>
                    </b-row>
                    <div class="line-text mb-1 mr-1"/>
                  </b-col>
                </b-row>
              </b-collapse>
            </div>
          </div>
          <div class="text-right mr-1 mt-1">
            <span class="pb-2" style="color: #263bdb"  v-b-toggle="['collapse-entregue', 'collapse-transito', 'collapse-enviado']">Ver Detalhes</span>
          </div>
        </b-col>
        <b-col 
          class="info-box mb-1 mb-md-0 d-flex justify-content-center align-items-center"
        >
          <div
            v-if="Object.keys(situacao).length === 0"
            class="text-center"
          >
            <h3
              class="py-1"
              style="font-weight: bold;"
            >Aguardando Atualização</h3>
          </div>
          <div
            v-else-if="Object.keys(situacao).includes('ENTREGUE')"
            class="d-flex flex-column justify-items-center"
          >
            <span class="text-entregue">ENTREGUE</span>
            <a
              v-if="movimentacaoInfo.comprovanteRecebedor"
              :href="movimentacaoInfo.comprovanteRecebedor" 
              target="_blank"
              class="mt-1 btn btn-danger"
            >
              Comprovante
            </a>
          </div>
          <div
            v-else
          >
            <div
              class="text-center"
            >
              <h3
                class="py-1"
                style="font-weight: bold;"
              >
                Previsão de Entrega
              </h3>
              <h5>
                {{ previsaoData(movimentacaoInfo.dataPrazoEstimado).mes }}
              </h5>
              <h5>
                {{ previsaoData(movimentacaoInfo.dataPrazoEstimado).diaSemana }}
              </h5>
              <p
                class="previsao-dias py-1"
              >
                {{ previsaoData(movimentacaoInfo.dataPrazoEstimado).dia }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col
          v-if="jadlogFranquia.razaoSocial"
          md="4"
          class="info-box ml-md-1"
        >
          <div class="py-1">
            <h4>Transportadora responsável por sua entrega</h4>
          </div>
          <div class="pb-1">
            <h5>Nome da Unidade</h5>
            <span>{{ jadlogFranquia.razaoSocial }}</span>
          </div>
          <div class="pb-1">
            <h5>Endereço</h5>
            <span>{{ jadlogFranquia.endereco }}</span>
          </div>
          <div class="pb-1">
            <h5>Bairro</h5>
            <span>{{ jadlogFranquia.bairro }}</span>
          </div>
          <div class="pb-1">
            <h5>Cidade</h5>
            <span>{{ jadlogFranquia.cidade + " - " + jadlogFranquia.uf }}</span>
          </div>
          <div class="pb-1 d-flex flex-column">
            <h5>Telefones</h5>
            <span v-if="/\d{4,}/.test(jadlogFranquia.telefoneFixo)">Fixo {{ jadlogFranquia.telefoneFixo }}</span>
            <span v-if="/\d{4,}/.test(jadlogFranquia.celular)">Celular {{ jadlogFranquia.celular }}</span>
          </div>
        </b-col> 
      </b-row>
      <b-row class="info-footer m-1 custom-width">
        <b-col
          sm="12"
          md="5"
          class="text-md-left ml-md-5 p-1 d-flex flex-column justify-content-center"
        >
          <h4>Remetente</h4>
          <span>Nome: {{ movimentacaoInfo.remetenteNome }}</span>
          <span>Rua: {{ movimentacaoInfo.remetenteRua }} </span>
          <span>Estabelecimento: {{ movimentacaoInfo.remetenteEstabelecimento }}</span>
          <span>Cidade / CEP: {{ movimentacaoInfo.remetenteCidade + " / " + movimentacaoInfo.remetenteCep }} </span>
        </b-col>
        <b-col
          sm="12"
          md="5"
          class="text-md-left ml-md-5 p-1 d-flex flex-column justify-content-center"
        >
          <h4>Destinatario</h4>
          <span>Nome: {{ movimentacaoInfo.destinatarioNome }}</span>
          <span>Rua: {{ movimentacaoInfo.destinatarioRua }} </span>
          <span>Estabelecimento: {{ movimentacaoInfo.destinatarioEstabelecimento }}</span>
          <span>Cidade / CEP: {{ movimentacaoInfo.destinatarioCidade + " / " + movimentacaoInfo.destinatarioCep }} </span>
        </b-col>
      </b-row>
    </b-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import swal from 'sweetalert2/dist/sweetalert2.min'
import store from '@/store'
import { $themeConfig } from '@themeConfig'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { reactive, toRefs } from '@vue/composition-api'
import utils from '@/helpers/utils'
import TrackExternoHeader from './components/track-header.vue'

export default {
  name: 'TrackExterno',
  components: { TrackExternoHeader },
  setup() {
    const appLoading = $themeConfig.app.appLoadingImage
    const trackExternoStore = createNamespacedHelpers('trackExterno')

    const dataAgora = () => {
      const data = new Date()
      const dataBrasil = new Date(data.getTime() - 3 * 60 * 60 * 1000)
      return utils.maskDateHour(dataBrasil.toISOString().split('.')[0])
    }

    const previsaoData = dataPrevisao => {
      const nowBrazilianTime = new Date(dataPrevisao)
      const mes = utils.mesNomes[nowBrazilianTime.getMonth()]
      const diaSemana = new Intl.DateTimeFormat('pt-BR', { weekday: 'long' }).format(nowBrazilianTime)
      const dia = nowBrazilianTime.getDate()
      return { mes, diaSemana, dia }
    }

    const state = reactive({
      situacao: trackExternoStore.useGetters(['getSituacao']).getSituacao,
      movimentacaoInfo: trackExternoStore.useGetters(['getMovimentacaoInfo']).getMovimentacaoInfo,
      jadlogFranquia: trackExternoStore.useGetters(['getJadlogFranquia']).getJadlogFranquia,
      clienteMidiaSocial: trackExternoStore.useGetters(['getClienteMidiaSocial']).getClienteMidiaSocial,
      carregando: true,
    })

    const param = router.currentRoute.params
    if (param.id) {
      appLoading.show()
      store.dispatch('trackExterno/findValues', param.id).then(() => {
        state.carregando = false
        appLoading.hide()
      }).catch(() => {
        appLoading.hide()
        swal.fire({
          icon: 'error',
          html: '<h4>Link fora do padrão!</h4> <br> <h6>Contate sua Unidade</h6>',
          showConfirmButton: true,
        })
      })
    } else {
      appLoading.hide()
      swal.fire({
        icon: 'warning',
        text: 'Link fora do padrão!',
        showConfirmButton: true,
      })
    }

    const alterColor = (local, primary) => {
      const key = Object.keys(state.situacao)[0]
      let as = ''
      if (local === 'text') {
        as = 'color: '
      }
      if (local === 'line') {
        as = 'background-color: '
      }
      if (primary) {
        if (key === 'ENTREGUE') {
          return `${as}#61AA82`
        }
        if (key === 'ENVIADO' || key == null) {
          return `${as}#000000`
        }
        if (key === 'TRANSITO') {
          if (state.situacao.TRANSITO.length > 0) {
            const { situacaoTracking } = state.situacao.TRANSITO[0]
            if (situacaoTracking === 'PROBLEMA_NA_ENTREGA' || situacaoTracking === 'EXTRAVIADO' || situacaoTracking === 'BLOQUEADO') {
              return `${as}#D33540`
            }
            if (situacaoTracking === 'AGUARDANDO_RETIRADA') {
              return `${as}#4E7EFF`
            }
            return `${as}#9747FF`
          }
          return `${as}#9747FF`
        }
      }
      return `${as}#A5A5A5`
    }

    return {
      ...toRefs(state),
      ...utils,
      dataAgora,
      previsaoData,
      alterColor,
    }
  },
}
</script>

<style lang="scss">
.track-icons {
  min-height: 80px;
  border-radius: 7px;
}

.info-header {
  background: #FFFFFF;
  min-height: 100px;
  border-radius: 8px;
  border: solid #a9a7a7 0.5px;
}

.pedido-text {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
}

.info-footer {
  min-height: 11rem;
  border: solid #a9a7a7 0.5px;
  border-radius: 8px;
  color: #FFFFFF;
  background-color: #F55559;
  h4 {
    color: #FFFFFF
  }
}

.custom-width {
  width: 95vw;
}

@media (min-width: 768px) {
  .custom-width {
    width: 90vw;
  }
}

@media (min-width: 992px) {
  .custom-width {
    width: 70vw;
  }
}

.info-center {
  border-radius: border-box;
}

.info-box {
  overflow-y: auto;
  background: #FFFFFF;
  border: solid #a9a7a7 0.5px;
  border-radius: 8px;
  height: 30rem;
}

.text-entregue {
  font-weight: 700;
  font-size: 2rem;
}

.row-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row-colapse {
  width: 3px;
  // background-color: #a9a7a7;
  flex-grow: 1;
  transform: translateY(-1rem);
}

.line-text {
  height: 0.1rem;
  border-top: 1px dotted #a9a7a7
}

.previsao-dias {
  font-weight: 700;
  font-size: 40px;
}
</style>
