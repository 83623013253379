import UsuarioService from '@/service/UsuarioService'

export default {
  namespaced: true,
  state: {
    usuario: [],
  },

  getters: {
    usuario(state) {
      return state.usuario
    },
  },
  mutations: {
    SET_USUARIO(state, usuario) {
      state.usuario = usuario
    },
  },
  actions: {
    async findByUid({ commit }, { FirebaseUid, senha }) {
      const usuario = await UsuarioService.findByUid(FirebaseUid, senha)
      commit('SET_USUARIO', usuario)
    },
  },
}