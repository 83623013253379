import DepartamentoService from '@/service/DepartamentoService'
import adapter from '@/adapters/ClienteAdapter'

export default {
  namespaced: true,
  state: {
    departamento: null,
    departamentos: [],
    departamentosOptions: [],
  },

  getters: {
    departamento(state) {
      return state.departamento
    },
    departamentos(state) {
      return state.departamentos
    },
    departamentosOptions(state) {
      return state.departamentosOptions
    },
  },
  mutations: {
    SAVE_DEPARTAMENTO(state, departamentos) {
      state.departamentos.push(departamentos)
    },
    UPDATE_DEPARTAMENTO(state, departamento) {
      state.departamento = departamento
    },
    UPDATE_DEPARTAMENTOS(state, departamentos) {
      state.departamentos = departamentos
    },
    UPDATE_DEPARTAMENTOS_OPTIONS(state, departamentosOptions) {
      state.departamentosOptions = departamentosOptions
    },
  },
  actions: {
    async save({ commit }, departamento) {
      const departamentoId = await DepartamentoService.save(adapter.toApi(departamento))
      const departamentoToSave = { ...departamento, departamentoId }
      commit('SAVE_departamento', departamentoToSave)
    },
    async findAll({ commit }, clienteId) {
      const departamentos = await DepartamentoService.findAll(clienteId)
      commit('UPDATE_DEPARTAMENTOS', departamentos.map(dpto => adapter.toStore(dpto)))
      return departamentos
    },
    async findAllByUsuarioId({ commit }, usuarioId) {
      const departamentos = await DepartamentoService.findAllByUsuarioId(usuarioId)
      if (departamentos.length > 0) {
        commit('UPDATE_DEPARTAMENTOS', departamentos.map(dpto => adapter.toStore(dpto)))
      } else {
        commit('UPDATE_DEPARTAMENTOS', [{ nomeDepartamento: 'Sem Departamento' }])
      }
      return departamentos
    },
    async findById({ commit }, departamentoId) {
      const departamento = await DepartamentoService.findById(departamentoId)
      commit('UPDATE_DEPARTAMENTO', adapter.toStore(departamento))
      return departamento
    },
    setOptions({ commit }, departamentos) {
      const options = departamentos.map(departamento => ({
        label: departamento.nomeDepartamento,
        value: departamento.clienteDepartamentoId,
      }))
      commit('UPDATE_DEPARTAMENTOS_OPTIONS', options)
    },
  },
}
