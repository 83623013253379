<template>
  <div class="list-box-wrapper">
    <v-row>
      <v-col
        cols="12"
        sm="5"
      >
        <div class="list-box-item">
          <div class="title-box">
            <span class="top-title">{{ titleSource.toUpperCase() }}</span>
          </div>
          <div class="search-box">
            <FormText
              v-model="searchSource"
              :label="sourceSearchLabel"
            />
          </div>
          <ul class="list-box">
            <li
              v-for="(item, key) in source
                .map((item, inx) => ({ inx, ...item }))
                .filter((item) =>
                  item[label in item ? label : 'label']
                    .toLowerCase()
                    .includes(searchSource.toLowerCase())
                )"
              :key="key"
              :class="'list-item' + (item.selected ? ' active' : '')"
              @click="!disabled && selectSource(searchSource ? item.inx : key)"
            >^
              {{ item[label in item ? label : "label"] }}
            </li>
            <li
              v-if="
                source.filter((item) =>
                  item[label in item ? label : 'label']
                    .toLowerCase()
                    .includes(searchSource.toLowerCase())
                ).length == 0 && source.length
              "
              class="list-item"
            >
              No results found
            </li>
          </ul>
          <div class="bulk-action">
            <div
              class="select-all"
              @click="!disabled && selectAllSource"
            >
              Selecionar Todos
            </div>
            <div
              class="deselect-all"
              @click="!disabled && deSelectAllSource"
            >
              Selecionar Nenhum
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        style="btn-col"
        cols="12"
        sm="2"
      >
        <div class="actions">
          <div
            class="btn-action"
            @click="!disabled && moveDestination"
          >
            <v-icon color="white">
              mdi-plus
            </v-icon>
          </div>
          <div
            class="btn-action"
            @click="!disabled && moveSource"
          >
            <v-icon color="white">
              mdi-minus
            </v-icon>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="5"
      >
        <div class="list-box-item">
          <div class="title-box">
            <span class="top-title">{{ titleDestination.toUpperCase() }}</span>
          </div>
          <div class="search-box">
            <FormText
              v-model="searchDestination"
              :label="destinationSearchLabel"
            />
          </div>
          <ul class="list-box">
            <li
              v-for="(item, key) in destination
                .map((item, inx) => ({ inx, ...item }))
                .filter((item) =>
                  item[label in item ? label : 'label']
                    .toLowerCase()
                    .includes(searchDestination.toLowerCase())
                )"
              :key="key"
              :class="'list-item' + (item.selected ? ' active' : '')"
              @click="!disabled && selectDestination(searchDestination ? item.inx : key)"
            >
              {{ item[label in item ? label : "label"] }}
            </li>
            <li
              v-if="
                destination.filter((item) =>
                  item[label in item ? label : 'label']
                    .toLowerCase()
                    .includes(searchDestination.toLowerCase())
                ).length == 0 && destination.length
              "
              class="list-item"
            >
              No results found
            </li>
          </ul>
          <div class="bulk-action">
            <div
              class="select-all"
              @click="!disabled && selectAllDestination"
            >
              Selecionar Todos
            </div>
            <div
              class="deselect-all"
              @click="!disabled && deSelectAllDestination"
            >
              Selecionar Nenhum
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import './index.styles.scss'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    source: Array,
    // eslint-disable-next-line vue/require-default-prop
    destination: Array,
    // eslint-disable-next-line vue/require-default-prop
    label: String,
    // eslint-disable-next-line vue/require-default-prop
    titleDestination: String,
    // eslint-disable-next-line vue/require-default-prop
    titleSource: String,
    // eslint-disable-next-line vue/require-default-prop
    sourceSearchLabel: String,
    // eslint-disable-next-line vue/require-default-prop
    destinationSearchLabel: String,
    disabled: Boolean,
  },
  data() {
    return {
      searchSource: '',
      searchDestination: '',
    }
  },
  methods: {
    moveDestination() {
      let selected = this.source.filter(f => f.selected)
      if (!selected.length) return
      selected = selected.map(item => ({
        ...item,
        selected: false,
      }))
      const destination = [...selected, ...this.destination]
      const source = this.source.filter(f => !f.selected)
      this.searchSource = ''
      this.searchDestination = ''
      this.$emit('onChangeList', {
        source,
        destination,
      })
    },
    moveSource() {
      let selected = this.destination.filter(f => f.selected)
      if (!selected.length) return
      selected = selected.map(item => ({
        ...item,
        selected: false,
      }))
      const source = [...selected, ...this.source]
      const destination = this.destination.filter(f => !f.selected)
      this.searchSource = ''
      this.searchDestination = ''
      this.$emit('onChangeList', {
        source,
        destination,
      })
    },
    moveAllDestination() {
      const destination = [
        ...this.source.map(item => ({ ...item, selected: false })),
        ...this.destination,
      ]
      const source = []
      this.searchSource = ''
      this.searchDestination = ''
      this.$emit('onChangeList', {
        source,
        destination,
      })
    },
    moveAllSource() {
      const source = [
        ...this.destination.map(item => ({ ...item, selected: false })),
        ...this.source,
      ]
      const destination = []
      this.searchSource = ''
      this.searchDestination = ''
      this.$emit('onChangeList', {
        source,
        destination,
      })
    },
    selectDestination(key) {
      const { source } = this
      const destination = this.destination.map((i, k) => {
        if (k === key) {
          i.selected = !i.selected
        }
        return i
      })
      this.$emit('onChangeList', {
        source,
        destination,
      })
    },
    selectSource(key) {
      const { destination } = this
      const source = this.source.map((i, k) => {
        if (k === key) {
          i.selected = !i.selected
        }
        return i
      })
      this.$emit('onChangeList', {
        source,
        destination,
      })
    },
    selectAllSource() {
      const source = this.source.map(item => ({ ...item, selected: true }))
      const { destination } = this
      this.$emit('onChangeList', {
        source,
        destination,
      })
    },
    deSelectAllSource() {
      const source = this.source.map(item => ({ ...item, selected: false }))
      const { destination } = this
      this.$emit('onChangeList', {
        source,
        destination,
      })
    },
    selectAllDestination() {
      const destination = this.destination.map(item => ({
        ...item,
        selected: true,
      }))
      const { source } = this
      this.$emit('onChangeList', {
        source,
        destination,
      })
    },
    deSelectAllDestination() {
      const destination = this.destination.map(item => ({
        ...item,
        selected: false,
      }))
      const { source } = this
      this.$emit('onChangeList', {
        source,
        destination,
      })
    },
  },
}
</script>
<style scoped>
</style>