// import httpClient from './http-clients/supertubos'
import httpClient from './http-clients/portaljad'

const baseUrl = 'cotacao'

export default class CotacaoFrete {
  static async cotarFrete(dados) {
    try {
      const data = await httpClient.post([baseUrl, dados.clienteId].join('/'), dados.obj)
      return data
    } catch (error) {
      throw error.response.data
    }
  }
}