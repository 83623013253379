<template>
  <b-card
    no-body
    class="mb-2"
  >
    <b-card-header class="p-2">
      <b-card-title class="font-medium-2 card-title">
        <feather-icon
          icon="HomeIcon"
          size="18"
        />
        <span class="align-middle ml-50">
          Destinatário
        </span>
      </b-card-title>
    </b-card-header>
    <hr class="my-0">
    <div class="p-2">
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <validation-provider
            v-slot="validationContext"
            name="enderecoReversa"
          >
            <b-form-group
              label="Endereco Reversa"
              label-for="enderecoReversa"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="enderecoReversa"
                :reduce="val=>val.value"
                :options="enderecoReversaOptions"
                :clearable="false"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col 
          v-if="mostrarDepartamento"
          cols="12"
        >
          <validation-provider
            #default="validationContext"
            name="Departamento"
            :rules="{ required: temDepartamento }"
          >
            <b-form-group
              label="Departamento"
              label-for="departamento"
            >
              <v-select
                id="departamento"
                v-model="destinatario.clienteDepartamentoId"
                :options="selectDepartamentos"
                :reduce="val=>val.value"
                :clearable="false"
                @input="(value) => atualizaEndereco(value)"
              />
              <span class="undermessage-error">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required"
          >
            <b-form-group
              label="Nome *"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="destinatario.nomeFantasia"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Destinatário"
                disabled
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="destinatario.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="destinatario@exemplo.com"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Celular"
            rules="phone"
          >
            <b-form-group
              label="Celular"
              label-for="celular"
            >
              <b-form-input
                id="celular"
                v-model="destinatario.telefone"
                v-cleave="cleaveOptions.phone"
                trim
                placeholder="(11) 00000-0000"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="CEP"
            rules="required"
          >
            <b-form-group
              label="CEP *"
              label-for="cep"
            >
              <b-form-input
                id="cep"
                v-model="destinatario.cep"
                v-cleave="cleaveOptions.cep"
                trim
                placeholder="00000-000"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="UF"
            rules="required"
          >
            <b-form-group
              label="UF *"
              label-for="uf"
            >
              <b-form-input
                id="uf"
                v-model="destinatario.uf"
                :state="getValidationState(validationContext)"
                trim
                placeholder="SP"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Cidade"
            rules="required"
          >
            <b-form-group
              label="Cidade *"
              label-for="cidade"
            >
              <b-form-input
                id="cidade"
                v-model="destinatario.cidade"
                :state="getValidationState(validationContext)"
                trim
                placeholder="São Paulo"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Bairro"
            rules="required"
          >
            <b-form-group
              label="Bairro *"
              label-for="bairro"
            >
              <b-form-input
                id="bairro"
                v-model="destinatario.bairro"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Centro"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Logradouro"
            rules="required"
          >
            <b-form-group
              label="Logradouro *"
              label-for="logradouro"
            >
              <b-form-input
                id="logradouro"
                v-model="destinatario.logradouro"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Av. Brasil"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Numero"
            rules="required"
          >
            <b-form-group
              label="Número *"
              label-for="numero"
            >
              <b-form-input
                id="numero"
                v-model="destinatario.numero"
                :state="getValidationState(validationContext)"
                trim
                placeholder="110"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Complemento"
          >
            <b-form-group
              label="Complemento"
              label-for="complemento"
            >
              <b-form-input
                id="complemento"
                v-model="destinatario.complemento"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Sala 220"
                disabled
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </div>
    <hr class="my-0">
    <div class="p-2">
      <b-row class="flex-row-reverse">
        <div
          style="display:flex; align-items: center; justify-content: center"
        >
          <b-button
            variant="primary"
            class="mb-sm-0 mr-0 mr-sm-1"
            type="submit"
          >
            Próximo
            <feather-icon
              icon="ArrowRightIcon"
            />
          </b-button>
        </div>
        <div
          style="display:flex; align-items: center; justify-content: center"
        />
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { watch, reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { mapGetters } from 'vuex'

import cleaveOptions from '@/libs/cleave/options'

export default {
  name: 'Destinatario',
  props: {
    getValidationState: {
      type: Function,
      required: true,
    },
    destinatario: {
      type: undefined,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      firebaseUser: 'firebase/firebaseUser',
    }),
  },

  beforeMount() {
    const user = JSON.parse(localStorage.getItem('userName'))
    this.$store.dispatch('departamento/findAllByUsuarioId', user.usuarioId)
    this.$store.dispatch('cliente/findById', user.clienteId)
    this.$store.dispatch('enderecoReversa/findAll', user.clienteId)
  },

  setup(props, { emit }) {
    const clienteStore = createNamespacedHelpers('cliente')
    const enderecoReversaStore = createNamespacedHelpers('enderecoReversa')
    const findClienteById = clienteStore.useActions(['findById']).findById
    const departamentoStore = createNamespacedHelpers('departamento')
    const findDepartamentoById = departamentoStore.useActions(['findById']).findById
    const { findById } = enderecoReversaStore.useActions(['findById'])

    const state = reactive({
      clienteOptions: clienteStore.useGetters(['cliente']).cliente,
      departamentoOptions: departamentoStore.useGetters(['departamentos']).departamentos,
      departamento: departamentoStore.useGetters(['departamento']).departamento,
      cliente: clienteStore.useGetters(['cliente']).cliente,
      selectDepartamentos: [],
      temDepartamento: false,
      mostrarDepartamento: false,
      enderecoReversaOptions: enderecoReversaStore.useGetters(['getEnderecoReversaOptions']).getEnderecoReversaOptions,
      enderecoReversa: null,
    })

    const destinatarioPadrao = option => {
      state.enderecoReversa = 0
      emit('update:destinatario', option)
    }

    watch(() => state.clienteOptions, value => {
      if (value.enderecoReversa !== 0) {
        const clienteEndRev = value.enderecoReversa
        const enderecoReversaId = state.enderecoReversaOptions.find(end => end.value === clienteEndRev)
        if (enderecoReversaId) {
          state.enderecoReversa = enderecoReversaId.value
          findById(enderecoReversaId.value).then(response => {
            emit('update:destinatario', response)
          })
        } else {
          destinatarioPadrao(state.clienteOptions)
        }
      } else {
        destinatarioPadrao(state.clienteOptions)
      }
    })

    watch(() => state.enderecoReversa, value => {
      if (value) {
        findById(value).then(response => {
          emit('update:destinatario', response)
        })
      } else {
        emit('update:destinatario', state.clienteOptions)
      }
    })

    const atualizaEndereco = value => {
      if (value) {
        findDepartamentoById(value)
          .then(departamento => {
            props.destinatario.nomeDepartamento = departamento.nomeDepartamento
            if (departamento.temEndereco) {
              props.destinatario.cep = departamento.cep
              props.destinatario.uf = departamento.uf
              props.destinatario.cidade = departamento.cidade
              props.destinatario.bairro = departamento.bairro
              props.destinatario.logradouro = departamento.logradouro
              props.destinatario.numero = departamento.numero
              props.destinatario.complemento = departamento.complemento
            } else {
              const user = JSON.parse(localStorage.getItem('userName'))
              findClienteById(user.clienteId)
                .then(cliente => {
                  props.destinatario.cep = cliente.cep
                  props.destinatario.uf = cliente.uf
                  props.destinatario.cidade = cliente.cidade
                  props.destinatario.bairro = cliente.bairro
                  props.destinatario.logradouro = cliente.logradouro
                  props.destinatario.numero = cliente.numero
                  props.destinatario.complemento = cliente.complemento
                })
                .finally(() => {
                  props.destinatario.clienteDepartamentoId = value
                })
            }
          })
      }
    }

    return {
      ...toRefs(state),
      cleaveOptions,
      atualizaEndereco,
    }
  },
}
</script>

<style scoped>

</style>