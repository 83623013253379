<template>
  <b-card-text>
    <h4 class="my-2">
      Destinatário
    </h4>
    <hr class="my-0">
    <div class="mx-1 pt-2">
      <b-row class="justify-content-between pb-1">
        <span class="font-weight-bold">
          Tipo de Documento:
        </span>
        <span>
          {{ destinatario.tipoDocumento }}
        </span>
      </b-row>
      <b-row class="justify-content-between pb-1">
        <span class="font-weight-bold">
          Documento:
        </span>
        <span>
          {{ destinatario.tipoDocumento == 'CPF' ? maskCpf(destinatario.numeroDocumento) : maskCnpj(destinatario.numeroDocumento) }} 
        </span>
      </b-row>
      <b-row class="justify-content-between pb-1">
        <span class="font-weight-bold">
          Email:
        </span>
        <span>
          {{ destinatario.email }}
        </span>
      </b-row>
      <b-row class="justify-content-between pb-1">
        <span class="font-weight-bold">
          Telefone:
        </span>
        <span>
          {{ destinatario.telefone ? maskPhone(destinatario.telefone) : '' }}
        </span>
      </b-row>
      <b-row class="justify-content-between pb-1">
        <span class="font-weight-bold">CEP:</span>
        <span>{{ destinatario.cep ? maskCep(destinatario.cep) : '' }}</span>
      </b-row>
      <b-row class="justify-content-between pb-1">
        <span class="font-weight-bold">UF:</span>
        <span>{{ destinatario.uf }}</span>
      </b-row>
      <b-row class="justify-content-between pb-1">
        <span class="font-weight-bold">Cidade:</span>
        <span>{{ destinatario.cidade }}</span>
      </b-row>
      <b-row class="justify-content-between pb-1">
        <span class="font-weight-bold">
          Bairro:
        </span>
        <span>
          {{ destinatario.bairro }}
        </span>
      </b-row>
      <b-row class="justify-content-between pb-1">
        <span class="font-weight-bold">
          Logradouro:
        </span>
        <span>
          {{ destinatario.logradouro ? destinatario.logradouro : destinatario.endereco }}
        </span>
      </b-row>
      <b-row class="justify-content-between pb-1">
        <span class="font-weight-bold">
          Número:
        </span>
        <span>
          {{ destinatario.numero }}
        </span>
      </b-row>
      <b-row
        class="justify-content-between pb-1"
      >
        <span class="font-weight-bold">
          Complemento:
        </span>
        <span>
          {{ destinatario.complemento }}
        </span>
      </b-row>
    </div>
  </b-card-text>
</template>
<script>

import utils from '@/helpers/utils'

export default {
  name: 'DestinatarioComponent',
  props: {
    destinatario: { 
      required: true,
      type: Object,
    },
  },
  setup() {
    return { ...utils }
  },
}
</script>