<template>
  <b-sidebar
    id="add-new-integracao-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetFormIntegracao"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar integração
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          ref="formIntegracao"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Integração"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Integração"
              label-for="integracao"
            >
              <v-select
                id="integracao"
                v-model="loja.integracaoId"
                :reduce="val=>val.value"
                :clearable="false"
                :options="integracoesOptions"
              />
              <span class="undermessage-error text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
          <div v-if="loja.integracaoId === 1">
            <validation-provider
              #default="validationContext"
              name="Nome da Loja"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Nome da Loja"
                label-for="nome"
              >
                <b-form-input
                  id="nome"
                  v-model="loja.nome"
                  :state="getValidationState(validationContext)"
                  trim
                  maxlength="150"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Token / Chave API"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Token / Chave API"
                label-for="token"
              >
                <b-form-textarea
                  id="token"
                  v-model="loja.token"
                  :state="getValidationState(validationContext)"
                  trim
                  rows="3"
                  maxlength="255"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ loja.integracaoId != 2 ? 'Adicionar' : 'Acessar URL' }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'IndexVue',
  emits: ['aoSalvar'],
  props: { active: { required: true, type: Boolean } },
  beforeMount() {
    this.$store.dispatch('integracao/findAll')
  },
  setup(_, { emit }) {
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const blankLoja = {
      clienteId,
      integracaoId: null,
      nome: null,
      token: null,
    }

    const lojaStore = createNamespacedHelpers('loja')
    const integracaoStore = createNamespacedHelpers('integracao')

    const { save } = lojaStore.useActions(['save'])

    const state = reactive({
      loja: { ...blankLoja },
      integracoesOptions: integracaoStore.useGetters(['integracoesOptions']).integracoesOptions,
      integracoes: integracaoStore.useGetters(['integracoes']).integracoes,
    })

    const resetRegiao = () => {
      state.loja = { ...blankLoja }
    }

    const integracaoBlingV3Url = data => {
      const integracao = state.integracoes.find(i => i.integracaoId === data)
      window.location.href = integracao.urlExterna
    }

    const { appCarregando } = $themeConfig.app

    const { getValidationState, resetForm, refFormObserver } = formValidation(resetRegiao)

    const onSubmit = () => {
      const intId = state.loja.integracaoId
      if (intId === 1) {
        appCarregando.show()
        save(state.loja).then(response => {
          appCarregando.hide()
          emit('update:active', false)
          emit('aoSalvar')
          if (!response.success) {
            swal.fire({
              icon: 'info',
              html: `<h1>${response.body}</h1>`,
              showConfirmButton: true,
            })
          } else {
            Vue.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Integração incluída com sucesso!',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              },
              {
                timeout: 5000,
                draggable: true,
                hideProgressBar: false,
              },
            )
          }
        })
      }
      if (intId === 2) {
        integracaoBlingV3Url(intId)
      }
    }

    return {
      ...toRefs(state),
      getValidationState,
      resetForm,
      refFormObserver,
      onSubmit,
      resetRegiao,
    }
  },
  methods: {
    resetFormIntegracao() {
      this.$refs.formIntegracao.reset()
    },
  },
}
</script>

<style scoped>

</style>