import LayoutParametroService from '@/service/LayoutParametroService'

export default {
  namespaced: true,
  state: {
    layoutParametros: [],
  },
  
  getters: {
    layoutParametros(state) {
      return state.layoutParametros
    },
  },
  mutations: {
    SET_LAYOUT_PARAMETROS(state, layoutParametros) {
      state.layoutParametros = layoutParametros
    },
    SET_LAYOUT_PARAMETROS_ADD_OPTIONS(state, options) {
      state.layoutParametros.forEach(layoutParametro => {
        const optionsCopia = []
        options.forEach(option => {
          optionsCopia.push({
            label: option.descricao,
            dados: {
              label: option.descricao,
              coluna: option.coluna,
              nomeParametro: layoutParametro.nomeParametro,
              descricao: option.descricao,
              descricaoColuna: layoutParametro.label,
              resultadoColuna: option.resultadoColuna,
            },
          })
        })
        layoutParametro.options = optionsCopia
      })
    },
  },
  actions: {
    async findAll({ commit }) {
      const layoutParametros = await LayoutParametroService.findAll()
      commit('SET_LAYOUT_PARAMETROS', layoutParametros)
      return layoutParametros
    },
    async findAllReversa({ commit }) {
      const layoutParametros = await LayoutParametroService.findAllReversa()
      commit('SET_LAYOUT_PARAMETROS', layoutParametros)
    },
    addOptions({ commit }, options) {
      commit('SET_LAYOUT_PARAMETROS_ADD_OPTIONS', options)
    },
  },
}
