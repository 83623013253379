import httpClient from './http-clients/portaljad'

const baseUrl = 'tratativa'

export default class TratativaService {
  static async save(tratativa) {
    try {
      const data = await httpClient.post(baseUrl, tratativa)
      return data
    } catch (error) {
      throw error.data
    }
  }

  static async findAll(filtros) {
    try {
      const { data } = await httpClient.get(baseUrl, filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAllEntregas(filtros) {
    try {
      const { data } = await httpClient.post([baseUrl, 'entrega'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(tratativaId) {
    try {
      const { data } = await httpClient.get([baseUrl, tratativaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async edit(tratativaId, tratativa) {
    try {
      const { data } = await httpClient.put([baseUrl, tratativaId].join('/'), tratativa)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async deleteById(tratativaId) {
    try {
      await httpClient.delete([baseUrl, tratativaId].join('/'))
      return true
    } catch (error) {
      throw error.response.data
    }
  }
}