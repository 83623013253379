<template>
  <div class="mb-5">
    <b-card
      no-body
      class="mb-2"
    >
      <validation-observer
        #default="context"
        ref="validationCep"
      >
        <b-form @submit.prevent="context.handleSubmit(onSubmit)">
          <b-card-header class="p-2">
            <b-card-title class="font-medium-2 card-title">
              <feather-icon
                icon="TruckIcon"
                size="18"
              />
              <span class="align-middle ml-50"> Cotação de frete </span>
            </b-card-title>
          </b-card-header>
          <hr class="my-0">
          <div class="p-2">
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Cep Origem"
                  rules="noAlpha|required"
                >
                  <b-form-group
                    label="Cep Origem"
                    label-for="cepOrigem"
                  >
                    <b-form-input
                      id="cepOrigem"
                      v-model="cepOrigem"
                      readonly
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Cep Destino"
                  rules="noAlpha|required|cep"
                >
                  <b-form-group
                    label="Cep Destino"
                    label-for="cepDestino"
                  >
                    <b-form-input
                      id="cepDestino"
                      v-model="cepDestino"
                      v-cleave="cleaveOptions.cep"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="00000-000"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  v-model="valorDeclarado"
                  name="Valor Declarado"
                  rules="required"
                >
                  <b-form-group
                    label="Valor Declarado"
                    label-for="valorDeclarado"
                  >
                    <CurrencyInput
                      v-model="valorDeclarado"
                      :validation-context="validationContext"
                      :show-symbol="true"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
      <hr class="my-0">
      <b-row>
        <b-col md="9">
          <h3 class="p-2">
            Volumes
          </h3>
        </b-col>
        <b-col md="3">
          <h3 class="p-2">
            <b-form-checkbox
              id="manterPeso"
              v-model="manterPeso"
            >
              Manter peso
            </b-form-checkbox>
            <b-form-checkbox
              id="manterMedidas"
              v-model="manterMedidas"
            >
              Manter medidas
            </b-form-checkbox>
          </h3>
        </b-col>
      </b-row>
      <b-card
        no-body
        class="mb-2"
        style="padding-left: 28px; padding-right: 14px"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="validationVolume"
        >
          <b-form @submit.prevent="handleSubmit(addVolume)">
            <b-row>
              <b-col
                cols="12"
                lg="2"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Peso"
                  rules="required"
                >
                  <b-form-group
                    label="Peso(kg)"
                    label-for="peso"
                  >
                    <CurrencyInput
                      v-model="volume.pesoEmQuilo"
                      :show-symbol="false"
                      :required="true"
                      :validation-context="validationContext"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                lg="2"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Formato"
                >
                  <b-form-group
                    label="Formato"
                    label-for="formato"
                  >
                    <v-select
                      v-model="volume.formato"
                      :options="formatoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <template v-if="volume.formato === 'PACOTE' || volume.formato === 'ENVELOPE'">
                <b-col
                  cols="12"
                  lg="2"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Altura"
                    rules="integer|required"
                  >
                    <b-form-group
                      label="Altura(cm)"
                      label-for="altura"
                    >
                      <b-form-input
                        id="altura"
                        v-model="volume.altura"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="0"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </template>
              <template
                v-if="
                  volume.formato === 'PACOTE' || volume.formato === 'ENVELOPE'
                "
              >
                <b-col
                  cols="12"
                  lg="2"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Largura"
                    rules="integer|required"
                  >
                    <b-form-group
                      label="Largura(cm)"
                      label-for="largura"
                    >
                      <b-form-input
                        id="largura"
                        v-model="volume.largura"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="0"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </template>
              <b-col
                cols="12"
                lg="2"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Comprimento"
                  rules="integer|required"
                >
                  <b-form-group
                    label="Comprimento(cm)"
                    label-for="comprimento"
                  >
                    <b-form-input
                      id="comprimento"
                      v-model="volume.comprimento"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="0"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <template v-if="volume.formato === 'CILINDRO'">
                <b-col
                  cols="12"
                  lg="2"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Diâmetro"
                    rules="integer|required"
                  >
                    <b-form-group
                      label="Diâmetro(cm)"
                      label-for="diametro"
                    >
                      <b-form-input
                        id="diametro"
                        v-model="volume.diametro"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="0"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </template>
              <b-col
                cols="12"
                lg="2"
                md="4"
              >
                <div class="pt-2">
                  <b-button
                    variant="primary"
                    class="w-100 d-block"
                    type="submit"
                    style="max-height: 38px"
                  >
                    Adicionar
                    <feather-icon
                      class="ml-1 d-md-none d-xl-inline d-inline"
                      icon="PlusIcon"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>

          <b-table
            ref="refVolumesTable"
            class="position-relative"
            :items="volumes"
            :fields="volumesTableColumns"
            show-empty
            stacked="md"
            empty-text="Não há nenhum volume declarado."
            responsive
          >
            <template #cell(excluir)="data">
              <a @click="removeValue(data)">
                <feather-icon
                  class="text-danger"
                  icon="TrashIcon"
                />
              </a>
            </template>
          </b-table>
          <hr class="my-0">
          <div class="p-2">
            <b-row class="flex-row-reverse">
              <div style="display: flex; align-items :center ; justify-content: center">
                <b-button
                  variant="primary"
                  class="mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="validationRefs()"
                >
                  Consultar Frete
                  <feather-icon icon="CheckIcon" />
                </b-button>
              </div>
              <b-col v-if="showCotacaoTable">
                <span class="text-danger">*O preço desta pesquisa é meramente informativo, devendo ser confirmado no ato da postagem.</span>
              </b-col>
            </b-row>
          </div>
        </validation-observer>
      </b-card>
    </b-card>
    <template v-if="showCotacaoTable">
      <b-table
        ref="refCotacaoTable"
        responsive
        class="position-relative pb-3"
        :items="cotacoes"
        :fields="freteApi ? cotacaoTableColumnsFreteApi: cotacaoTableColumns"
        show-empty
        stacked="md"
        empty-text="Não há nenhum volume declarado."
      >
        <template #cell(prazoDiasUteis)="data">
          <span>{{ data.item.prazoDiasUteis + ' Dias' }}</span>
        </template>
        <template #cell(pesoFisico)="data">
          <span>{{ data.item.pesoFisico ? `${maskDecimal(Number(Number(data.item.pesoFisico)/1000).toFixed(2))}` : '' }}</span>
        </template>
        <template #cell(valor)="data">
          <span>{{ data.item.valor ? maskCurrency(data.item.valor) : '' }}</span>
        </template>
        <template #cell(pesoCubico)="data">
          <span>{{ data.item.pesoCubico ? `${maskDecimal(Number(Number(data.item.pesoCubico)/1000).toFixed(2))}` : '' }}</span>
        </template>
        <template #cell(pesoFinal)="data">
          <span>{{ data.item.pesoFinal ? `${maskDecimal(Number(Number(data.item.pesoFinal)/1000).toFixed(2))}` : '' }}</span>
        </template>
        <template #cell(valorCubico)="data">
          <span>{{ data.item.valorCubico ? maskCurrency(data.item.valorCubico) : '' }}</span>
        </template>
        <template #cell(nomeServico)="data">
          {{ serviceDictionary(data.item.nomeServico) }}
        </template>
        <template #cell(valorSeguro)="data">
          <span>{{ maskCurrency(data.item.valorSeguro ? data.item.valorSeguro : 0) }}</span>
        </template>
        <template #cell(valorTotal)="data">
          <span>{{ data.item.valorTotal ? maskCurrency(data.item.valorTotal) : '' }}</span>
        </template>
        <template #cell(gerar)="">
          <b-button
            variant="link"
            @click="direcionarParaGerarEtiquetas()"
          >
            <feather-icon
              icon="FileIcon"
              size="18"
            />
          </b-button>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
import { watch, reactive, ref, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import store from '@/store'
import cleaveOptions from '@/libs/cleave/options'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import utils from '@/helpers/utils'
import CurrencyInput from '@/components/form/CurrencyInput'
import swal from 'sweetalert2/dist/sweetalert2.min'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'CotacaoFrete',
  components: { CurrencyInput },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'geracao-etiquetas') {
      localStorage.removeItem('Volumes')
    }
    next()
  },
  beforeMount() {
    const user = JSON.parse(localStorage.getItem('userName'))
    this.$store.dispatch('cliente/findById', user.clienteId)
  },

  mounted() {
    this.$refs.validationCep.reset()
    this.$refs.validationVolume.reset()
    localStorage.removeItem('Volumes')
  },
  methods: {
    direcionarParaGerarEtiquetas() {
      localStorage.setItem('Volumes', JSON.stringify(this.objetoCotacao.obj.volumes))
      this.$router.push('/envios/etiquetas/etq-manual')
      // localStorage.setItem('DadosFrete', JSON.stringify(data.item))
    },
  },

  setup() {
    const { appCarregando } = $themeConfig.app
    const clienteStore = createNamespacedHelpers('cliente')
    const cotacaoStore = createNamespacedHelpers('cotacao')
    const validationCep = ref(null)
    const pesoInput = ref(null)
    const validationVolume = ref(null)
    const refCotacaoTable = ref(null)
    const showCotacaoTable = ref(false)
    const manterMedidas = ref(true)
    const manterPeso = ref(true)

    const state = reactive({
      cliente: clienteStore.useGetters(['cliente']).cliente,
      cotacaoStore: cotacaoStore.useGetters(['cotacao']).cotacao,
      freteApi: false,
      cotacoes: null,
      volumes: [],
      cepOrigem: ref(null),
      cepDestino: ref(null),
      valorDeclarado: null,
      volume: { formato: 'PACOTE' },
      objetoCotacao: null,
    })

    watch(() => state.volume.formato, formato => {
      state.volume.altura = formato !== 'CILINDRO' ? state.volume.altura : null
      state.volume.largura = formato !== 'CILINDRO' ? state.volume.largura : null
      state.volume.diametro = formato === 'CILINDRO' ? state.volume.diametro : null
    })

    const resetVolume = () => {
      const volume = { ...state.volume }
      validationVolume.value.reset()

      if (!manterPeso.value && !manterMedidas.value) {
        state.volume = { formato: volume.formato }
      } else if (manterPeso.value && !manterMedidas.value) {
        state.volume = {
          formato: volume.formato,
          peso: volume.peso,
        }
      } else if (!manterPeso.value && manterMedidas.value) {
        state.volume = {
          formato: volume.formato,
          altura: volume.formato !== 'CILINDRO' ? volume.altura : null,
          largura: volume.formato !== 'CILINDRO' ? volume.largura : null,
          diametro: volume.formato === 'CILINDRO' ? volume.diametro : null,
          comprimento: volume.comprimento,
        }
      }
    }

    watch(() => state.cliente, value => {
      state.freteApi = value.freteApi
      state.cepOrigem = utils.maskCep(value.cep)
    })

    const addVolume = () => {
      state.volumes.push({ ...state.volume })
      resetVolume()
    }

    const sendRequest = objetoCotacao => {
      state.cotacoes = null
      appCarregando.show()
      store.dispatch('cotacao/cotarFrete', objetoCotacao)
        .then(response => {
          appCarregando.hide()
          if (response) {
            const { data } = response
            if (data.success) {
              if (data.body[0].erroMsg === null) {
                state.cotacoes = data.body
                showCotacaoTable.value = true
              } else {
                swal.fire({
                  icon: 'error',
                  html: `<h3>${data.body[0].erroMsg}</h3>`,
                  showConfirmButton: false,
                  timer: 3000,
                })
              }
            } else {
              swal.fire({
                icon: 'warning',
                html: `<h3>${data.body}</h3>`,
                showConfirmButton: false,
                timer: 3000,
              })
            }
          } else {
            swal.fire({
              icon: 'error',
              text: 'Erro ao cotar frete.',
              showConfirmButton: false,
              timer: 3000,
            })
          }
        })
    }

    const onSubmit = () => {
      if (state.volumes.length > 0) {
        const objetoCotacao = {
          clienteId: state.cliente.clienteId,
          obj: {
            cepOrigem: state.cepOrigem.replaceAll(/[.,()/\s-]+/g, ''),
            cepDestino: state.cepDestino.replaceAll(/[.,()/\s-]+/g, ''),
            valorDeclarado: state.valorDeclarado,
            volumes: state.volumes,
          },
        }
        state.objetoCotacao = objetoCotacao
        sendRequest(objetoCotacao)
      } else {
        swal.fire({
          icon: 'warning',
          html: '<h3>Adicione ao menos um volume</h3>',
          showConfirmButton: false,
          timer: 3000,
        })
      }
    }

    const validationRefs = () => {
      validationCep.value.handleSubmit(onSubmit)
    }

    const removeValue = data => {
      state.volumes.splice(data.index, 1)
    }

    const reset = () => {
      state.cepOrigem = null
      state.cepDestino = null
      state.valorDeclarado = null

      state.volumes = []
      state.volume = {}
    }

    const volumesTableColumns = [
      { key: 'pesoEmQuilo', sortable: true, label: 'peso(kg)' },
      { key: 'formato', sortable: true },
      { key: 'altura', sortable: true },
      { key: 'largura', sortable: true },
      { key: 'comprimento', sortable: true },
      { key: 'diametro', sortable: true, label: 'diâmetro' },
      { key: 'excluir', sortable: false },
    ]

    const formatoOptions = [
      { label: 'PACOTE', value: 'PACOTE' },
      { label: 'CILINDRO', value: 'CILINDRO' },
      { label: 'ENVELOPE', value: 'ENVELOPE' },
    ]

    const cotacaoTableColumns = [
      { key: 'nomeServico', sortable: true, label: 'serviço', align: 'center' },
      { key: 'prazoDiasUteis', sortable: true, label: 'PRAZO' },
      { key: 'pesoFinal', sortable: true, label: 'peso(kg)' },
      { key: 'valorSeguro', sortable: true },
      { key: 'valorTotal', sortable: true },
      { key: 'gerar', sortable: false, label: 'Gerar Etiqueta' },
    ]

    const cotacaoTableColumnsFreteApi = [
      { key: 'nomeServico', sortable: true, label: 'serviço', align: 'center' },
      { key: 'prazoDiasUteis', sortable: true, label: 'PRAZO' },
      { key: 'pesoFinal', sortable: true, label: 'peso(kg)' },
      { key: 'valorTotal', sortable: true },
      { key: 'gerar', sortable: false, label: 'Gerar Etiqueta' },
    ]

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      reset,
    )
    return {
      validationCep,
      pesoInput,
      cleaveOptions,
      refFormObserver,
      getValidationState,
      resetForm,
      ...toRefs(state),
      formatoOptions,
      manterMedidas,
      manterPeso,
      addVolume,
      volumesTableColumns,
      showCotacaoTable,
      cotacaoTableColumns,
      cotacaoTableColumnsFreteApi,
      onSubmit,
      validationRefs,
      validationVolume,
      refCotacaoTable,
      removeValue,
      cotacaoStore,
      ...utils,
    }
  },
}
</script>
