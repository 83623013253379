<template>
  <b-card
    no-body
    class="mb-2"
  >
    <b-card-header class="p-2">
      <b-card-title class="font-medium-2 card-title">
        <feather-icon
          icon="MapPinIcon"
          size="18"
        />
        <span class="align-middle ml-50">
          Remetente
        </span>
      </b-card-title>
    </b-card-header>
    <hr class="my-0">
    <div class="p-2">

      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required|max:80"
          >
            <b-form-group
              label="Nome *"
              label-for="remetente-nome"
            >
              <vue-autosuggest
                :value="remetente.nome"
                :suggestions="suggestions"
                :input-props="inputProps"
                :get-suggestion-value="getSuggestionValue"
                @selected="onSelected"
                @input="onInputChange"
              >
                <template slot-scope="{suggestion}">
                  <div class="d-flex align-items-center">
                    <div class="detail ml-50">
                      <b-card-text class="mb-0">
                        {{ suggestion.item.nome }}
                      </b-card-text>
                      <small class="text-muted">
                        {{
                          suggestion.item.cidade + ' - ' +
                            suggestion.item.logradouro + ' - ' +
                            suggestion.item.numero
                        }}
                      </small>
                    </div>
                  </div>
                </template>
              </vue-autosuggest>
              <span class="undermessage-error">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            v-slot="validationContext"
            name="Tipo de Documento"
            rules="required"
          >
            <b-form-group
              label="Tipo de Documento *"
              label-for="tipoDocumento"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="remetente.tipoDocumento"
                :reduce="val=>val.value"
                :options="tipoDocumentoOptions"
                :clearable="false"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            v-slot="validationContext"
            name="Número do Documento"
            rules="required"
          >
            <b-form-group
              label="Número do Documento *"
              label-for="numeroDocumento"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="numeroDocumento"
                :key="cleaveKey"
                v-model="remetente.numeroDocumento"
                v-cleave="remetente.tipoDocumento ? cleaveOptions[remetente.tipoDocumento.toLowerCase()] : 
                  cleaveOptions.cpf"
                :state="getValidationState(validationContext)"
                trim
                placeholder="000.000.000-00 ou 00.000.000/0000-00"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email|max:100"
          >
            <b-form-group
              label="Email"
              label-for="remetente-email"
            >
              <b-form-input
                id="remetente-email"
                v-model="remetente.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="remetente@exemplo.com"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Celular"
            rules="phone"
          >
            <b-form-group
              label="Celular"
              label-for="remetente-celular"
            >
              <b-form-input
                id="remetente-celular"
                v-model="remetente.celular"
                v-cleave="cleaveOptions.phone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="(11) 00000-0000"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="CEP"
            rules="required"
          >
            <b-form-group
              label="CEP *"
              label-for="remetente-cep"
            >
              <b-form-input
                id="remetente-cep"
                v-model="remetente.cep"
                v-cleave="cleaveOptions.cep"
                :state="getValidationState(validationContext)"
                trim
                placeholder="00000-000"
                @blur="buscaEndereco"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Logradouro"
            rules="required|max:80"
          >
            <b-form-group
              label="Logradouro *"
              label-for="remetente-logradouro"
            >
              <b-form-input
                id="remetente-logradouro"
                v-model="remetente.logradouro"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Av. Brasil"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <validation-provider
            #default="validationContext"
            name="Numero"
            rules="required"
          >
            <b-form-group
              label="Número *"
              label-for="remetente-numero"
            >
              <b-form-input
                id="remetente-numero"
                v-model="remetente.numero"
                :state="getValidationState(validationContext)"
                trim
                placeholder="110"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Complemento"
            rules="max:50"
          >
            <b-form-group
              label="Complemento"
              label-for="remetente-complemento"
            >
              <b-form-input
                id="remetente-complemento"
                v-model="remetente.complemento"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Sala 220"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Bairro"
            rules="required|max:40"
          >
            <b-form-group
              label="Bairro *"
              label-for="remetente-bairro"
            >
              <b-form-input
                id="remetente-bairro"
                v-model="remetente.bairro"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Centro"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Cidade"
            rules="required|max:40"
          >
            <b-form-group
              label="Cidade *"
              label-for="remetente-cidade"
            >
              <b-form-input
                id="remetente-cidade"
                v-model="remetente.cidade"
                readonly
                :state="getValidationState(validationContext)"
                trim
                placeholder="São Paulo"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="UF"
            rules="required"
          >
            <b-form-group
              label="UF *"
              label-for="remetente-uf"
            >
              <b-form-input
                id="remetente-uf"
                v-model="remetente.uf"
                readonly
                :state="getValidationState(validationContext)"
                trim
                placeholder="SP"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </div>
    <hr class="my-0">
    <div class="p-2">

      <b-row class="flex-row-reverse">
        <div
          style="display:flex; align-items: center; justify-content: center"
        >
          <b-button
            variant="primary"
            class="mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
          >
            Próximo
            <feather-icon
              icon="ArrowRightIcon"
            />
          </b-button>
        </div>
        <div
          style="display:flex; align-items: center; justify-content: center"
        >
          <b-form-checkbox
            v-model="remetente.salvar"
            switch
            class="mr-2"
          >
            Lembrar deste remetente
          </b-form-checkbox>
        </div>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import cleaveOptions from '@/libs/cleave/options'
import { ref, watch, reactive, toRefs } from '@vue/composition-api'
import CepService from '@/service/CepService'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'Remetente',
  props: {
    getValidationState: {
      type: Function,
      required: true,
    },
    remetente: {
      type: undefined,
      required: true,
    },
  },
  data: () => ({
    inputProps: {
      id: 'autosuggest__input',
      class: 'form-control',
      placeholder: 'Digite o nome do remetente',
    },
  }),

  computed: {
    ...mapGetters({
      firebaseUser: 'firebase/firebaseUser',
    }),
  },

  watch: {
    firebaseUser() {
      const { clienteId } = JSON.parse(localStorage.getItem('userName'))
      this.$store.dispatch('remetente/findAll', clienteId)
    },
  },

  beforeMount() {
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))
    this.$store.dispatch('remetente/findAll', clienteId)
  },

  setup(props, { emit }) {
    const remetenteStore = createNamespacedHelpers('remetente')

    const state = reactive({
      remetenteOptions: remetenteStore.useGetters(['remetentes']).remetentes,
    })

    const cleaveKey = ref(0)

    const tipoDocumentoOptions = [
      { label: 'CPF', value: 'CPF' },
      { label: 'CNPJ', value: 'CNPJ' },
    ]

    const suggestions = ref([{ data: [] }])

    function incrementCleaveKey() {
      cleaveKey.value += 1
    }

    watch(() => props.remetente.tipoDocumento, () => {
      incrementCleaveKey()
    })

    const reduce = value => {
      emit('update:remetente', value)
    }

    const onSelected = option => {
      if (option.item.nome === props.remetente.nome) {
        return
      }
      reduce(option.item)
    }

    const buscaEndereco = event => {
      CepService.buscarEndereco(event.target.value).then(endereco => {
        if (endereco) {
          if (endereco.success) {
            const dadosEndereco = JSON.parse(endereco.body)
            dadosEndereco.cep = event.target.value
            delete dadosEndereco.nome
            reduce({ ...props.remetente, ...dadosEndereco })
          }
        }
      })
    }

    const onInputChange = text => {
      if (text.length >= 3) {
        const newRemetente = { ...props.remetente, nome: text }
        emit('update:remetente', newRemetente)
        const suggestionsArray = state.remetenteOptions.filter(remetente => remetente.nome.toLowerCase().indexOf(text.toLowerCase())
          >= 0)
        suggestions.value = [{ data: suggestionsArray }]
      }
    }

    const getSuggestionValue = value => {
      const newRemetente = { ...props.remetente, ...value.item }
      emit('update:remetente', newRemetente)
      return value.item.nome
    }

    return {
      ...toRefs(state),
      cleaveOptions,
      tipoDocumentoOptions,
      cleaveKey,
      reduce,
      onInputChange,
      buscaEndereco,
      onSelected,
      getSuggestionValue,
      suggestions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>