<template>
  <div>
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header class="p-2">
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="FileIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Relatório
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <validation-observer
        #default="{ handleSubmit }"
        ref="validationSearch"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div class="p-2">
            <b-row>
              <b-col
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange"/>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="agrupamento"
                  :rules="{ required: false }"
                >
                  <b-form-group
                    label="Agrupar Por"
                    label-for="agrupamento"
                  >
                    <b-form-select
                      id="agrupamento"
                      v-model="filtros.agrupamento"
                      :options="agruparOptions"
                    />
                  </b-form-group>
                  <span class="undermessage-error">
                    {{ validationContext.errors[ 0 ] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
              >
                <div class="pt-2">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mb-sm-0 mr-0 mr-sm-1"
                  >
                    Buscar
                    <feather-icon
                      class="ml-1 d-md-none d-xl-inline d-inline"
                      icon="SearchIcon"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header>
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="TagIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Resultados
          </span>
        </b-card-title>
        <b-col
          md="3"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            class="px-3"
            @click="gerarExcel"
          >
            <span class="text-nowrap">Exportar Excel</span>
          </b-button>
        </b-col>
      </b-card-header>
      <hr class="my-0">
      <b-table
        ref="tableRef"
        show-empty
        empty-text="Nenhum resultado encontrado."
        :items="dados"
        :fields="tableColumns"
        sticky-header
      >
        <template #cell(valor)="data">
          {{ maskCurrency(data.item.valor) }}
        </template>
        <template slot="bottom-row">
          <td class="table-footer">
            TOTAL
          </td>
          <td class="table-footer">
            {{ totalSoma('quantidade') }}
          </td>
          <td class="table-footer">
            {{ maskCurrency(totalSoma('valor')) }}
          </td>
        </template>
      </b-table>
    </b-card>
    
  </div>
</template>

<script>
import { reactive, toRefs, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'
import moment from 'moment'
import store from '@/store'
import utils from '@/helpers/utils'
import swal from 'sweetalert2/dist/sweetalert2.min'
import ExcelComposition from '@/components/exportar/excel.composition'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import DateRange from '@/components/form/DateRange.vue'

export default {
  name: 'Relatorios',
  components: { DateRange },
  methods: {
    separaValores(obj, tipoSoma) {
      if (tipoSoma === 'quantidade') {
        const value = obj.quantidade ? obj.quantidade : 0
        return Number(value)
      }
      if (tipoSoma === 'valor') {
        const value = obj.valor ? obj.valor : 0
        return Number(value)
      } return 0
    },
    totalSoma(tipoSoma) {
      let total = 0
      if (this.$refs.tableRef) {
        const array = this.$refs.tableRef.filteredItems
        array.forEach(obj => {
          const valor = this.separaValores(obj, tipoSoma)
          total += valor
        })
      }
      return total
    },
  },
  setup() {
    const { appCarregando } = $themeConfig.app

    const relatorioStore = createNamespacedHelpers('relatorio')

    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const state = reactive({
      dateRange: {
        startDate: moment()
          .subtract(30, 'days')
          .format('YYYY-MM-DD'),
        endDate: new Date().toISOString().split('T')[0],
      },
      filtros: {
        agrupamento: 'DIA',
        clienteId,
      },
      dados: relatorioStore.useGetters(['dados']).dados,
      validationSearch: null,
    })

    const tableColumns = [
      { key: 'agrupamento', sortable: true },
      { key: 'quantidade', sortable: true },
      { key: 'valor', label: 'Valor R$', sortable: true },
    ]

    const reset = () => {
      state.filtros = {
        agrupamento: 'DIA',
        dataInicial: new Date().toISOString().split('T')[0], 
        dataFinal: new Date().toISOString().split('T')[0],
        clienteId,
      }
    }
    const sendRequest = filtros => {
      appCarregando.show()
      store.dispatch('relatorio/pesquisar', filtros).then(() => {
        appCarregando.hide()
      })
    }

    const onSubmit = () => {
      const filtros = {
        ...state.filtros,
        dataInicial: state.dateRange.startDate, 
        dataFinal: state.dateRange.endDate,
      }
      sendRequest(filtros)
    }

    onSubmit()
 
    const { getValidationState, resetForm } = formValidation(reset)

    const agruparOptions = [
      {
        text: 'Dia',
        value: 'DIA',
      },
      {
        text: 'Mês',
        value: 'MES',
      },
      {
        text: 'Serviço',
        value: 'SERVICO',
      },
      // {
      //   text: 'Departamento',
      //   value: 'DEPARTAMENTO',
      // },
      {
        text: 'Situação',
        value: 'SITUACAO',
      },
      {
        text: 'UF',
        value: 'UF',
      },
    ]

    const { downloadSpreadsheet } = ExcelComposition()
    const tableRef = ref(null)
    const downloadExcel = () => {
      const dados = tableRef.value.sortedItems.map(item => ({
        agrupamento: item.agrupamento,
        quantidade: item.quantidade,
        valor: Number(item.valor),
      }))
      if (dados.length === 0) {
        appCarregando.hide()
        swal.fire({
          icon: 'warning',
          title: 'Sem dados para exportar',
          text: 'O Resultado da sua busca não retornou dados para exportação.',
          showConfirmButton: false,
          timer: 3000,
        })
      } else {
        downloadSpreadsheet(dados)
      }
    }
    const gerarExcel = () => {
      appCarregando.show()
      setTimeout(() => {
        downloadExcel()
      }, 100)
    }

    return {
      ...toRefs(state),
      getValidationState,
      onSubmit,
      resetForm,
      ...utils,
      tableColumns,
      agruparOptions,
      tableRef,
      gerarExcel,
    }
  },
}
</script>
<style scoped>
</style>