<template>
  <div>
    <b-card
      no-body
      class="mb-2"
    >
      <template>
        <b-card-header class="p-2">
          <b-card-title class="font-medium-2 card-title col-9">
            <feather-icon
              icon="BookIcon"
              size="18"
            />
            <span class="align-middle ml-50"> Log de {{ nomeLog }} </span>
          </b-card-title>
          <div 
            class="col-3 d-flex justify-content-between"
          >
            <b-button
              class="mr-3"
              variant="outline-danger"
              @click="voltar()"
            >
              <span class="text-nowrap">Voltar</span>
            </b-button>
            <b-button
              variant="primary"
              @click="atualizarPagina()"
            >
              <span class="text-nowrap">Atualizar</span>
            </b-button>
          </div>
        </b-card-header>
        <hr class="my-0">
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="search"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
                  debounce="500"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrando</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>envios por página</label>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="tableRef"
          responsive
          class="position-relative"
          :items="logs"
          :fields="logLocal == 'cadastro-clientes' ? resultsTableColumnsClientes : resultsTableColumns "
          :filter="search"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          stacked="md"
          :empty-text="carregando ? 'Carregando...' : 'Nenhum resultado encontrado.'"
          empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
          @filtered="onFiltered"
        >
          <template #cell(dataModificacao)="data">
            <div>
              {{ data.item.dataModificacao ? maskDate(data.item.dataModificacao) : '' }}
            </div>
          </template>
          <template #cell(hora)="data">
            <div>
              {{ data.item.dataModificacao ? maskHour(data.item.dataModificacao) : '' }}
            </div>
          </template>
          <template #cell(acoes)="data">
            <b-button
              variant="link"
              @click="openModal(data)"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
              />
            </b-button>
          </template>
        </b-table>
        <hr class="mt-0 mb-1">
        <div class="m-2">
          <b-row v-if="totalRows > 0">
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Mostrando do 
                {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
                {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                  totalRows
                }} resultados</span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
    <b-modal
      ref="modal"
      title="Alterações"
      ok-only
      size="md"
      style="max-height: 400px"
    >
      <details-log :data="modalData" />
    </b-modal>
  </div>
</template>

<script>
import utils from '@/helpers/utils'
import { mapGetters } from 'vuex'
import DetailsLog from './components/datailsLog'

export default {
  name: 'IndexVue',
  components: { DetailsLog },
  data() {
    return {
      ...utils,
      nomeLog: null,
      logLocal: null,
      resultsTableColumns: [
        { key: 'dataModificacao', label: 'Data', sortable: true, thStyle: { whiteSpace: 'nowrap' } },
        { key: 'hora', label: 'Hora' },
        { key: 'usuarioNome', sortable: true, label: 'Nome Usuário' },
        { key: 'tela', label: 'Tela', thStyle: { whiteSpace: 'nowrap' } },
        { key: 'acao', sortable: true, label: 'Ação' },
        { key: 'acoes', sortable: false, label: 'Registro de Ações' },
      ],
      resultsTableColumnsClientes: [
        { key: 'dataModificacao', label: 'Data', sortable: true, thStyle: { whiteSpace: 'nowrap' } },
        { key: 'hora', label: 'Hora' },
        { key: 'usuarioNome', sortable: true, label: 'Nome Usuário' },
        { key: 'tela', label: 'Tela', thStyle: { whiteSpace: 'nowrap' } },
        { key: 'local', sortable: true, label: 'Local' },
        { key: 'acao', sortable: true, label: 'Ação' },
        { key: 'acoes', sortable: false, label: 'Registro de Ações' },
      ],
      modal: null,
      tableRef: null,
      showResultsTable: false,
      validationSearch: null,
      modalData: {
        campoNome: 'campoNome',
        campoId: 'campoId',
        campo: [{ campo: 123123, valor: '112323 > 121233' }],
      },
      totalRows: 0,
      currentPage: 1,
      carregando: false,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      search: '',
      sortBy: 'dataModificacao',
      isSortDirDesc: true,
    }
  },
  computed: {
    ...mapGetters('logs', ['logs']),
  },
  watch: {
    carregando(value) {
      if (value) {
        this.appCarregando.show()
      } else {
        this.appCarregando.hide()
      }
    },
    logs(value) {
      if (value && value.length) {
        this.totalRows = value.length
      } else {
        this.totalRows = 0
      }
    },
  },
  destroyed() {
    this.nomeLog = null
    this.logLocal = null
    this.$store.dispatch('logs/resetLogs')
  },
  beforeMount() {
    const origem = this.$route.query.from
    if (origem != null) {
      if (origem === '/envios/gerar-romaneio') {
        this.nomeLog = 'Romaneio'
        this.logLocal = 'cliente-romaneio'
      }
      if (origem === '/cadastro/layouts-importacao') {
        this.nomeLog = 'Layout Importação Envio'
        this.logLocal = 'cliente-leyout-envio'
      }
      if (origem === '/cadastro/layouts-importacao-reversa') {
        this.nomeLog = 'Layout Importação Reversa'
        this.logLocal = 'cliente-leyout-reversa'
      }
      if (origem === '/cadastro/endereco-reversa') {
        this.nomeLog = 'Cadastro Endereço Reversa'
        this.logLocal = 'cliente-endereco-reversa'
      }
    }
  },
  mounted() {
    this.onSubmit()
    this.modal = this.$refs.modal
  },
  methods: {
    voltar() {
      this.$router.go(-1)
    },
    openModal(data) {
      const { campoNome, campoId, campos, usuarioNome, dataModificacao } = data.item
      if (campos) {
        const campo = campos.map(item => {
          const regex = /campo=(.*?), valorAntigo=(.*?), novoValor=(.*?)\)/
          const matches = item.match(regex)
          if (!matches) {
            return {
              campo: '',
              valor: '',
            }
          }
          return {
            campo: matches[1],
            valor: `${matches[2]} > ${matches[3]}`,
          }
        })
        this.modalData = {
          campoId,
          campoNome,
          usuarioNome,
          dataModificacao: dataModificacao ? utils.maskDateHour(dataModificacao) : '',
          campo,
        }
      }
      this.modal.show()
    },
    onFiltered(filteredItems) {
      this.currentPage = 1
      this.totalRows = filteredItems.length
    },
    onSubmit() {
      const { clienteId } = JSON.parse(localStorage.getItem('userName'))
      const parametros = {
        logLocal: this.logLocal,
        idCliente: clienteId,
      }
      if (parametros.logLocal !== null) {
        this.$store.dispatch('logs/findAllByLocal', parametros)
      }
      this.currentPage = 1
      this.search = ''
      this.showResultsTable = true
    },
    atualizarPagina() {
      this.onSubmit()
    },
  },
}
</script>
<style scoped>
  .form-group {
    min-width: 100%;
  }
  .table-footer {
    font-weight: bold;
    background: #E9E9E9;
    border-bottom: 3px solid #F55559;
    font-size: 1.2rem;
  }
  .widerTh {
    min-width: 30rem;
    margin: 0 30px;
  }
  .cte-link {
    color: #263bdb;
    cursor: pointer;
  }
</style>