import httpClient from './http-clients/portaljad'

const baseUrl = 'fatura-cliente'

export default class FaturasService {
  static async findByFilter(filter) {
    try {
      const { data } = await httpClient.post([baseUrl, 'filter'].join('/'), filter)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(faturaId) {
    try {
      if (!faturaId) {
        return undefined
      }
      const { data } = await httpClient.get([baseUrl, faturaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}