import LayoutService from '@/service/LayoutService'
import adapter from '@/adapters/LayoutAdapter'

export default {
  namespaced: true,
  state: {
    layout: {},
    layouts: [],
    layoutsOptions: [],
    layoutsOptionsReversa: [],
  },
  getters: {
    layout(state) {
      return state.layout
    },
    layouts(state) {
      return state.layouts
    },
    layoutsOptions(state) {
      return state.layoutsOptions
    },
    layoutsOptionsReversa(state) {
      return state.layoutsOptionsReversa
    },
  },
  mutations: {
    UPDATE_LAYOUT(state, layout) {
      state.layout = layout
    },
    UPDATE_LAYOUTS(state, layouts) {
      state.layouts = layouts
    },
    SAVE_LAYOUT(state, layout) {
      state.layouts.push(layout)
    },
    DELETE_LAYOUT(state, layoutId) {
      if (state.layouts) {
        const index = state.layouts.findIndex(item => item.id === layoutId)
        state.layouts.splice(index, 1)
      }
    },
    UPDATE_LAYOUTS_OPTIONS(state, layoutsOptions) {
      state.layoutsOptions = layoutsOptions
    },
    UPDATE_LAYOUTS_OPTIONS_REVERSA(state, layoutsOptions) {
      state.layoutsOptionsReversa = layoutsOptions
    },
  },
  actions: {
    async findById({ commit }, layoutId) {
      const layout = await LayoutService.findById(layoutId)
      commit('UPDATE_LAYOUT', adapter.toStore(layout))
      return layout
    },
    async findAll({ commit }, clienteId) {
      const layouts = await LayoutService.findAll(clienteId)
      commit('UPDATE_LAYOUTS', layouts)
      return layouts
    },
    async findAllReversa({ commit }, clienteId) {
      const layouts = await LayoutService.findAllReversa(clienteId)
      commit('UPDATE_LAYOUTS', layouts)
      return layouts
    },
    async save(_, multipart) {
      const data = await LayoutService.save(multipart)
      return data
    },
    async delete({ commit }, layoutId) {
      await LayoutService.deleteById(layoutId)
      commit('DELETE_LAYOUT', layoutId)
    },
    setOptions({ commit }, layouts) {
      const layotPadrao = layouts.filter(l => l.id < 0)
      const options = [
        {
          label: 'Padrão',
          options: [
            { text: 'XML NFe', value: 1 },
            ...layotPadrao.map(l => (
              { text: l.nomeLayout, value: l.id }
            )),
          ],
        },
        {
          label: 'E-mail',
          options: [
            { text: 'XML NFe', value: 0 },
          ],
        },
      ]
      if (layouts && layouts.filter(layout => layout.id > 1).length > 0) {
        options.push({
          label: 'Cadastrado',
          options: layouts.filter(layout => layout.id > 1).map(layout => ({
            text: layout.nomeLayout,
            value: layout.id,
          })),
        })
      }
      commit('UPDATE_LAYOUTS_OPTIONS', options)
    },
    setOptionsReversa({ commit }, layouts) {
      const options = [
        {
          label: 'Padrão',
          options: [
            {
              text: 'XML NFe',
              value: 1,
            },
          ],
        },
      ]
      if (layouts && layouts.filter(layout => layout.id > 1).length > 0) {
        options.push({
          label: 'Cadastrado',
          options: layouts.filter(layout => layout.id !== 1).map(layout => ({
            text: layout.nomeLayout,
            value: layout.id,
          })),
        })
      }
      commit('UPDATE_LAYOUTS_OPTIONS_REVERSA', options)
    },
  },
}