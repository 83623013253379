export default class RemetenteAdapter {
  static toApi(remetente) {
    return {
      ...remetente,
      numeroDocumento: remetente.numeroDocumento ? remetente.numeroDocumento.replaceAll(/[.,()/\s-]+/g, '') : null,
      celular: remetente.celular ? remetente.celular.replaceAll(/[.,()/\s-]+/g, '') : null,
      cep: remetente.cep ? remetente.cep.replaceAll(/[.,()/\s-]+/g, '') : null,
      ativo: true,
    }
  }

  static toStore(remetente) {
    return {
      ...remetente,
      clienteDepartamentoId: null,
      salvar: false,
    }
  }
}