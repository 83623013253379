import httpClient from './http-clients/portaljad'

const baseUrl = 'cliente-layout-parametro'

export default class ClienteLayoutParametroService {
  static async findAll() {
    try {
      const { data } = await httpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAllByLayoutId(layoutId) {
    try {
      const { data } = await httpClient.get([baseUrl, layoutId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async save(layoutParametros) {
    try {
      const { data } = await httpClient.post(baseUrl, layoutParametros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async deleteById(layoutId) {
    try {
      await httpClient.delete([baseUrl, layoutId].join('/'))
    } catch (error) {
      throw error.response.data
    }
  }
}