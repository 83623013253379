import DestinatarioService from '@/service/DestinatarioService'
import adapter from '@/adapters/DestinatarioAdapter'

export default {
  namespaced: true,
  state: {
    destinatarios: [],
  },
  
  getters: {
    destinatarios(state) {
      return state.destinatarios
    },
  },
  mutations: {
    SAVE_DESTINATARIO(state, destinatario) {
      state.destinatarios.push(destinatario)
    },
    UPDATE_DESTINATARIO(state, destinatario) {
      state.destinatarios = destinatario
    },

  },
  actions: {
    async save({ commit }, destinatario) {
      const destinatarioId = await DestinatarioService.save(adapter.toApi(destinatario))
      const destinatarioToSave = { ...destinatario, destinatarioId }
      commit('SAVE_DESTINATARIO', destinatarioToSave)
    },
    async findAll({ commit }, clienteId) {
      const destinatarios = await DestinatarioService.findAll(clienteId)
      commit('UPDATE_DESTINATARIO', destinatarios.map(dpto => adapter.toStore(dpto)))
      return destinatarios
    },

    async findById({ commit }, destinatarioId) {
      const destinatario = await DestinatarioService.findById(destinatarioId)
      commit('UPDATE_DESTINATARIO', adapter.toStore(destinatario))
    },
  },
}
