import ArquivoImportacaoService from '@/service/ArquivoImportacaoService'
import ClienteService from '@/service/ClienteService'

export default {
  namespaced: true,
  state: {
    dadosArquivo: {
      linhas: [],
    },
    arquivoRequest: {},
  },

  getters: {
    dadosArquivo(state) {
      return state.dadosArquivo
    },
    arquivoRequest(state) {
      return state.arquivoRequest
    },
  },
  mutations: {
    SET_DADOS_ARQUIVO(state, dadosArquivo) {
      state.dadosArquivo = dadosArquivo
    },
    SET_ARQUIVOS_REQUEST(state, arquivoRequest) {
      state.arquivoRequest = arquivoRequest
    },
  },
  actions: {
    async lerArquivo({ commit }, formFile) {
      const dadosArquivo = await ArquivoImportacaoService.getDadosArquivo(formFile)
      commit('SET_DADOS_ARQUIVO', dadosArquivo)
      return dadosArquivo
    },
    async montaGridDadosArquivo({ commit }, formData) {
      const dadosArquivo = await ArquivoImportacaoService.montaGridDadosArquivo(formData)
      if (dadosArquivo.success) {
        commit('SET_DADOS_ARQUIVO', dadosArquivo.body)
      }
      return dadosArquivo
    },
    async montaGridPeloLayoutId({ commit }, layoutId) {
      const dadosArquivo = await ArquivoImportacaoService.montaGridPeloLayoutId(layoutId)
      commit('SET_DADOS_ARQUIVO', dadosArquivo)
      return dadosArquivo
    },
    async setArquivoRequest({ commit }, arquivoRequest) {
      if (arquivoRequest.redirecionado) {
        const servico = await ClienteService.findServicoEmailById(arquivoRequest.clienteId)
        if (servico) {
          commit('SET_ARQUIVOS_REQUEST', {
            ...arquivoRequest,
            servicoId: servico.id,
            layoutId: arquivoRequest.layoutId || 0,
          })
        } else {
          commit('SET_ARQUIVOS_REQUEST', arquivoRequest)
        }
      } else {
        commit('SET_ARQUIVOS_REQUEST', arquivoRequest)
      }
    },
  },
}