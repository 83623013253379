<template>
  <div style="padding-bottom: 80px">
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header
        class="p-2 d-flex"
      >
        <b-card-title class="font-medium-2 card-title">
          <span class="align-middle ml-50">
            Layouts de Importação
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <div class="p-2">
        <b-row>
          <b-col
            cols="1"
            md="12"
          >
            <div class="d-flex align-items-right justify-content-end">
              <b-button 
                variant="primary"
                :to="{ name: 'novo-layout-importacao' }"
              >
                Criar Novo Layout
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h5 class="mb-1">
              Layouts de Importação Cadastrados:
            </h5>
            <b-table 
              ref="tableRef"
              :items="layouts"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              caption-top
              show-empty
              empty-text="Não há itens a exibir"
            >
              <template #cell(acoes)="data">
                <b-dropdown
                  :class="data.item.id <= 1 ? 'invisible' : 'visible'"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item 
                    :to="{ name: 'layouts-visualizar', params: { layoutId: data.item.id } }"
                  >
                    <feather-icon icon="FileTextIcon"/>
                    <span class="align-middle ml-50">Detalhes</span>
                  </b-dropdown-item>
                  <b-dropdown-item 
                    @click="excluir(data.item.id)"
                  >
                    <feather-icon icon="TrashIcon"/>
                    <span class="align-middle ml-50">Excluir</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="m-2">
              <b-row v-if="totalRows > 0">
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Mostrando do 
                    {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
                    {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                      totalRows
                    }} resultados</span>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { reactive, watch, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'LayoutsImportacao',
  setup() {
    const layoutStore = createNamespacedHelpers('layout')

    const state = reactive({
      layouts: layoutStore.useGetters(['layouts']).layouts,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      carregando: false,
    })

    watch(() => state.layouts, value => {
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    return {
      ...toRefs(state),
      fields: [
        { label: 'Código do Layout', key: 'id', sortable: true },
        { label: 'Nome do Layout', key: 'nomeLayout', sortable: true },
        { label: 'Modelo do Layout', key: 'tipoLayout', sortable: true },
        { label: 'Ações', key: 'acoes', sortable: false },
      ],
    }
  },
  beforeMount() {
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))
    this.$store.dispatch('layout/findAll', clienteId)
  },
  methods: {
    excluir(layoutId) {
      const appLoading = $themeConfig.app.appLoadingImage
      this.$swal({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Você tem certeza que quer deletar esse layout?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim, eu tenho!',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appLoading.show()
          this.$store.dispatch('layout/delete', layoutId).then(() => {
            appLoading.hide()
            this.$swal({
              icon: 'success',
              html:
                '<h1>Deletado com sucesso!</h1>',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    },
  },
}
</script>