import httpClient from './http-clients/portaljad'

const baseUrl = 'imprimir-etiquetas'

export default class EtiquetaService {
  static async findByDateAndSituation(filtros) {
    try {
      const { data } = await httpClient.post(baseUrl, filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findByFilter(filtros) {
    try {
      const { data } = await httpClient.post([baseUrl, 'imprimit-itiquetas'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findDetalhesByMovimentacaoId(movId) {
    try {
      const { data } = await httpClient.get([baseUrl, 'detalhes', movId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findByChave(clienteId, chave) {
    try {
      const { data } = await httpClient.get([baseUrl, 'por-chave', clienteId, chave].join('/'))
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async exportar(filtros) {
    try {
      const { data } = await httpClient.post(`${baseUrl}/exportar`, filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}